import React from "react";

const PrivacyPolicySingleSignOn = () => {
  return (
    <section className="mx-auto font-light">
      <div className="relative flex flex-col items-center justify-between text-dark-gray text-sm lg:text-base mb-14">
        <div className="h-[358px] bg-bondi-blue text-white w-full">
          <div className="max-w-xl lg:max-w-7xl mx-auto text-center px-5 mt-24">
            <h1 className="uppercase text-2xl sm:text-3xl lg:text-4xl my-2 font-semibold">
              PRIVACY NOTICE -
              <br />
              SINGLE SIGN ON
            </h1>
          </div>
        </div>
        <div className="rounded-t-[50px] -mt-20 bg-white overflow-hidden w-full max-w-xl lg:max-w-7xl min-h-[30vh] pt-16 px-8 mb-20 pb-1">
          <div className="mb-6">
            <p className="text-dark-gray">
              This privacy notice tells you about the information collected from
              you when you register for a Single Sign On (SSO) account. SSO is
              an identity provider service that creates, maintains and manages
              user identity information, providing authentication services for
              Filly Jobs and other third party applications. In collecting this
              information, we are acting as a data controller and, by law, we
              are required to provide you with information about us, about why
              and how we use your data, and about the rights you have over your
              data. For more information, please see our{" "}
              <a href="/privacy-policy" className="text-bondi-blue">
                privacy policy
              </a>
              .
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-xl mb-3">Who we are</h2>
            <p className="">
              We are Filly Jobs Limited. Our address is 388 Market Street, Suite
              1300, San Francisco CA 94111. You can contact us by post at the
              above address or by email to{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>{" "}
              for the attention of “Legal”.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-xl mb-3">
              What personal data do we collect?
            </h2>
            <p className="">
              When you submit your information to us using the confidentiality
              agreement form, we ask you for your name, your address, your
              organisation name and title, if applicable and related
              information.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-xl mb-3">
              Why do we collect this information?
            </h2>
            <p className="">
              By signing the confidentiality agreement, you are entering into a
              legally binding agreement. We will use your information to fully
              execute the confidentiality agreement.
            </p>
            <p className="">
              We ask for your consent to do this, and we will only contact you
              in relation to the confidentiality agreement for the duration of
              the term of the confidentiality agreement.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-xl mb-3">
              What do we do with your information?
            </h2>
            <p className="">
              Your information is stored in our database and is not shared with
              any third parties, except as reasonably required for Filly Jobs to
              process and store your data.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-xl mb-3">
              How long do we keep your information for?
            </h2>
            <p className="">
              Your information is kept for as long as you continue to consent to
              receive our newsletter and for so long as reasonably required
              thereafter in accordance with our record retention policy.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-xl mb-3">
            Your rights over your information
            </h2>
            <p className="">
              By law, you can ask us what information we hold about you, and you
              can ask us to correct it if it is inaccurate. You can also ask for
              it to be erased and you can ask for us to give you a copy of the
              information.
            </p>
            <p className="mt-3">
              You can also ask us to stop using your information for
              newsletters, news or product updates – the simplest way to do this
              is to withdraw your consent, which you can do at any time, either
              by clicking the unsubscribe link at the end of any newsletter or
              email, or by emailing, writing or telephoning us using the contact
              details above
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-xl mb-3">
              Your rights to complain
            </h2>
            <p className="">
              If you have a complaint about our use of your information, you can
              contact the Information Commissioner’s Office via their website at{" "}
              <a
                href="https://portal.ct.gov/FOI/CommonElements/Top-Menu/Contact-Us"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                https://portal.ct.gov/FOI/CommonElements/Top-Menu/Contact-Us
              </a>{" "}
              or write to them at:
            </p>
            <address className="not-italic mt-6">
              <p className="mt-3">
                Connecticut Freedom of Information Commission
              </p>
              <p className="mt-3">165 Capitol Ave., Suite 1100</p>
              <p className="mt-3">Hartford, CT 06106y.</p>
            </address>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicySingleSignOn;
