import React from "react";

const ConnectWithUs = () => {
  return (
    <section className="px-5 mx-auto lg:w-full max-w-xl lg:max-w-7xl mt-6 mb-20">
      <h2 className="font-bold text-[2.5rem] mb-9 text-center">
        Connect With Us
      </h2>
      <p className="mt-4 mx-auto text-center max-w-lg">
        Feel free to contact us via whatever means is suitable for you.
      </p>
      <div className="flex flex-col lg:flex-row items-center justify-center mt-8 lg:mt-12">
        <a
          href="#"
          className="w-full lg:w-80 h-24 bg-bondi-blue bg-opacity-10 p-8 rounded-[10px] flex items-center relative mb-6 lg:mb-0 lg:mx-9 hover:bg-opacity-100 group transition-all"
        >
          <i className="w-6 h-6 bg-white rounded-full flex items-center justify-center mr-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.28616 5.97925H8.76216V7.21258C9.11883 6.50325 10.0335 5.86592 11.4075 5.86592C14.0415 5.86592 14.6668 7.27792 14.6668 9.86858V14.6666H12.0002V10.4586C12.0002 8.98325 11.6435 8.15125 10.7355 8.15125C9.47616 8.15125 8.95283 9.04792 8.95283 10.4579V14.6666H6.28616V5.97925ZM1.7135 14.5533H4.38016V5.86592H1.7135V14.5533ZM4.76216 3.03325C4.76226 3.25677 4.71793 3.47808 4.63175 3.68431C4.54557 3.89054 4.41926 4.07759 4.26016 4.23459C3.93776 4.555 3.50137 4.73435 3.04683 4.73325C2.59309 4.73295 2.1577 4.55405 1.83483 4.23525C1.6763 4.07773 1.55042 3.89047 1.46439 3.68421C1.37836 3.47794 1.33388 3.25673 1.3335 3.03325C1.3335 2.58192 1.5135 2.14992 1.8355 1.83125C2.15809 1.51202 2.59366 1.33305 3.0475 1.33325C3.50216 1.33325 3.93816 1.51259 4.26016 1.83125C4.5815 2.14992 4.76216 2.58192 4.76216 3.03325Z"
                fill="#0384C6"
              />
            </svg>
          </i>
          <h3 className="text-lg font-semibold text-bondi-blue border-b-2 border-bondi-blue w-fit group-hover:text-white group-hover:border-white">
            LinkedIn
          </h3>
          <i class="text-bondi-blue absolute right-8 group-hover:text-white fas fa-arrow-right"></i>
        </a>
        <a
          href="#"
          className="w-full lg:w-80 h-24 bg-bondi-blue bg-opacity-10 p-8 rounded-[10px] flex items-center relative mb-6 lg:mb-0 lg:mx-9 hover:bg-opacity-100 group transition-all"
        >
          <i className="w-6 h-6 bg-white rounded-full flex items-center justify-center mr-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M6.09051 15.7499H11.909C12.4207 15.7499 12.9115 15.5466 13.2733 15.1848C13.6351 14.823 13.8384 14.3322 13.8384 13.8205V5.81055H11.909V13.8205H4.16113C4.16113 14.3322 4.36441 14.823 4.72623 15.1848C5.08806 15.5466 5.57881 15.7499 6.09051 15.7499Z"
                fill="black"
              />
              <path
                d="M11.909 2.25H6.09051C5.57881 2.25 5.08806 2.45327 4.72623 2.8151C4.36441 3.17693 4.16113 3.66767 4.16113 4.17937V12.1894H6.09051V4.17937H13.8384C13.8384 3.66767 13.6351 3.17693 13.2733 2.8151C12.9115 2.45327 12.4207 2.25 11.909 2.25Z"
                fill="black"
              />
            </svg>
          </i>
          <h3 className="text-lg font-semibold text-bondi-blue border-b-2 border-bondi-blue w-fit group-hover:text-white group-hover:border-white">
            Glassdoor
          </h3>
          <i class="text-bondi-blue absolute right-8 group-hover:text-white fas fa-arrow-right"></i>
        </a>
        <a
          href="#"
          className="w-full lg:w-80 h-24 bg-bondi-blue bg-opacity-10 p-8 rounded-[10px] flex items-center relative mb-6 lg:mb-0 lg:mx-9 hover:bg-opacity-100 group transition-all"
        >
          <i className="w-6 h-6 bg-white rounded-full flex items-center justify-center mr-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_533_150)">
                <path
                  d="M9.8802 6.8725C8.2817 7.6925 6.4922 6.164 7.0497 4.456C7.6097 2.7475 9.9562 2.5725 10.7612 4.18C10.8808 4.41463 10.953 4.67058 10.9735 4.93315C10.9939 5.19573 10.9624 5.45977 10.8806 5.71011C10.7988 5.96046 10.6683 6.19219 10.4967 6.392C10.3252 6.59182 10.1158 6.75578 9.8807 6.8745L9.8802 6.8725ZM7.7347 0.379996C9.3802 -0.218504 11.2632 -0.187504 12.6722 1.0365C12.9699 1.28168 13.2039 1.59527 13.3542 1.9505C13.4947 2.4085 12.8542 1.903 12.7682 1.8385C12.3231 1.53444 11.8411 1.28845 11.3337 1.1065C8.5577 0.257996 5.9322 1.8045 4.3102 4.2105C3.64862 5.27133 3.14342 6.422 2.8102 7.627C2.77906 7.77499 2.73195 7.91917 2.6697 8.057C2.5972 8.1925 2.6357 7.6895 2.6357 7.6745C2.6957 7.172 2.7922 6.672 2.9222 6.182C3.6747 3.56 5.3417 1.3775 7.7347 0.377496V0.379996ZM7.7582 14.3775V8.534C7.9247 8.552 8.0807 8.56 8.2477 8.56C9.0057 8.5625 9.7477 8.354 10.3962 7.9635V14.375C10.3962 14.925 10.2942 15.3285 10.0467 15.5945C9.92487 15.7283 9.77533 15.834 9.60849 15.9041C9.44166 15.9742 9.26155 16.0071 9.0807 16.0005C8.90171 16.007 8.72349 15.9739 8.5587 15.9038C8.39391 15.8336 8.24659 15.728 8.1272 15.5945C7.8827 15.326 7.7552 14.92 7.7552 14.378L7.7582 14.3775Z"
                  fill="#0384C6"
                />
              </g>
              <defs>
                <clipPath id="clip0_533_150">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </i>
          <h3 className="text-lg font-semibold text-bondi-blue border-b-2 border-bondi-blue w-fit group-hover:text-white group-hover:border-white">
            Indeed
          </h3>
          <i class="text-bondi-blue absolute right-8 group-hover:text-white fas fa-arrow-right"></i>
        </a>
      </div>
    </section>
  );
};

export default ConnectWithUs;
