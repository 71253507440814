import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ({to}) {
    const navigate = useNavigate()
    useEffect(() => {
      console.log(to)
      navigate(to)
    }, [])
    
  return (
    <></>
  )
}
