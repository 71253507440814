import React from "react";
import AboutUs from "../Components/About/AboutUs";
import BlueBox from "../Components/shared/BlueBox";
import Hero from "../Components/About/Hero";
import InfoDetails from "../Components/About/InfoDetails";
import Team from "../Components/About/Team";
import Testimonial from "../Components/About/Testimonial";

const About = () => {
  return (
    <section className="mx-auto">
      <div className="flex flex-col items-center justify-between">
        <Hero />
        <InfoDetails />
        <Team />
        <AboutUs />
        <Testimonial />
        <BlueBox>
          <>
            <h2 className="relative font-normal text-xl lg:text-2xl mb-9 text-center mx-auto">
              Sign Up with us and join <br /> the Filly Jobs family
            </h2>
            <div className="relative w-full flex flex-wrap md:flex-nowrap justify-center lg:justify-center mx-auto">
              <a
                href="#"
                className="font-semibold text-base mx-2 w-full md:w-auto text-center max-w-lg text-bondi-blue hover:text-white bg-white hover:bg-transparent rounded-3xl px-14 mb-5 py-3 lg:mb-0 lg:mx-4 lg:flex lg:items-center cursor-pointer border border-white transition-all"
              >
                Find Jobs
              </a>
              <a
                href="#"
                className="font-semibold text-base mx-2 w-full md:w-auto text-center hover:text-bondi-blue max-w-lg text-white bg-transparent rounded-3xl px-14 mb-5 py-3 lg:mb-0 lg:mx-4 lg:flex lg:items-center cursor-pointer border hover:bg-white transition-all"
              >
                Hire Talents
              </a>
            </div>
          </>
        </BlueBox>
      </div>
    </section>
  );
};

export default About;
