import React from "react";
import Hero from "../Components/HowItWorks/Hero";
import WhyFillyJobs from "../Components/HowItWorks/WhyFillyJobs";
import WorkSteps from "../Components/HowItWorks/WorkSteps";
import BlueBox from "../Components/shared/BlueBox";

const HowItWorksFindWork = () => {
  return (
    <div>
      <Hero />
      <WorkSteps />
      <WhyFillyJobs />
      <BlueBox>
      <>
            <h2 className="relative font-normal text-xl lg:text-2xl mb-9 text-center mx-auto">
              Sign Up with us and join <br /> the Filly Jobs family
            </h2>
            <div className="relative w-full flex flex-wrap md:flex-nowrap justify-center lg:justify-center mx-auto">
              <a
                href="#"
                className="font-semibold text-base mx-2 w-full md:w-auto text-center max-w-lg text-bondi-blue hover:text-white bg-white hover:bg-transparent rounded-3xl px-14 mb-5 py-3 lg:mb-0 lg:mx-4 lg:flex lg:items-center cursor-pointer border border-white transition-all"
              >
                Find Jobs
              </a>
            </div>
          </>
      </BlueBox>
    </div>
  );
};

export default HowItWorksFindWork;
