import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ServicePurchase = ({ active, setActive, handlePurchase, description, setDescription, setFiles }) => {
  const profile = useSelector(state => state.user.profile)
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (active === 4) {
      setShow(true);
    } else {
      setShow(false);
    }

    return () => {
      setShow(false);
    };
  }, [active]);

  return (
    <div
      className={!show ? 'hidden' : `bg-opacity-70 bg-slate-900 fixed left-0 h-screen inset-0 overflow-hidden right-0 top-0 w-full z-50
      x-transition:enter=transition ease-out duration-100 x-transition:enter-start=opacity-0 scale-90
      x-transition:enter-end=opacity-100 scale-100 x-transition:leave=transition ease-in duration-100
      x-transition:leave-start=opacity-100 scale-100 x-transition:leave-end=opacity-0 scale-90`}
    >
      <div className="grid h-full items-center max-w-3xl mx-auto px-4 py-8 relative">
        <div className="bg-white overflow-y-auto max-h-full relative rounded-lg">
          <div className=" flex text-riight items-right justify-between p-4 rounded-t border-b">
          <span className="text-2xl">Request A Quote</span>
            <button
              type="button"
              onClick={() => setActive(0)}
              className="bg-transparent hover:bg-slate-200 hover:text-slate-900 inline-flex items-center p-1.5 rounded-lg text-slate-400 text-sm"
            >
              <span className="material-icons text-2xl">X</span>
            </button>
          </div>
          <div className=" flex text-riight items-right p-4 rounded-t border-b">
          <img className="w-20 rounded-full" src={profile.pic} alt=""/>
          <div className="ml-3"> 
          <h4 className="font-bold">{profile.name}</h4>
          <p className="text-gray-500">Hi, please provide your request details below and ill get back to you</p>
          </div>
          </div>
          <div className="font-bold text-2xl justify-center text-left mr-6 px-8">
            <h3>
              Describe the service you're looking to purchase-please be as
              detailed as possible
            </h3>
          </div>

          <div className="p-6 pb-8">
            <div className="grid gap-y-4">
              <div className="grid gap-4">
                <div className="flex flex-col items-start">
                  <textarea
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                    className="w-full border-2 px-8  py-4 outline-none h-52 rounded-lg"
                    placeholder="I'm looking for"
                  ></textarea>
                  <div className="flex w-full bg-grey-lighter pt-3">
                    <label className="w-54 flex px-4 py-2 bg-gray-300 text-blue rounded-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                      <span className="mt-2 text-sm leading-normal">
                        Attach Files
                      </span>
                      <input type="file" class="hidden" />
                      <svg
                        className="w-8 h-8 ml-3"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                      </svg>
                    </label>
                  </div>
                </div>
                <div className="flex flex-col">
                <h3 className="font-bold">Once you place your order, when would you like your service delivered?</h3>
                <div className="flex justify-between text-sm">
                <button className="bg-white border rounded mt-6">
                <span className="text-gray-500 px-8 ">24 hours</span>
                </button>

                <button className="bg-white border rounded mt-6">
                <span className="text-gray-500 px-8">3 days</span>
                </button>

                <button className="bg-white border rounded mt-6">
                <span className="text-gray-500 px-8">7 days</span>
                </button>

                <button className="bg-white border rounded mt-6">
                <span className="text-gray-500 px-8">Others</span>
                </button>

                </div>
                </div>
                <div className="my-6 flex items-center justify-end">
                  <button
                    type="button"
                    onClick={handlePurchase}
                    className="text-white bg-blue-700 ml-2 hover:bg-blue-900 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm uppercase px-5 py-2 tracking-widest"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServicePurchase;
