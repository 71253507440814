import React from "react";

const FillyJobsEscrowInc = () => {
  return (
    <div className="font-thin text-dark-gray">
      <h2 className="font-semibold text-black text-xl my-1 mb-2">
        Filly Jobs Inc.
      </h2>
      <p className="font-normal text-black">Valid since 25th August 2022</p>
      <div className="mt-8">
        <p className="">
          Filly Jobs Escrow Inc. provides escrow services solely to Users of
          Filly Jobs. We provide these escrow services solely to deliver, hold,
          or receive payment for jobs for services engaged through, and to pay
          fees including service, membership and payment processing and
          administration fees to, Filly Jobs. These escrow services are intended
          for business use, and the Users agree to use these escrow services
          only for business purposes and not for consumer, personal, family, or
          household purposes.
        </p>
        <p className="uppercase">
          FILLY JOBS ESCROW INC. DOES NOT PROVIDE ESCROW SERVICES TO THE GENERAL
          PUBLIC.
        </p>
      </div>
    </div>
  );
};

export default FillyJobsEscrowInc;
