import React from "react";

const WorkWithUs = () => {
  return (
    <section id="careers" className="px-5 mx-auto lg:w-full max-w-xl lg:max-w-7xl mb-14">
      <h2 className="font-bold text-[2.5rem] mb-9 text-center">
        Filly Jobs Portfolio
      </h2>
      <p className="mt-4 mx-auto text-center max-w-lg">
        Our team takes pride in delivering superior service, superior quality,
        and superior customer satisfaction
      </p>
      <div className="w-full flex flex-wrap flex-col md:flex-row items-center justify-center lg:justify-between mx-auto mt-20">
        <div className="shadow-lg lg:mx-3 mb-8 rounded-md w-full lg:w-[30%] xl:w-full max-w-sm py-7 px-8">
          <h3 className="font-semibold text-bondi-blue text-lg mb-2">
            Frontend Web Developer
          </h3>
          <div className="text-dark-gray flex mb-6 items-center">
            {/* <i className="text-3xl fal fa-map-marker-alt"></i> */}
            <ion-icon name="location"></ion-icon>
            <p className="ml-2">Remote</p>
          </div>
          <button
            data-bs-toggle="modal"
            data-bs-target="#applicationModal"
            className="bg-bright-red hover:bg-white font-semibold text-white hover:text-bright-red transition-all py-2 px-9 border border-bright-red rounded-full"
          >
            View Job Details
          </button>
        </div>
        <div className="shadow-lg lg:mx-3 mb-8 rounded-md w-full lg:w-[30%] xl:w-full max-w-sm py-7 px-8">
          <h3 className="font-semibold text-bondi-blue text-lg mb-2">
            UI/UX Designer
          </h3>
          <div className="text-dark-gray flex mb-6 items-center">
            <ion-icon name="location"></ion-icon>
            <p className="ml-2">Remote</p>
          </div>
          <button
            data-bs-toggle="modal"
            data-bs-target="#applicationModal"
            className="bg-bright-red hover:bg-white font-semibold text-white hover:text-bright-red transition-all py-2 px-9 border border-bright-red rounded-full"
          >
            View Job Details
          </button>
        </div>
        <div className="shadow-lg lg:mx-3 mb-8 rounded-md w-full lg:w-[30%] xl:w-full max-w-sm py-7 px-8">
          <h3 className="font-semibold text-bondi-blue text-lg mb-2">
            Content Creator
          </h3>
          <div className="text-dark-gray flex mb-6 items-center">
            <ion-icon name="location"></ion-icon>
            <p className="ml-2">Remote</p>
          </div>
          <button
            data-bs-toggle="modal"
            data-bs-target="#applicationModal"
            className="bg-bright-red hover:bg-white font-semibold text-white hover:text-bright-red transition-all py-2 px-9 border border-bright-red rounded-full"
          >
            View Job Details
          </button>
        </div>
        <div className="shadow-lg lg:mx-3 mb-8 rounded-md w-full lg:w-[30%] xl:w-full max-w-sm py-7 px-8">
          <h3 className="font-semibold text-bondi-blue text-lg mb-2">
            Project Manager
          </h3>
          <div className="text-dark-gray flex mb-6 items-center">
            <ion-icon name="location"></ion-icon>
            <p className="ml-2">Remote</p>
          </div>
          <button
            data-bs-toggle="modal"
            data-bs-target="#applicationModal"
            className="bg-bright-red hover:bg-white font-semibold text-white hover:text-bright-red transition-all py-2 px-9 border border-bright-red rounded-full"
          >
            View Job Details
          </button>
        </div>
        <div className="shadow-lg lg:mx-3 mb-8 rounded-md w-full lg:w-[30%] xl:w-full max-w-sm py-7 px-8">
          <h3 className="font-semibold text-bondi-blue text-lg mb-2">
            Chief Accountant
          </h3>
          <div className="text-dark-gray flex mb-6 items-center">
            <ion-icon name="location"></ion-icon>
            <p className="ml-2">California, USA</p>
          </div>
          <button
            data-bs-toggle="modal"
            data-bs-target="#applicationModal"
            className="bg-bright-red hover:bg-white font-semibold text-white hover:text-bright-red transition-all py-2 px-9 border border-bright-red rounded-full"
          >
            View Job Details
          </button>
        </div>
        <div className="shadow-lg lg:mx-3 mb-8 rounded-md w-full lg:w-[30%] xl:w-full max-w-sm py-7 px-8">
          <h3 className="font-semibold text-bondi-blue text-lg mb-2">
            Data Analyst
          </h3>
          <div className="text-dark-gray flex mb-6 items-center">
            <ion-icon name="location"></ion-icon>
            <p className="ml-2">Remote</p>
          </div>
          <button
            data-bs-toggle="modal"
            data-bs-target="#applicationModal"
            className="bg-bright-red hover:bg-white font-semibold text-white hover:text-bright-red transition-all py-2 px-9 border border-bright-red rounded-full"
          >
            View Job Details
          </button>
        </div>
      </div>
    </section>
  );
};

export default WorkWithUs;
