import React from "react";

const PrivatePolicy = () => {
  return (
    <section className="mx-auto">
      <div className="relative flex flex-col items-center justify-between text-dark-gray text-sm lg:text-base mb-14">
        <div className="h-[358px] bg-bondi-blue text-white w-full">
          <div className="max-w-xl lg:max-w-7xl mx-auto text-center px-5 mt-24">
            <h1 className="uppercase text-2xl sm:text-3xl lg:text-4xl my-2 font-semibold">
              privacy policy
            </h1>
          </div>
        </div>
        <div className="rounded-t-[50px] -mt-20 bg-white overflow-hidden w-full max-w-xl lg:max-w-7xl min-h-[30vh] pt-10 px-8 mb-20 pb-1">
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Data Privacy
            </h2>
            <p className="">
              Filly Jobs collects personal information from you in a number of
              different ways. For example, when you download one of our
              products, receive services from us or use one of our sister
              websites. At Filly Jobs, we consider your privacy to be extremely
              important to us. These are the fundamental principles that we
              follow in relation to your personal information:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                We don’t ask you for personal information unless we truly need
                it.
              </li>
              <li className="mb-3">
                We don’t share your personal information with anyone except to
                provide you with services, or products, to comply with the law,
                or to protect our rights.
              </li>
              <li className="mb-3">
                Provide your name, email address, address and telephone number;
              </li>
              <li className="mb-3">
                We don’t store personal information unless required for the
                ongoing operation of services to you, to provide you with
                products, to comply with the law, or to protect our rights.
              </li>
              <li className="mb-3">
                We will use the personal information that you provide to us in
                accordance with this privacy policy.
              </li>
            </ul>
            <p className=" mt-6">
              Filly Jobs Limited (“we”, “us” and “our”) are committed to
              protecting and respecting your privacy. Information collected for
              or on behalf of Filly Jobs will be the responsibility of Filly
              Jobs Limited. This policy (together with our terms of use and any
              other documents referred to on it) sets out the basis on which any
              personal data we collect from you, or that you provide to us, will
              be processed by us. Please read the following carefully to
              understand our views and practices regarding your personal data
              and how we will treat it. By visiting Filly Jobs website, you are
              accepting and consenting to the practices described in this
              policy.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Privacy Notices
            </h2>
            <p className="">
              Specific details about personal data collection can be found in
              the relevant privacy notice:
            </p>
            <ul className="mt-6 list-disc text-bondi-blue ml-8 underline">
              <li className="mb-3">
                <a href="/privacy-policy/privacy-notice-partner-portal">
                  Partner Privacy notice
                </a>
              </li>
              <li className="mb-3">
                <a href="/privacy-policy/privacy-notice-newsletter">
                  Newsletter privacy notice
                </a>
              </li>
              <li className="mb-3">
                <a href="/privacy-policy/privacy-notice-webinars">
                  Webinar privacy notice
                </a>
              </li>
              <li className="mb-3">
                <a href="/privacy-policy/privacy-notice-online-purchase">
                  Online purchase privacy notice
                </a>
              </li>
              <li className="mb-3">
                <a href="/privacy-policy/privacy-notice-contact-us">
                  Contact us and enquiries privacy notice
                </a>
              </li>
              <li className="mb-3">
                <a href="/privacy-policy/privacy-notice-single-sign-on">
                  Single sign on privacy notice
                </a>
              </li>
              <li className="mb-3">
                <a href="/privacy-policy/privacy-notice-confidentiality-agreement">
                  Confidentiality agreement privacy notice
                </a>
              </li>
              <li className="mb-3">
                <a href="/privacy-policy/privacy-notice-recruitment">
                  Recruitment privacy notice
                </a>
              </li>
            </ul>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Information We Collect From You
            </h2>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                Information you give us. This is information about you that you
                give us by filling in forms on the Filly Jobs website or by
                corresponding with us by phone, e-mail or otherwise. It includes
                information you provide when you register to use our site, your
                login information where provided on a site or make a purchase on
                our site. It may include the following: first name, last name;
                company name; company size; industry; job title; level; phone
                number; email address; state or province and country.
              </li>
              <li className="mb-3">
                Information about your visit. This information includes the full
                Uniform Resource Locators (URL), clickstream to, through and
                from our site (including date and time, timezone, your
                geolocation), items you viewed or searched for, page response
                times, download errors, length of visits to certain pages, page
                interaction information (such as scrolling, clicks, and
                mouse-overs), methods used to browse away from the page.
              </li>
              <li className="mb-3">
                Information we receive from other sources. This is information
                we receive about you if you use any of the other websites we
                operate or the other services we provide. In this case we will
                have informed you when we collected that data (through a Privacy
                notice) if we intend to share those data internally and combine
                it with data collected on this site. We will also have told you
                for what purpose we will share and combine your data. We are
                working closely with third parties (including, for example,
                business partners, sub-contractors in technical, payment and
                delivery services, advertising networks, analytics providers,
                search information providers, credit reference agencies). We may
                notify you when we receive information about you from them and
                the purposes for which we intend to use that information.
              </li>
            </ul>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              How information is collected from you
            </h2>
            <p className="">
              We may collect this information through the following routes:
            </p>

            <div className="mt-8">
              <h3 className="font-semibold text-xl mb-3">Websites</h3>
              <p className="">
                When you register to use certain parts of our websites, such as
                wikis, webinars or message boards, or register for free
                accounts, we may ask for personal information such as your full
                name, email address and a password. Please see the Privacy
                notice on collection for further details of how we will use your
                personal data.
              </p>
            </div>
            <div className="mt-8">
              <h3 className="font-semibold text-xl mb-3">
                Chat Rooms, Forums and New Groups
              </h3>
              <p className="">
                These are available to our community. Any information that you
                disclose in these areas becomes public information and may be
                recorded, archived and republished by anyone. Please exercise
                caution when deciding to disclose any personal information in
                such areas
              </p>
            </div>

            <div className="mt-8">
              <h3 className="font-semibold text-xl mb-3">Filly Job Products</h3>
              <p className="">
                These are available to our community. Any information that you
                disclose in these areas becomes public information and may be
                recorded, archived and republished by anyone. Please exercise
                caution when deciding to disclose any personal information in
                such areas
              </p>
            </div>
            <div className="mt-8">
              <h3 className="font-semibold text-xl mb-3">Webinars</h3>
              <p className="">
                Some webinars are directly hosted by Filly Jobs on our sites.
                Where a webinar is operated by a third-party provider, you will
                be directed to a third-party website and the third-party
                provider may collect information as part of the webinar
                registration process. This data will be collected in accordance
                with the third-party provider’s terms and conditions. Please see
                “Links and third-party content” in the Terms and Conditions for
                further details.
              </p>
            </div>
            <div className="mt-8">
              <h3 className="font-semibold text-xl mb-3">
                Non-personally identifying information
              </h3>
              <p className="">
                Filly Jobs may collect non-personally identifying information of
                the sort that web browsers and servers typically make available,
                such as the browser type, referring site, and the date and time
                of each visitor request. Our purpose in collecting
                non-personally identifying information is to better understand
                how visitors use our websites and services. For further
                information about how we use cookies, see the “cookie” section
                below
              </p>
            </div>
            <div className="mt-8">
              <h3 className="font-semibold text-xl mb-3">Error reports</h3>
              <p className="">
                When you chose to send an error report, it includes a unique
                identifier for your computer. This identifier does not identify
                you, unless you (or someone acting on your behalf) discloses it
                separately. An error report may include personal information
                such as the state of programs that were running at the time. You
                can block future error reports from the privacy panel of System
                Settings or by deselecting the “Enable Google Analytics in MAAS
                UI to shape improvements in user experience” box where
                applicable.
              </p>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Contact Us
            </h2>
            <p className="">
              If you contact us and provide us with information, we may keep a
              record of that correspondence and information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              What do we do with your personal data
            </h2>
            <p className="">
              We may use your information in the following ways:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                To authenticate access to certain features of our websites
              </li>
              <li className="mb-3">
                To contact you to respond to enquiries or to provide notices to
                you regarding your use of our websites or the provision of our
                services.
              </li>
              <li className="mb-3">
                To provide services, products, process payment, and authenticate
                access (where required).
              </li>
              <li className="mb-3">
                To analyse the performance or the appropriateness of products or
                services.
              </li>
              <li className="mb-3">Contact us and enquiries privacy notice </li>
              <li className="mb-3">
                To comply with legal and regulatory requirements (including
                responding to court orders, subpoenas and to prevent
                crime).These special circumstances may require us to disclose
                personal information.
              </li>
              <li className="mb-3">
                To contact you if your actions violate your agreement with us
                (if any).
              </li>
              <li className="mb-3">To fix errors and analyse trends.</li>
              <li className="mb-3">
                To study how anonymous users interact with our websites and
                services.
              </li>
              <li className="mb-3">
                To market our products or services to you.
              </li>
            </ul>
            <p className=" mt-6">
              Unless you have notified us otherwise, Filly Jobs may occasionally
              send you to email to tell you about new features, solicit
              feedback, or just to keep you up to date with what’s going on. We
              may use various community websites to communicate this type of
              information, so we expect to keep this type of information to a
              minimum. If you no longer wish to receive such communications from
              us, please follow the unsubscribe instructions provided in any of
              the communications, modify the communications preferences in your
              account or contact us using the contact details provided below.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              What are your rights?{" "}
            </h2>
            <p className="">
              You have the right to consent to our processing of personal data
              for marketing purposes and to ask us not to process your personal
              data for marketing purposes. We will usually inform you (before
              collecting your data) if we intend to use your data for such
              purposes or if we intend to disclose your information to any third
              party for such purposes. You can exercise your right to prevent
              such processing by checking certain boxes on the forms we use to
              collect your data.
            </p>
            <p className="mt-4">
              You can also exercise the right at any time by contacting us at{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>{" "}
              or by using the relevant{" "}
              <a
                href="/contact"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                contact us
              </a>{" "}
              form.
            </p>
            <p className="mt-6">
              Under the data protection legislation, you have the following
              rights. These consist of:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">The right to be informed </li>
              <li className="mb-3">The right of access</li>
              <li className="mb-3">The right to rectification</li>
              <li className="mb-3">The right to erasure </li>
              <li className="mb-3">The right to object</li>
              <li className="mb-3">The right to data portability </li>
              <li className="mb-3">
                Rights in relation to automated decision making and profiling.
              </li>
            </ul>
            <p className=" mt-6">
              Each of these rights are supported by appropriate procedures
              within Filly Jobs which allow the required action to be taken
              within the timescales stated in the applicable data protection
              legislation. You can also ask us to stop using your information –
              the simplest way to do this is to withdraw your consent, which you
              can do at any time, either by clicking the unsubscribe link at the
              end of any newsletter, or by emailing, writing or telephoning us
              using the contact details above.
            </p>
            <p className=" mt-6">
              We keep your order information for so long as reasonably required
              in accordance with our record retention policy. Your personal
              information associated with the order will then be removed.
            </p>
            <p className=" mt-6">
              Our site may, from time to time, contain links to and from the
              websites of our partner networks, advertisers and affiliates. If
              you follow a link to any of these websites, please note that these
              websites have their own privacy policies and that we do not accept
              any responsibility or liability for these policies. Please check
              these policies before you submit any personal data to these
              websites.
            </p>
            <p className=" mt-4 mb-8">These timescales are shown in Table 1.</p>

            <table className="table border-collapse w-full max-w-xl lg:max-w-3xl">
              <thead>
                <tr className="">
                  <th className="border py-3 px-4">DATA SUBJECT REQUEST</th>
                  <th className="border py-3 px-4">TIME SCALE</th>
                </tr>
              </thead>
              <tbody className="align-top">
                <tr>
                  <td className="border py-3 px-4">The right to be informed</td>
                  <td className="border py-3 px-4">
                    When data is collected (if supplied by data subject) or
                    within one month (if not supplied by data subject
                  </td>
                </tr>
                <tr>
                  <td className="border py-3 px-4">The right of access</td>
                  <td className="border py-3 px-4">One month</td>
                </tr>
                <tr>
                  <td className="border py-3 px-4">
                    The right to rectification
                  </td>
                  <td className="border py-3 px-4">One month</td>
                </tr>
                <tr>
                  <td className="border py-3 px-4">The right to erasure</td>
                  <td className="border py-3 px-4">Without undue delay</td>
                </tr>
                <tr>
                  <td className="border py-3 px-4">
                    The right to restrict processing
                  </td>
                  <td className="border py-3 px-4">Without undue delay</td>
                </tr>
                <tr>
                  <td className="border py-3 px-4">
                    The right to data portability
                  </td>
                  <td className="border py-3 px-4">One month</td>
                </tr>
                <tr>
                  <td className="border py-3 px-4">The right to object</td>
                  <td className="border py-3 px-4">On reciept of objection</td>
                </tr>
                <tr>
                  <td className="border py-3 px-4">
                    Rights in relation to automated decision making and
                    profiling.
                  </td>
                  <td className="border py-3 px-4">Not specified</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Cookies
            </h2>
            <p className="">
              Our website uses cookies to distinguish you from other users of
              our website. This helps us to provide you with a good experience
              when you browse our website and also allows us to improve our
              site.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              What is a cookie?
            </h2>
            <p className="">
              A cookie is a small file of letters and numbers that we store on
              your browser or the hard drive of your computer if you agree.
              Cookies contain information that is transferred to your computer’s
              hard drive.
            </p>
            <p className="mt-5">We use the following cookies:</p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                Strictly necessary cookies. These are cookies that are required
                for the operation of our website. They include, for example,
                cookies that enable you to log into secure areas of our website,
                use a shopping cart or make use of e-billing services.
              </li>
              <li className="mb-3">
                Analytical/performance cookies. They allow us to recognise and
                count the number of visitors and to see how visitors move around
                our website when they are using it.This helps us to improve the
                way our website works, for example, by ensuring that users are
                finding what they are looking for easily.
              </li>
              <li className="mb-3">
                Functionality cookies. These are used to recognise you when you
                return to our website. This enables us to personalise our
                content for you, greet you by name and remember your preferences
                (for example, your choice of language or region).
              </li>
              <li className="mb-3">
                Targeting cookies. These cookies record your visit to our
                website, the pages you have visited and the links you have
                followed. We will use this information to make our website and
                the advertising displayed on it more relevant to your interests.
                We may also share this information with third parties for this
                purpose.
              </li>
            </ul>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Target Cookies
            </h2>
            <p className="">
              Please note that third parties (including, for example,
              advertising networks and providers of external services like web
              traffic analysis services) may also use cookies, over which we
              have no control. These cookies are likely to be
              analytical/performance cookies or targeting cookies.
            </p>
            <p className="mt-4">
              You block cookies by activating the setting on your browser that
              allows you to refuse the setting of all or some cookies. However,
              if you use your browser settings to block all cookies (including
              essential cookies) you may not be able to access all or parts of
              our site.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              How do we protect the information we collect?
            </h2>
            <p className="">
              Filly Jobs is very concerned about protecting the confidentiality
              of your personal information. We have security measures, including
              administrative, physical and electronic measures, to protect
              against the loss, exposure, misuse or alteration of information
              that we have collected from you in the use of the services. These
              measures include SSL data encryption to transmit your personal
              information as well as technical architectures and systems to
              prevent unauthorised third parties from accessing your personal
              information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              How do we use the information we collect?
            </h2>
            <p className="">
              We use information held about you in the following ways:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                To provide you with the services you require at the time and for
                the reasons you provide the information, e.g. newsletters and
                webinars.
              </li>
              <li className="mb-3">
                To carry out our obligations arising from any contracts entered
                into between you and us and to provide you with the information,
                products and services that you request from us.
              </li>
              <li className="mb-3">
                To provide you with information about other goods and services
                we offer that are similar to those that you have already
                purchased or enquired about.
              </li>
              <li className="mb-3">
                To provide you, or permit selected third parties to provide you,
                with information about goods or services we feel may interest
                you in accordance with your consent and our Privacy notices.
              </li>
              <li className="mb-3">
                To make suggestions and recommendations to you and other users
                of our site about goods or services that may interest you or
                them.
              </li>
              <li className="mb-3">
                We will combine this information with information you give to us
                and information we collect about you. We will use this
                information and the combined information for the purposes set
                out above (depending on the types of information we receive).
              </li>
            </ul>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Disclosure of your information
            </h2>
            <p className="">
              You agree that we have the right to share your information with
              any member of our group, which means our subsidiaries, our
              ultimate holding company and its subsidiaries, as defined in
              section 1159.
            </p>
            <p className="mt-4">
              We will also disclose your information to third parties:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                If we are under a duty to disclose or share your personal data
                in order to comply with any legal obligation, or in order to
                enforce or apply our terms of use and other agreements; or to
                protect the rights, property, or safety of Filly Jobs, our
                customers, or others.
              </li>
              <li className="mb-3">
                In accordance with Privacy notices made known at the time of
                collection.
              </li>
              <li className="mb-3">
                In the event that we sell or buy any business or assets, in
                which case we will disclose your personal data to the
                prospective seller or buyer of such business or assets.
              </li>
              <li className="mb-3">
                If Filly Jobs or substantially all of its assets are acquired by
                a third party, in which case personal data held by it about its
                customers will be one of the transferred assets.
              </li>
            </ul>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Where we transfer and store your information
            </h2>
            <p className="">
              All information you provide to us is stored on our secure servers.
              Where we have given you (or where you have chosen) a password
              which enables you to access certain parts of our site, you are
              responsible for keeping this password confidential. We ask you not
              to share a password with anyone.
            </p>
            <p className="mt-4">
              By submitting your personal data, you agree to transfer, storing
              or processing. We will take all steps reasonably necessary to
              ensure that your data is treated securely and in accordance with
              this privacy policy and all applicable data protection
              legislation.
            </p>
            <p className="mt-4">
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although we will do our best to protect
              your personal data, we cannot guarantee the security of your data
              transmitted to our site; any transmission is at your own risk.
              Once we have received your information, we will use strict
              procedures and security features to try to prevent unauthorised
              access.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Changes to our privacy policy
            </h2>
            <p className="">
              Any changes we make to our privacy policy in the future will be
              posted on this page and, where appropriate, notified to you by
              email. Please check back frequently to see any updates or changes
              to our privacy policy.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Contact Us
            </h2>
            <p className="">
              Questions, comments and requests regarding this privacy policy are
              welcomed and should be addressed to{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>{" "}
              or to the address below:
            </p>
            <address className="not-italic mt-5">
              <p className="">388 Market Street,</p>
              <p className="mt-3">Suite 1300,</p>
              <p className="mt-3">San Francisco CA 94111</p>
              <p className="mt-3">
                Alternatively, you can use the relevant{" "}
                <a
                  href="/contact"
                  className="border-b border-b-bondi-blue text-bondi-blue"
                >
                  contact us
                </a>{" "}
                form.
              </p>
            </address>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Changes to our privacy policy
            </h2>
            <p className="">
              If you have a complaint about our use of your information, you can
              contact the Information Commissioner’s Office via their website at{" "}
              <a
                href="/https://portal.ct.gov/FOI/Common-Elements/Top-Menu/Contact-Us"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                https://portal.ct.gov/FOI/Common-Elements/Top-Menu/Contact-Us
              </a>{" "}
              or write to them at:
            </p>
            <address className="not-italic mt-6">
              <p className="">Connecticut Freedom of Information Commission</p>
              <p className="mt-3">165 Capitol Ave., Suite 1100</p>
              <p className="mt-3">Hartford, CT 06106</p>
            </address>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivatePolicy;
