import React from "react";
import { Link } from "react-router-dom";

const FixedPriceDirect = () => {
  return (
    <div className="font-thin text-dark-gray">
      <h2 className="font-semibold text-black text-xl my-1 mb-2">
        Fixed Price Direct Contract Escrow Instructions
      </h2>
      <p className="font-normal text-black">Valid since 25th August 2022</p>
      <p className="mt-8">
        If a Client and a Talent enter into a Fixed Price Direct Contract, these
        Fixed Price Direct Contract Escrow Instructions (
        <span className="font-semibold">“Escrow Instructions”</span>) apply.
        These Escrow Instructions govern Escrow Accounts for Fixed Price Direct
        Contracts. Service Contracts are governed by the applicable{" "}
        <Link
          to="/escrow-manual"
          className="border-b border-bondi-blue text-bondi-blue"
        >
          Escrow instructions
        </Link>
        .
      </p>
      <p className="mt-4">
        To the extent permitted by applicable law, we may modify these Escrow
        Instructions without prior notice to you, and any revisions to these
        Escrow Instructions will take effect when posted on the Site unless
        otherwise stated. Please check the Site often for updates.
      </p>
      <p className="">
        These Escrow Instructions hereby incorporate by reference the{" "}
        <a
          href="/terms-and-conditions"
          className="border-b border-bondi-blue text-bondi-blue"
        >
          Terms of Service
        </a>{" "}
        (<span className="font-semibold">“Terms of Service”</span>). Capitalized
        terms not defined in these Escrow Instructions are defined in the{" "}
        <a href="#" className="border-b border-bondi-blue text-bondi-blue">
          User Agreement
        </a>
        , elsewhere in the Terms of Service, or have the meanings given such
        terms on the Site. These Escrow Instructions do not apply to Fixed-Price
        Contracts or Hourly Contracts.
      </p>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">1.</span> DIGITAL SIGNATURE
        </h2>
        <p className="">
          By clicking to accept a Direct Contract, Client and Talent are deemed
          to have executed these Escrow Instructions electronically, effective
          on the date Client clicks to accept the Direct Contract, pursuant to
          California Civil Code section 1633.8 and the federal Electronic
          Signatures in Global and National Commerce Act, 15 U.S.C. Sec. 7001,
          et seq., as may be amended from time to time (the "E-Sign Act"). Doing
          so constitutes an acknowledgement that Client and Talent agree to
          conduct the transaction electronically, and are able to electronically
          receive, download, and print these Escrow Instructions.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">2.</span> FUNDING ESCROW
        </h2>
        <p className="">
          By accepting a Direct Contract, Client agrees to deposit funds for the
          amount of the first milestone (as defined on the Site) or the full
          amount of the Direct Contract if there is only one milestone.
          Additional milestones, if any, may be funded on the same contract by
          the Client by selecting the button to add an additional milestone or
          to activate the next milestone. Funds deposited by Clients remain in
          the Client Escrow Account until they are released to the Talent Escrow
          Account or released to the Client. Filly Jobs Escrow will not release
          funds held in escrow except as described in these Escrow Instructions.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">3.</span> RELEASE AND DELIVERY OF AMOUNTS IN
          ESCROW
        </h2>
        <p className="">
          Client and Talent irrevocably authorize and instruct Filly Jobs Escrow
          to release applicable portions of the Direct Contract Escrow Account
          (each portion, a “Release”) to their Talent Escrow Account or Client
          Escrow Account, as applicable, upon the occurrence of and in
          accordance with one or more Release Conditions provided below or as
          otherwise required by applicable law or the Terms of Service. If the
          funds are released to the Client Escrow Account, they will be
          automatically returned to the Client’s Payment Method that was charged
          to fund escrow. The amount of the Release will be delivered to the
          applicable Escrow Account in accordance with Talent’s or Client’s
          instructions, as applicable, these Escrow Instructions, and the other
          Terms of Service.
        </p>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="">3.1</span> RELEASE CONDITIONS
          </h3>
          <p className="mb-6">
            As used in these Escrow Instructions,{" "}
            <span className="font-semibold">“ Release Condition”</span> means
            any of the following:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">Client clicks to release funds to Talent.</li>
            <li className="mb-4">
              Client does not take any action for 14 days from the date of a
              Talent’s Release request, in which case Talent and Client agree
              that Filly Jobs Escrow is authorized and irrevocably instructed to
              immediately release to Talent the amount associated with the
              applicable milestone in connection with such Release request.
            </li>
            <li className="mb-4">
              Talent or Client initiates a dispute pursuant to Section 6 before
              a payment has been released to Talent, in which case the funds
              will be released in accordance with the outcome of the dispute.
            </li>
            <li className="mb-4">
              Talent cancels the contract before a payment has been released to
              Talent, in which case the funds are to be returned to the Client.
            </li>
            <li className="mb-4">
              Client cancels the contract before a payment has been released to
              Talent and Talent approves the request or takes no action within 7
              days, in which case the funds are to be released to the Client.
            </li>
            <li className="mb-4">
              Issuance of the final order of a court or arbitrator of competent
              jurisdiction from which appeal is not taken, in which case the
              funds will be released in accordance with such order.
            </li>
            <li className="mb-4">
              We believe, in our sole discretion, that fraud, an illegal act, or
              a violation of Filly Jobs's Terms of Service has been committed or
              is being committed or attempted, in which case Client and Talent
              irrevocably authorize and instruct Filly Jobs Escrow to take such
              actions as we deem appropriate in our sole discretion and in
              accordance with applicable law, in order to prevent or remedy such
              acts, including without limitation to return the funds associated
              with such acts to their source of payment.
            </li>
          </ol>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">4.</span> MAKING OR RECEIVING A BONUS OR
          EXPENSE PAYMENT
        </h2>
        <p className="">
          On the occurrence of a Release Condition, Client and Talent are deemed
          to and hereby agree that the instruction to Filly Jobs Escrow and its
          wholly owned subsidiaries to release funds is irrevocable. Without
          limiting the foregoing, Client’s instruction to Filly Jobs Escrow and
          its wholly owned subsidiaries to pay a Talent is irrevocable. Such
          instruction is Client’s authorization to transfer funds to Talent from
          the Client Escrow Account or authorization to charge Client’s Payment
          Method. Such instruction is also Client’s representation that Client
          has received, inspected and accepted the subject work or expense.
          Client acknowledges and agrees that upon receipt of Client’s
          instruction to pay Talent, Filly Jobs
        </p>
        <p className="mt-4">
          Escrow will transfer funds to the Talent and that Filly Jobs, Filly
          Jobs Escrow, and other Affiliates have no responsibility to and may
          not be able to recover such funds. Therefore, and in consideration of
          services described in this Agreement, Client agrees that once Filly
          Jobs Escrow or its subsidiary has charged Client’s Payment Method, the
          charge is non-refundable.
        </p>
      </div>

      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">5.</span> INSTRUCTIONS IRREVOCABLE
        </h2>
        <p className="">
          Client’s instruction to Filly Jobs Escrow and its wholly owned
          subsidiaries to pay a Talent is irrevocable. Such instruction is
          Client’s authorization to transfer funds to Talent from the Client
          Escrow Account or authorization to charge Client’s Payment Method.
          Such instruction is also Client’s representation that Client has
          received, inspected, and accepted the subject work or expense. Client
          acknowledges and agrees that upon receipt of Client’s instruction to
          pay Talent, Filly Jobs Escrow will transfer funds to the Talent and
          that Filly Jobs, Filly Jobs Escrow, and other Affiliates have no
          responsibility to and may not be able to recover such funds.
          Therefore, and in consideration of services described in this
          Agreement, Client agrees that once Filly Jobs Escrow or its subsidiary
          has charged Client’s Payment Method, the charge is non-refundable.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">6.</span> DORMANT ENGAGEMENTS
        </h2>
        <p className="">
          To be fair to Clients and Talents, Filly Jobs has a procedure for
          Direct Contracts that appear Dormant (as defined below). For purposes
          of determining Dormant status,{" "}
          <span className="font-semibold">“activity”</span> means business term
          or milestone updates or requests, Direct Contract Escrow Funding,
          Release requests, or requests to close the Contract.
        </p>
        <p className="mt-4">
          A <span className="font-semibold">"Dormant Engagement"</span> is a
          Direct Contract that has either not been signed within 7 calendar days
          of sending or that has a Direct Contract Escrow Account with a balance
          but has had no activity for 90 consecutive days after the last
          milestone date contained in the business terms. Dormant Engagements
          will be closed subject to the following rules:
        </p>
        <ol className="list-[auto] ml-6 lg:ml-8 mt-6" type="1">
          <li className="mb-4">
            Direct Contracts that remain unsigned by at least one party after 7
            days will be considered dormant and expire.
          </li>
          <li className="mb-4">
            For contracts with no activity for 45 consecutive days after the
            last milestone, Filly Jobs will notify Talent on or around the 45th
            day that the Direct Contract has become dormant (
            <span className="font-semibold">
              “Talent Notice of Dormant Engagement”
            </span>
            ).
          </li>
          <li className="mb-4">
            If Talent submits a Release request after the Talent Notice of
            Dormant Engagement and before the contract is closed, and Client
            does not take any action for 14 calendar days from the date of the
            Release request, Talent and Client authorize and irrevocably
            instruct Filly Jobs Escrow to immediately release to Talent the
            amount related to the Milestone with the Release request.
          </li>
          <li className="mb-4">
            If neither Talent nor Client take any action for 45 calendar days
            after the Talent Notice of Dormant Engagement, Talent and Client
            authorize and irrevocably instruct Filly Jobs Escrow to immediately
            release escrow funds to the Client and close the contract.
          </li>
          <li className="mb-4">
            All funds released to Talent under this Section, Dormant
            Engagements, will be subject to the applicable Fees as described in
            the Direct Contract Terms.
          </li>
        </ol>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">7.</span> REFUNDS AND CANCELLATIONS
        </h2>
        <p className="">
          Client and Talent are encouraged to come to a mutual agreement if
          refunds or cancellations are necessary. In the event that Client and
          Talent are unable to come to an agreement on their own, the following
          process will apply.
        </p>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">7.1</span> DISPUTE ASSISTANCE PROGRAM
          </h3>
          <p className="">
            If Client and Talent fail to come to a mutual resolution by way of
            the Refund and Cancellation process, Filly Jobs provides the dispute
            assistance program administered by Filly Jobs and described in this
            Section 6 (the{" "}
            <span className="font-semibold">“Dispute Assistance Program”</span>)
            as a mechanism to resolve the dispute. If the funds in dispute are
            held in Escrow, the Dispute Assistance Program will proceed as
            described in Section 6.1. If the funds in dispute have been
            released, the Dispute Assistance Program will proceed as described
            in Section 6.2. The Dispute Assistance Program is not available for
            disputes filed or initiated past the Dispute Assistance deadlines,
            as set forth in Sections 6.1 and 6.2, as applicable. The Dispute
            Assistance Program is offered as a form of non-binding assistance to
            facilitate communication and potential resolution of disputes
            between clients and Talents. The Dispute Assistance Program as
            administered by Filly Jobs in this Section 6 does not evaluate the
            quality or functionality of work and cannot render binding judgment
            or determination as to the parties’ respective rights to the
            disputed funds.
          </p>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">7.2</span> DISPUTE OF FUNDS IN ESCROW
          </h3>
          <p className="mb-6">
            This Section applies to disputes filed by Clients or Talents over
            funds that are held in Escrow as of the date the dispute is filed (
            <span className="font-semibold">“Escrow Dispute”</span>). In the
            event of an Escrow Dispute, funds in Escrow will remain in Escrow
            while the Dispute Assistance Program is being administered. The
            Dispute Assistance Program for an Escrow Dispute will be
            administered as set forth below:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              <span className="font-semibold">
                Escrow Dispute Assistance Deadline:
              </span>{" "}
              Escrow Disputes must be received before the funds in Escrow have
              been released in order to be eligible for the Dispute Assistance
              Program under this Section. Disputes over funds that have been
              released from Escrow may be eligible for assistance under Section
              6.2 below.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Filling a Dispute</span>
              <ol className="list-[lower-alpha] ml-6 lg:ml-8 mt-4">
                <li className="mb-4">
                  <span className="font-semibold">Talents & Agencies:</span>
                  Talents and Agencies may initiate an Escrow Dispute within 7
                  calendar days of when a Client ends a project with an escrow
                  balance or a Client fails to release a Milestone payment,
                  despite any purported delivery of work.
                </li>
                <li className="mb-4">
                  <span className="font-semibold">Clients:</span>
                  Clients may initiate an Escrow Dispute by requesting an Escrow
                  refund on the platform. If the Talent disputes the Escrow
                  refund or offers a partial Escrow refund that is subsequently
                  rejected by the Client, the case will be referred to the
                  Dispute Assistance Program.
                </li>
              </ol>
            </li>
            <li className="mb-4">
              <span className="font-semibold">Notice of Escrow Dispute:</span>{" "}
              Once a dispute has been filed, the Escrow dispute team that
              administers the Disputes Assistance Program (
              <span className="font-semibold">“Escrow Disputes Team”</span>)
              will notify Client and Talent in writing of the Escrow dispute via
              ticket and request information and supporting documentation from
              the parties (
              <span className="font-semibold">“Notice of Escrow Dispute”</span>
              ).
            </li>
            <li className="mb-4">
              <span className="font-semibold">Lack of Participation:</span>{" "}
              Client and Talent must respond to the Notice of Escrow Dispute
              within 5 calendar days. If one party does not timely respond to
              the Notice of Escrow Dispute, Client and Talent agree that the
              lack of timely response acts as an irrevocable authorization and
              instruction to Filly Jobs Escrow to release the funds in Escrow
              from the non-responding party to the responding party.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Non-Binding Assistance:</span>{" "}
              After both Client and Talent respond to the Notice of Escrow
              Dispute, the Escrow Disputes Team will review the documentation
              submitted and any information available on the Site that pertains
              to the dispute. The Escrow Disputes Team will facilitate
              communication between the parties and help assess whether mutual
              resolution is possible. The Escrow Disputes Team does not evaluate
              the quality or functionality of work and cannot render binding
              judgment or determination as to the parties’ respective rights to
              the disputed funds.
            </li>
            <li className="mb-4">
              <span className="font-semibold">
                Resolution of Escrow Dispute:
              </span>{" "}
              If the Escrow Disputes Team is able to facilitate a resolution
              between Client and Talent, and if Client and Talent agree in
              writing to the resolution, Client and Talent agree that Filly Jobs
              Escrow is authorized and irrevocably instructed to immediately
              release Escrow funds in accordance with the agreed-upon
              resolution. This will result in a closure of the Escrow dispute
              ticket.
            </li>
            <li className="mb-4">
              <span className="font-semibold">No Resolution:</span> If no 7. No
              Resolution: If no resolution of the Escrow dispute has been
              reached within 21 calendar days of the Notice of Escrow Dispute,
              or if the Escrow Disputes Team determines in its sole discretion
              that no resolution between the parties is possible through the
              Dispute Assistance Program, Client and Talent agree that Filly
              Jobs Escrow is authorized and irrevocably instructed to
              immediately release Escrow funds in equal amounts to Client and
              Talent. This will result in a closure of the Escrow dispute
              ticket.
            </li>
          </ol>
          <div className="mt-8">
            <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
              <span className="mr-1">7.3</span> DISPUTE OF FUNDS RELEASED
            </h3>
            <p className="">
              This Section applies to disputes between Clients or Talents over
              funds that have been released from Escrow as of the date the
              dispute is filed. If there are no funds in escrow, the dispute
              procedures outlined in Section 6.1 above will not apply. However,
              Talent may issue a refund up to the full amount paid on the Direct
              Contract at any time, and Filly Jobs may facilitate communication
              between the Client and Talent if Client contacts Filly Jobs to
              request a refund within 7 calendar days of the release of the
              funds at issue.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">8.</span> NOTICES
        </h2>
        <p className="">
          All notices to a User required by these Escrow Instructions will be
          made via email sent by Filly Jobs to the User’s registered email
          address. Users are solely responsible for maintaining a current,
          active email address registered with Filly Jobs, for checking their
          email and for responding to notices sent by Filly Jobs to the User’s
          registered email address.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">9.</span> ABUSE
        </h2>
        <p className="">
          Filly Jobs, in its sole discretion, reserves the right to suspend or
          terminate your Account immediately upon giving notice to you if Filly
          Jobs believes you may be abusing the Dispute Assistance Program or as
          otherwise permitted by the{" "}
          <a
            href="/terms-and-conditions"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Terms of Service
          </a>
          . However, any Disputes for any Fixed-Price Contracts that existed
          prior to termination will be subject to the Terms of Service.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">10.</span> NO RESPONSIBILITY FOR SERVICES OR
          PAYMENTS
        </h2>
        <p className="">
          Filly Jobs and Affiliates merely provide a platform for Internet
          payment services. Filly Jobs and Affiliates do not have any
          responsibility or control over the Talent Services that Client
          purchases. Nothing in this Agreement deems or will be interpreted to
          deem Filly Jobs or any Affiliate as Client’s or Talent’s agent with
          respect to any Talent Services, or expand or modify any warranty,
          liability or indemnity stated in the Terms of Service. For example,
          Filly Jobs does not guarantee the performance, functionality, quality,
          or timeliness of Talent Services or that a Client can or will make
          payments.
        </p>
      </div>
    </div>
  );
};

export default FixedPriceDirect;
