import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/image/logo_white.png";

function Footer(props) {
  return (
    <footer className="min-h-[30vh] bg-black text-white">
      <div className="px-5 py-12 max-w-xl mx-auto lg:max-w-7xl flex flex-col sm:flex-row sm:flex-wrap justify-center lg:justify-between items-center sm:items-start">
        <div className="text-center sm:text-left w-full sm:w-1/2 lg:w-auto sm:mx-8 lg:mx-0 mb-6 lg:mb-0 flex-1 xl:flex-auto">
          <h4 className="font-semibold text-2xl">Contact</h4>
          <ul className="contact-details mt-4 text-sm">
            <li className="w-full flex items-center justify-center sm:justify-start mb-4">
              <ion-icon name="location" class="text-xl"></ion-icon>
              <p className=" ml-2 sm:ml-4">
                388 Market Street, Suite 1300, San Francisco CA 94111ct
              </p>
            </li>
            <li className="w-full flex items-center justify-center sm:justify-start mb-4">
              <ion-icon name="call"></ion-icon>
              <p className="ml-2 sm:ml-4">+1-650-449-9546</p>
            </li>
            <li className="w-full flex items-center justify-center sm:justify-start mb-4">
              <ion-icon name="mail"></ion-icon>
              <p className="ml-2 sm:ml-4">contact@filly jobs.com</p>
            </li>
            <li className="w-full flex items-center justify-center sm:justify-start mb-4">
              <ion-icon name="globe"></ion-icon>
              <p className="ml-2 sm:ml-4">fillyjobs.com</p>
            </li>
          </ul>
        </div>
        <div className="text-center sm:text-left w-full sm:w-1/2 lg:w-auto sm:mx-8 lg:mx-0 mb-6 lg:mb-0 flex-1 xl:flex-auto">
          <h4 className="font-semibold text-2xl">Services</h4>
          <ul className="services mt-4 text-sm">
          <li className="mb-2">
              <Link className="hover:text-bondi-blue transition-all" to="/howitworks-hire">How It Works</Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-bondi-blue transition-all" to="/careers">Careers</Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-bondi-blue transition-all" to="/contact">Contact Us</Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-bondi-blue transition-all" to="/faqs-freelancer">FAQs</Link>
            </li>
          </ul>
        </div>
        <div className="text-center sm:text-left w-full sm:w-1/2 lg:w-auto sm:mx-8 lg:mx-0 mb-6 lg:mb-0 flex-1 xl:flex-auto">
          <h4 className="font-semibold text-2xl">Information</h4>
          <ul className="information mt-4 text-sm">
            <li className="mb-2">
              <Link className="hover:text-bondi-blue transition-all" to="/about">About Us</Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-bondi-blue transition-all" to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-bondi-blue transition-all" to="/terms-and-conditions">Terms & Conditions</Link>
            </li>
            <li className="mb-2">
              <Link className="hover:text-bondi-blue transition-all" to="#">Filly Jobs Legal</Link>
            </li>
          </ul>
        </div>
        <div className="text-center sm:text-left w-full sm:w-1/2 lg:w-auto sm:mx-8 lg:mx-0 mb-6 lg:mb-0 flex-1 xl:flex-auto">
          <h4 className="font-semibold text-2xl">Socials</h4>
          <div className="social flex justify-between items-center mt-8 mb-5 w-48 mx-auto sm:ml-0">
            <a href="#" aria-label="instagram">
              <i className="text-3xl hover:text-bondi-blue fab fa-instagram"></i>
            </a>
            <a href="#" aria-label="linkedin">
              <i className="text-3xl hover:text-bondi-blue fab fa-linkedin"></i>
            </a>
            <a href="#" aria-label="twitter">
              <i className="text-3xl hover:text-bondi-blue fab fa-twitter"></i>
            </a>
            <a href="#" aria-label="facebook">
              <i className="text-3xl hover:text-bondi-blue fab fa-facebook-f"></i>
            </a>
          </div>
          <div className="">
            <p className="text-sm">
              Send your enquiries to{" "}
              <a href="mailto:info@fillyjobs.com" className="text-bondi-blue">
                info@fillyjobs.com
              </a>
            </p>
          </div>
          <Link to="/" className="logo mx-4 ">
            <img src={logo} alt="logo" className="h-40 mx-auto sm:ml-0" />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
