import React from "react";
import ConnectWithUs from "../Components/Careers/ConnectWithUs";
import Hero from "../Components/Careers/Hero";
import JobApplication from "../Components/Careers/JobApplication";
import OurCoreValues from "../Components/Careers/OurCoreValues";
import OurMission from "../Components/Careers/OurMission";
import WorkWithUs from "../Components/Careers/WorkWithUs";
import BlueBox from "../Components/shared/BlueBox";

const Careers = () => {
  return (
    <section className="mx-auto">
      <div className="flex flex-col items-center justify-between scroll-smooth">
        <Hero />
        <OurMission />
        <OurCoreValues />
        <WorkWithUs />
        <ConnectWithUs />
        <BlueBox>
          <>
            <h2 className="relative font-normal text-xl lg:text-2xl text-center mx-auto">
              Would you like to recieve updates from us?
              <div className="font-semibold mt-3">
                Subscribe to our Newsletter
              </div>
            </h2>
            <div className="relative w-full flex flex-wrap md:flex-nowrap justify-center lg:justify-center mx-auto">
              <form class="mt-8 sm:flex w-full max-w-sm">
                <label for="email" class="sr-only">
                  Your work email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="w-full px-5 py-3 placeholder-dim-gray text-dark-gray sm:max-w-sm focus:outline-none rounded-md md:rounded-r-none"
                  placeholder="Your work email address"
                />
                <div class="mt-3 rounded-md md:rounded-l-none shadow sm:mt-0 sm:flex-shrink-0 bg-white">
                  <button
                    type="submit"
                    class="w-full h-full flex items-center justify-center px-5 py-3 text-sm font-medium rounded-md md:rounded-l-none text-white bg-bright-red hover:bg-opacity-30 hover:text-bright-red transition-all focus:outline-none"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </>
        </BlueBox>
        <JobApplication />
      </div>
    </section>
  );
};

export default Careers;
