import React from "react";
import aboutUsImg from "../../assets/about/banner/about-us-hero-img.jpg";

const Hero = () => {
  return (
    <>
      <div className="relative bg-black h-[470px] w-full lg:overflow-hidden">
        <div className="absolute inset-0 -top-20"></div>
        <img
          src={aboutUsImg}
          alt="people working on computers"
          className="w-full h-full lg:h-fit object-cover lg:object-contain lg:object-[0_-10rem] opacity-30"
        />
        <div className="text-white my-auto px-5 text-center flex flex-col items-center justify-center mx-auto absolute inset-x-0 top-1/3 sm:top-[40%] xl:top-[47%] max-w-xl lg:max-w-4xl">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold !leading-[140%]">
            Our Mission is to provide and connect talents with opportunities
          </h1>
          <p className="mt-3 lg:mt-5 lg:text-lg">
            Find jobs from anywhere in the world
          </p>
        </div>
      </div>
    </>
  );
};

export default Hero;
