import React from "react";
import { Link } from "react-router-dom";

const Faqs = () => {
  return (
    <section className="mx-auto">
      <div className="relative flex flex-col items-center justify-between text-dark-gray">
        <div className="h-[358px] bg-bondi-blue text-white w-full">
          <div className="max-w-xl lg:max-w-7xl mx-auto text-center px-5 mt-24">
            <h1 className="uppercase text-2xl sm:text-3xl lg:text-4xl my-2 font-semibold">
              frequently asked questions
            </h1>
            <h2 className="mt-3 text-sm sm:text-base lg:text-xl font-poppins">
              Please find answers to your frequently asked questions below. If
              you still have any questions, please contact us at{" "}
              <a href="mailto:contact@fillyjobs.com">contact@fillyjobs.com</a>{" "}
            </h2>
          </div>
        </div>
        <div className="relative rounded-t-[50px] -mt-20 bg-white overflow-hien w-full max-w-xl lg:max-w-7xl min-h-[30vh] pt-10 px-8 mb-20 pb-1">
          <div className="preference flex justify-center absolute inset-x-4 sm:inset-x-0 -top-5 sm:-top-6">
            <Link
              to="/faqs-client"
              className="font-semibold shadow-md text-sm lg:text-base lg:w-full flex justify-center text-center max-w-md hover:text-white bg-white hover:bg-dark-cerulean-blue px-10 lg:px-16 mb-5 py-3 lg:mb-0 lg:flex lg:items-center cursor-pointer transition-all"
            >
              I want to hire
            </Link>
            <Link
              to="/faqs-freelancer"
              className="font-semibold shadow-md text-sm lg:text-base lg:w-full flex justify-center text-center max-w-md text-white hover:text-bondi-blue bg-dark-cerulean-blue hover:bg-white px-10 lg:px-16 mb-5 py-3 lg:mb-0  lg:flex lg:items-center cursor-pointer transition-all"
            >
              I want to work
            </Link>
          </div>
          <div className="mb-8 mt-16">
            <p className="">
              Please find answers to your frequently asked questions below. If
              you still have any questions, please contact us at{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>{" "}
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
              How do I apply for jobs?
            </h2>
            <p className="">
              Search our database of job listings by industry, experience level,
              and more. Filter the list of jobs to match your needs and
              qualifications.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
              How long does it take to get a job?
            </h2>
            <p className="">
              Once you’ve set up your Talent (I want to work) account, you can
              start applying for open positions.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
              What are the benefits of completing my profile?
            </h2>
            <p className="">
              The more complete a Talent's profile is, the better chance they
              have of getting a job. The more professional the profile is, the
              higher the confidence of a potential employer that you can
              accomplish the job successfully.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
              How do I complete my profile?
            </h2>
            <p className="">
              Your profile will only be complete if you fill in all the required
              sections on your profile.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
              How safe is your payment system?
            </h2>
            <p className="">
              Funds are deposited into an escrow account for the benefit of
              talents. The funds will be used to make sure payments are made as
              agreed upon.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
              What is the minimum amount I’ll be paid?
            </h2>
            <p className="">
              Filly Jobs does not support contract rates under $3.00 USD per
              hour for hourly contracts. For fixed-price contracts, the minimum
              payment for work is $5.00 USD. This means a Talent must be paid at
              least $3.00 an hour for hourly contracts. Fixed-price contracts
              must pay a minimum of $5.00 for a project
            </p>
          </div>

          <div className="mb-8">
            <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
              Is there any upfront recruiting or a contractual clause?
            </h2>
            <p className="">
              As a talent, you’ll pay a sliding fee based on your total lifetime
              billings with a client. Because we want to give you the
              flexibility to change contract types as you work together, we
              count all your contracts with a client –– whether hourly,
              fixed-price, or a Project Catalog project. Find out more on{" "}
              <Link
                to="/escrow-manual"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                Escrow Manual
              </Link>
              .
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
              Where do Filly Job experts work?
            </h2>
            <p className="">
              Our head office is located in San Francisco, California, but we
              work remotely all over the world. We are able to get excellent
              results for our clients through our remote strategy, which allows
              us to be flexible and responsive with schedules that work for you.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
