import { useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";

import UserRoot from "./UserRoot";
import { store } from "../app/store";
import { Provider } from "react-redux";import GuestRoot from "./GuestRoot";

function useScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const Root = () => {
  const { pathname } = useLocation();
  const isAdminRoute = useMatch("/admin");
  const isUserRoute = useMatch("/me");
  const [userPath, setUserPath] = useState("guest");
  useEffect(() => {
    if (isUserRoute) setUserPath("user");
    else if (isAdminRoute) setUserPath("admin");
    else setUserPath("guest");
  }, [pathname]);

  useScrollToTop();
  return (
    <>
      <Provider store={store}>
        
        {userPath === "admin" ? (
          <></>
        ) : userPath === "user" ? (
          <UserRoot />
        ) : (
          <GuestRoot />
        )}
      </Provider>
    </>
  );
};

export default Root;
