import React from "react";
import { Link } from "react-router-dom";

const EscrowManual = () => {
  return (
    <div className="">
      <h2 className="font-semibold text-black text-xl my-1 mb-2">
        Table of contents
      </h2>
      <p className="">Valid since 25th August 2022</p>
      <ul className="mt-8 text-bondi-blue">
        <li className="border-b border-bondi-blue w-fit mb-6">
          <Link to="hourly-bonus" className="">
            Hourly, Bonus, and Expense Payment Agreement with Escrow
            Instructions
          </Link>
        </li>
        <li className="border-b border-bondi-blue w-fit mb-6">
          <Link to="fixed-price-service" className="">
            Fixed-Price Escrow Instructions
          </Link>
        </li>
        <li className="border-b border-bondi-blue w-fit mb-6">
          <Link to="fixed-price-direct" className="">
            Fixed Price Direct Contract Escrow Instructions
          </Link>
        </li>
        <li className="border-b border-bondi-blue w-fit mb-6">
          <Link to="hourly-direct" className="">
            Hourly Direct Contract Escrow Instructions
          </Link>
        </li>
        <li className="border-b border-bondi-blue w-fit mb-6">
          <Link to="escrow-inc" className="">
            Filly Jobs Escrow Inc.
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default EscrowManual;
