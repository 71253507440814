import React from "react";
import ourMissionImg from "../../assets/careers/our-mission/pexels-sora-shimazaki-5673487.jpg";
const OurMission = () => {
  return (
    <section className="px-5 mx-auto lg:w-full max-w-xl lg:max-w-7xl my-20">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <h2 className="font-medium text-xl flex-1 max-w-lg mb-3 lg:mb-0">
          Our mission is to provide employment oppoptunities for myrads of
          talents and to better the lives of the masses
        </h2>
        <p className="font-light flex-1 max-w-lg">
          We aim to offer workplaces that foster social and economic
          development. Our employees are our greatest assets, and we work hard
          to keep them engaged in a workplace that's collaborative, gives them
          the flexibility they need to meet their goals, and rewards them with a
          sense of purpose.
        </p>
      </div>
      <div className="img-wrapper w-full lg:h-96 xl:h-[575px] rounded-xl overflow-hidden mt-7">
        <img
          src={ourMissionImg}
          alt="three people greeting each other"
          className=""
        />
      </div>
    </section>
  );
};

export default OurMission;
