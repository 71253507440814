import React from "react";

const HourlyBonus = () => {
  return (
    <div className="font-thin text-dark-gray">
      <h2 className="font-semibold text-black text-xl my-1 mb-2">
        Hourly, Bonus, and Expense Payment Agreement with Escrow Instructions
      </h2>
      <p className="font-normal text-black">Valid since 25th August 2022</p>
      <div className="mt-8">
        <p className="">
          This Hourly, Bonus, and Expense Payment Agreement with Escrow
          Instructions (“Agreement”) applies If Client and Talent enter into an
          Hourly Contract, if Client makes a bonus or expense payment to Talent.
        </p>
        <p className="mt-3">
          To the extent permitted by applicable law, we may modify this
          Agreement, and the Escrow Instructions it contains, without prior
          notice to you, and any revisions to the Agreement will take effect
          when posted on the website unless otherwise stated. Please check the
          website often for updates.
        </p>

        <p className="mt-3">
          This Agreement hereby incorporates by reference the Terms of Service.
          Capitalized terms not defined in this Agreement are defined in the
          <a href="#" className="border-b border-bondi-blue text-bondi-blue">
            {" "}
            User Agreement
          </a>
          , elsewhere in the Terms of Service, or have the meanings given such
          terms on the Site. The Escrow Instructions in this Agreement do not
          apply to Fixed-Price Escrow Accounts, except that they govern the
          making and receiving of bonus, expense and other miscellaneous
          payments for Fixed-Price Contracts.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">1.</span> DIGITAL SIGNATURE
        </h2>
        <p className="">
          By clicking to accept an Hourly Contract or make a bonus payment,
          Client and Talent are deemed to have executed this Agreement
          electronically, effective on the date Talent clicks to accept an
          Hourly Contract, pursuant to California Civil Code section 1633.8 and
          the federal Electronic Signatures in Global and National Commerce Act,
          15 U.S.C. Sec. 7001, et seq., as amended from time to time (the
          "E-Sign Act"). Doing so constitutes an acknowledgement that Client and
          Talent agree to conduct the transaction electronically, and are able
          to electronically receive, download, and print this Agreement and the
          Escrow Instructions it contains.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">2.</span> MAKING OR RECEIVING AN HOURLY PAYMENT
        </h2>
        <h3 className="font-semibold text-black text-lg mb-2">
          <span className="mr-1">2.1</span> WEEKLY HOURLY INVOICES
        </h3>
        <p className="">
          For Hourly Contracts, the weekly billing cycle starts Monday at 00:00
          midnight UTC and ends Sunday at 23:59 UTC. Invoices for hours recorded
          on Filly Jobs in the Work Diary are generated each Monday following
          the week in which the hours were billed (the{" "}
          <span className="font-semibold">"Hourly Invoice Deadline"</span> ).
          Talent irrevocably authorizes and instructs Filly Jobs, as its agent,
          to (i) create an invoice on behalf of Talent for payment due based
          upon the hours that the Talent recorded in the Work Diary before the
          Hourly Invoice Deadline (such invoice, the{" "}
          <span className="font-semibold">"Hourly Invoice"</span>); and (ii)
          submit the Hourly Invoice on behalf of Talent to Talent's Client for
          payment. For the avoidance of doubt, the{" "}
          <span className="font-semibold">“Work Diary”</span> is the section
          Filly Jobs where hours can be recorded on an Hourly Contract.
        </p>
        <p className="mt-3">
          By recording time in the Work Diary and allowing an Hourly Invoice to
          be created based on the time recorded, Talent represents and warrants
          that (y) Talent has completed the applicable Talent Services fully and
          satisfactorily; and (z) the hours Talent reports are true, accurate,
          and complete.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">3.</span> HOURLY INVOICE REVIEW
        </h2>
        <p className="">
          Client must review and approve or dispute the Hourly Invoice by 11:59
          PM UTC of the Friday following submission of the Hourly Invoice.
          Payments will be held in escrow during the Dispute Period (defined
          below), providing four additional days to review and dispute the
          invoice before funds are released. During the Dispute Period, Client
          may initiate a Dispute as to some or all of the time invoiced on the
          Hourly Invoice.
        </p>
        <p className="mt-3">
          On the Friday of the week following submission of the Hourly Invoice,
          Client will be deemed to have approved all undisputed amounts on the
          Hourly Invoice, and irrevocably instructed Filly Jobs’s Affiliate,
          Filly Jobs Escrow, to release escrow funds as described in this
          Agreement. Notwithstanding the foregoing, all hours recorded and
          submitted by Payroll Employee on the Filly Jobs website will be deemed
          approved by Client automatically, and Client authorizes and
          irrevocably instructs Filly Jobs Escrow to release payments for those
          hours to be paid to the Staffing Provider by Client's account each
          Monday following the week in which the hours were worked. Client may
          not reject time or file a Dispute for Payroll Employee time worked.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">4.</span> MAKING OR RECEIVING A BONUS OR
          EXPENSE PAYMENT
        </h2>
        <p className="">
          Client may also make a bonus, tip, expense, or other miscellaneous
          payment to Filly Jobs using the Site. To make such a payment to a
          Filly Jobs, Client must follow the instructions and links on the Site
          and provide the information requested. If Client clicks to pay such a
          payment to Filly Jobs, Client irrevocably instructs Filly Jobs Escrow
          to and Filly Jobs Escrow will release escrow funds as described in
          this Agreement.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">5.</span> INSTRUCTIONS TO PAY IRREVOCABLE
        </h2>
        <p className="">
          Client’s instruction to Filly Jobs Escrow and its wholly owned
          subsidiaries to pay a Talent is irrevocable. Such instruction is
          Client’s authorization to transfer funds to Talent from the Client
          Escrow Account or authorization to charge Client’s Payment Method.
          Such instruction is also Client’s representation that Client has
          received, inspected, and accepted the subject work or expense. Client
          acknowledges and agrees that upon receipt of Client’s instruction to
          pay Talent, Filly Jobs Escrow will transfer funds to the Talent and
          that Filly Jobs, Filly Jobs Escrow, and other Affiliates have no
          responsibility to and may not be able to recover such funds.
          Therefore, and in consideration of services described in this
          Agreement, Client agrees that once Filly Jobs Escrow or its subsidiary
          has charged Client’s Payment Method, the charge is non-refundable.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">6.</span> RELEASE AND DELIVERY OF AMOUNTS IN
          ESCROW
        </h2>
        <p className="">
          In addition, Filly Jobs Escrow is authorized to and will release
          applicable portions of the Client Escrow Account (each portion, a
          “Release”) to the Talent Escrow Account, upon the occurrence of and in
          accordance with one or more Release Conditions provided below or as
          otherwise required by applicable law or the Terms of Service. The
          amount of the Release will be delivered to the Talent Escrow Account,
          in accordance with Talent’s and Client’s instructions, as applicable,
          these Escrow Instructions, and the other Terms of Service.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
          <span className="mr-1">6.1</span> RELEASE CONDITION
        </h2>
        <p className="mb-6">
          As used in these Escrow Instructions,{" "}
          <span className="font-semibold">“ Release Condition”</span> means any
          of the following:
        </p>
        <ol className="list-[auto] ml-6 lg:ml-8" type="1">
          <li className="mb-4">
            Client and Talent have submitted joint written instructions for a
            Release.
          </li>
          <li className="mb-4">
            Client has approved all or a portion of the Talent's Hourly Invoice.
            This Release Condition will only apply to amounts invoiced by the
            Talent that Client has approved. Client’s failure to dispute an
            Hourly Invoice, or a portion of an Hourly Invoice, within the
            Dispute Period pursuant to this Agreement, or as otherwise provided
            in the Escrow Instructions, constitutes approval by the Client for
            purposes of this Release Condition.
          </li>
          <li className="mb-4">
            Talent is a Payroll Employee and has submitted an Hourly Invoice
            under a Services Contract that involves the use of Filly Jobs
            Payroll.
          </li>
          <li className="mb-4">
            Filly Jobs reviews Client's dispute of amounts invoiced on Talent's
            Hourly Invoice for an Hourly Contract with Work Diaries pursuant to
            this Agreement and determines that the time is related to the Hourly
            Contract requirements or Client's instructions.
          </li>
          <li className="mb-4">
            Client initiates a Dispute with respect to Talent's Hourly Invoice
            for an Hourly Contract without Work Diaries pursuant to this
            Agreement and Client and Talent resolve the dispute without the
            assistance of Filly Jobs.
          </li>
          <li className="mb-4">
            Issuance of a final order of a court or arbitrator of competent
            jurisdiction from which appeal is not taken, in which case the funds
            will be released in accordance with such order.
          </li>
          <li className="">
            We believe, in our sole discretion, that fraud, an illegal act, or a
            violation of Filly Jobs's Terms of Service has been committed or is
            being committed or attempted, in which case Client and Talent hereby
            irrevocably authorize and instruct Filly Jobs Escrow to take such
            actions as we deem appropriate in our sole discretion and in
            accordance with applicable law, in order to prevent or remedy such
            acts, including without limitation to return the funds associated
            with such acts to their source of payment.
          </li>
        </ol>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">7.</span> PAYMENT PROTECTION
        </h2>
        <p className="">
          Filly Jobs provides limited payment protection to Users as detailed in
          this Section 6 (
          <span className="font-semibold">"Hourly Payment Protection"</span>).
        </p>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">7.1</span> FOR TALENTS
          </h3>
          <p className="mb-6">
            In the rare event that a Talent’s Client does not make payment for
            legitimate services performed by a Talent, Filly Jobs will provide
            Hourly Payment Protection to the Talent as detailed in this Section
            6.1 as a membership benefit to foster fairness, reward loyalty, and
            encourage the Talent to continue to use the Site Services for their
            business needs. Hourly Payment Protection will be offered to a
            Talent only if all of the following criteria are met in Filly Jobs's
            sole discretion:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              Both Client and Talent must have agreed to use Work Diaries upon
              acceptance of the Hourly Contract, as part of the terms.
            </li>
            <li className="mb-4">
              Client must have an Account in good standing and a valid and
              verified Payment Method at the start of the Hourly Contract, and
              must agree to automatically pay for hours billed by Talent through
              Work Diaries
            </li>
            <li className="mb-4">
              Talent's Account must be in good standing at the time of the
              payment at issue, including, for example, completion of the
              identify verification process, tax information, and any other
              similar requests or requirements.
            </li>
            <li className="mb-4">
              Talent must have used Work Diaries enabled to document any and all
              hours covered by the Hourly Payment Protection for Talents.
            </li>
            <li className="mb-4">
              Prior to any Hourly Invoice being submitted, Talent must have
              annotated the screenshots documented in Work Diaries sufficient to
              demonstrate the work relates to the Hourly Contract.
            </li>
            <li className="mb-4">
              The screenshots documented by Work Diaries must be clearly related
              to the applicable Hourly Contract requirements or Client
              instructions.
            </li>
            <li className="mb-4">
              The number of hours billed in the Work Diaries must be within the
              hours authorized in the Hourly Contract for the week.
            </li>
            <li className="mb-4">
              Within five days after notification of rejected or unpaid time,
              Talent must submit a Dispute specifically identifying the
              documented work not otherwise paid for by their Client through the
              Escrow Services.
            </li>
          </ol>
          <p className="mt-4">
            Filly Jobs will investigate and determine in its sole discretion
            whether the above terms and conditions are met.
          </p>
          <p className="mt-4">
            Hourly Payment Protection does not apply to: (a) hours invoiced for
            work not agreed to or authorized by Client; (b) bonus payments; (c)
            refunds; (d) manual time; (e) time added after Client has disputed a
            billing and before the resolution of that dispute; (6) Fixed-Price
            Contracts; (f) hours reported by Payroll Employees; (g) any payments
            on Hourly Contracts where the Hourly Contract or the services
            provided thereunder are prohibited by the Terms of Service; (h)
            hours billed by Talents whom Filly Jobs believes, in its sole
            discretion, to be aware of or complicit in another User’s violation
            of this Agreement or the other Terms of Service; or (i) Talents whom
            Filly Jobs believes, in its sole discretion, to be involved in
            actual fraudulent activities or abuse of this Payment Protection.
          </p>
          <p className="">
            The maximum rate per hour protected by Filly Jobs to Talent under
            the Hourly Payment Protection for Talents is the lesser of: (i) the
            rate provided in the Hourly Contract terms; (ii) the usual hourly
            rate billed by Talent on the Site across all Clients; and (iii) the
            going rate for the same skills on the Site in Talent's area (such
            determination to be made in Filly Jobs's sole discretion). The
            maximum amount of coverage under the Hourly Payment Protection for
            Talents for the life of a relationship between the same Client and
            Talent is $2,500 or 50 hours logged in Work Diary at Talent's
            average hourly rate billed to Client, based on whichever is less.
          </p>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">7.2</span> FOR CLIENTS
          </h3>
          <p className="mb-6">
            Talent authorizes and instructs Filly Jobs to adjust the Hourly
            Invoice to remove invoiced hours that are not (a) clearly related to
            either the Hourly Contract terms or work agreed to by the Client,
            and (b) within the hours authorized in the Hourly Contract for the
            week, subject to and conditioned on the following terms:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              Both Client and Talent must agree to use Work Diaries as part of
              the Hourly Contract terms.
            </li>
            <li className="mb-4">
              Client must have an Account in good standing and a valid and
              verified default Payment Method, and Client must agree to
              automatically pay for hours billed by Talent in the Hourly
              Invoices. Within the Hourly Invoice Review Period, Client must
              submit a Dispute specifically identifying the time billed that is
              not clearly related to either the Hourly Contract requirements or
              Client instructions in the Work Diaries.
            </li>
          </ol>
          <p className="">
            Filly Jobs will investigate and determine in its sole discretion
            whether the above terms and conditions are met.
          </p>
          <p className="mt-4">
            Hourly Protection for Clients only protects Client from the
            obligation to pay for Talent’s work if the documented hours worked
            are not clearly related to the Hourly Contract requirements or
            Client instructions in the Work Diaries. If Client has requested
            that the Talent use functionality of the Site or third-party
            software that hides, blurs, or distorts images in the Work Diary,
            the screenshots will be deemed to be “clearly related” to the Hourly
            Contract requirements for purposes of the Client's eligibility for
            Hourly Payment Protection. Hourly Payment Protection for Clients
            does not create any warranties, express or implied, beyond those
            expressly stated in the User Agreement.
          </p>
          <p className="">
            Hourly Protection for Clients does not apply to: (1) Fixed-Price
            Contracts; (2) Hourly Contracts using Filly Jobs Payroll; or (3)
            hours billed by Talents who are aware of or complicit in another
            User's violation of this Agreement or the Terms of Service.
          </p>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">8.</span> DISPUTES BETWEEN CLIENT AND TALENT
        </h2>
        <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
          <span className="mr-1">8.1</span> DISPUTES INITIATED VIA THE PLATFORM
        </h3>
        <p className="">
          For Hourly Contracts, Client may dispute Talent’s hours invoiced on
          the Hourly Invoice for the prior week (Sunday 12:00 a.m. midnight UTC
          to Sunday 11:59 p.m. UTC) during the five days following the close of
          the weekly invoice period (Monday 12:00 a.m. midnight UTC to Friday
          11:59 p.m. UTC) (the “Dispute Period“). It is Client’s responsibility
          to review the Hourly Invoice of every Hourly Contract on a weekly
          basis and to file any disputes during the{" "}
          <span className="font-semibold"> Dispute Period</span>. Once the
          Dispute Period expires, Client will be deemed to have accepted the
          Talent Services and Talent Fees and can no longer dispute them.
          Disputes handled by Filly Jobs can only address the hours billed, not
          the quality of the Talent Services or the Work Product provided under
          Hourly Contracts. If Client disputes Talent’s hours invoiced in the
          Hourly Invoice under an Hourly Contract during the Dispute Period,
          Client and Talent are encouraged to resolve the dispute between
          themselves. If Client and Talent fail to come to a resolution, Filly
          Jobs will promptly investigate the Hourly Invoice and determine, in
          our sole discretion, whether an adjustment is appropriate consistent
          with Client’s and Talent’s instructions in these Escrow Instructions.
          Filly Jobs's determination of such dispute shall be final.
        </p>
        <p className="mt-4">
          If Client’s payment is unsuccessful, Filly Jobs will review the Weekly
          Invoice to determine if it qualifies for Hourly Payment Protection. If
          Filly Jobs, in its sole discretion, determines that the Weekly Invoice
          qualifies for Hourly Payment Protection, it will make payment to the
          Talent to cover the Hourly Invoice on behalf of the Client. In the
          event that Filly Jobs makes payment on behalf of the Client, Talent
          hereby irrevocably assigns any right, title or interest in any payment
          from Client to Filly Jobs for the amount paid by Filly Jobs.
        </p>
        <p className="mt-4">
          Client may choose to approve Talent’s Weekly Invoice prior to the end
          of the Dispute Period thereby releasing payment for the Weekly
          Invoice. If Client releases payment to Talent prior to the end of the
          Dispute Period, Client certifies that it approves the Weekly Invoice,
          accepts the work, and waives any further right to dispute the work or
          Weekly Invoice through Filly Jobs's Dispute Assistance (defined
          below).
        </p>
        <p className="mt-4">
          You acknowledge and agree that Filly Jobs Escrow or its Affiliates
          may, at its sole discretion, withhold or delay payment in the event of
          dispute between a Client and a Talent. You further acknowledge and
          agree that Filly Jobs and Affiliates are not and will not be a party
          to any dispute between a Client and Talent over an Hourly Invoice or
          Hourly Contract. Clients may not dispute hours worked with respect to
          any worker engaged as an employee through Filly Jobs Payroll. The
          Dispute Period does not apply and payments are released immediately
          upon completion of the Hourly Invoice for the prior week for Hourly
          Contracts where the Client has one or more Service Contracts using
          Filly Jobs Payroll.
        </p>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">8.2</span> FILLY JOBS DISPUTE ASSISTANCE
          </h3>
          <p className="mb-6">
            Non-binding dispute assistance (“Dispute Assistance”) is available
            within 30 days of the date of the last release of funds from Client
            to Talent. If Client or Talent contacts Filly Jobs via support
            ticket within 30 days of the date of the last payment from Client to
            Talent and requests non-binding dispute assistance for any dispute
            among them (a “Dispute”), Filly Jobs will attempt to assist Client
            and Talent by reviewing the Dispute and proposing a mutual,
            non-binding resolution. Filly Jobs will only review the 30 days of
            work performed prior to the date a User requests Dispute Assistance.
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              The Filly Jobs Disputes team will notify Client and Talent via
              ticket by providing a notice of dispute along with a request for
              information and supporting documentation
            </li>
            <li className="mb-4">
              If both Client and Talent respond to the notice and request for
              information, then the Disputes team will review the documentation
              submitted and any information available on the Site that pertains
              to the Dispute. After review, the Disputes team will propose a
              mutual, non-binding resolution based on the results of the review.
            </li>
            <li className="mb-4">
              The proposed resolution is non-binding; Client and Talent can
              choose whether or not to agree to it. If Client and Talent agree
              in writing to the proposed resolution, Client and Talent agree
              that Filly Jobs Escrow is authorized and irrevocably instructed to
              immediately release Escrow funds in accordance with the proposed
              resolution.
            </li>
            <li className="mb-4">
              If Client or Talent rejects Filly Jobs’s proposed, non-binding
              resolution then Client and/or Talent must pursue the Dispute
              independently.
            </li>
            <li className="mb-4">
              Filly Jobs reserves the right to review the Talent’s work for 30
              days prior to the date of the request for Dispute Assistance for
              compliance with Hourly Payment Protection requirements, and in its
              sole discretion, to make adjustments to invoices, and to direct
              Filly Jobs Escrow to make appropriate releases to Client if it
              finds work that clearly does not relate Hourly Contract
              requirements or Client instructions in the Work Diaries or
              violations of the Terms of Service during its review of the work.
            </li>
          </ol>
          <p className="mt-2">
            Dispute Assistance does not apply to Talent Services using Filly
            Jobs Payroll.
          </p>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">9.</span> REFUNDS AND CANCELLATIONS
        </h2>
        <p className="">
          Client and Talent are encouraged to come to a mutual agreement if
          refunds or cancellations are necessary. If there are no funds in
          escrow, Talent may issue a refund via the Filly Jobs platform up to
          the full amount paid on the Hourly Contract.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">10.</span> SERVICE FEES FOR ESCROW FUNDS
          RELEASED FOLLOWING DISPUTE ASSISTANCE
        </h2>
        <p className="">
          Client and Talent are encouraged to come to a All Escrow funds
          released under Section 7.2 of these Escrow Instructions are subject to
          the normal Service Fees associated with Escrow Accounts, as detailed
          in the User Agreement and these Escrow Instructions.mutual agreement
          if refunds or cancellations are necessary. If there are no funds in
          escrow, Talent may issue a refund via the Filly Jobs platform up to
          the full amount paid on the Hourly Contract.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">11.</span> NOTICES
        </h2>
        <p className="">
          All notices to a User required by these Escrow Instructions will be
          made via email sent by Filly Jobs to the User’s registered email
          address. Users are solely responsible for maintaining a current,
          active email address registered with Filly Jobs, for checking their
          registered email address and for responding to notices sent by Filly
          Jobs to the User’s registered email address.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">12.</span> COOPERATION WITH THE DISPUTE
          ASSISTANCE PROGRAM
        </h2>
        <p className="">
          All claims, disputes or other disagreements between you and another
          User that are covered by the Dispute Assistance program must be
          resolved in accordance with the terms in the Dispute Assistance
          program. All claims filed or brought contrary to the Dispute
          Assistance program will be considered improperly filed, unless
          otherwise required by law, and Filly Jobs will have the right to take
          any other action, including suspension or termination of your User
          Account, as defined in Section 1.4 of the User Agreement, which may
          impact your ability to withdraw funds, and any other legal action as
          Filly Jobs deems appropriate in its sole discretion.{" "}
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">13.</span> ABUSE
        </h2>
        <p className="">
          Filly Jobs, in its sole discretion, reserves the right to suspend or
          terminate your User Account, as defined in Section 1.4 of the User
          Agreement, immediately upon giving notice to you if Filly Jobs
          believes you may be abusing the Dispute Assistance program or as
          otherwise permitted by the Terms of Service, which may impact your
          ability to withdraw funds. However, any disputes for any contracts
          that existed prior to termination will be subject to the Terms of
          Service.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">14.</span> NO RESPONSIBILITY FOR SERVICES OR
          PAYMENTS
        </h2>
        <p className="">
          Filly Jobs and Affiliates merely provide a platform for Internet
          payment services. Filly Jobs and Affiliates do not have any
          responsibility or control over the Talent Services that Client
          purchases. Nothing in this Agreement deems or will be interpreted to
          deem Filly Jobs or any Affiliate as Client’s or Talent’s agent with
          respect to any Talent Services, or expand or modify any warranty,
          liability or indemnity stated in the Terms of Service. For example,
          Filly Jobs does not guarantee the performance, functionality, quality,
          or timeliness of Talent Services or that a Client can or will make
          payments.
        </p>
      </div>
    </div>
  );
};

export default HourlyBonus;
