import React from "react";

const Contact = () => {
  return (
    <section className="mx-auto">
      <div className="relative flex flex-col items-center justify-between">
        <div className="h-[358px] bg-bondi-blue text-white w-full">
          <div className="max-w-xl lg:max-w-7xl mx-auto text-center px-5 mt-24">
            <h2 className="uppercase text-2xl sm:text-3xl lg:text-4xl my-2 font-semibold">
              get in touch with us
            </h2>
            <h3 className="mt-3 sm:text-lg lg:text-2xl">
              Fill the form below and one of our representatives will contact
              you shortly.
            </h3>
          </div>
        </div>
        <div className="rounded-t-[50px] -mt-20 bg-white overflow-hidden w-full max-w-xl lg:max-w-7xl">
          <div className="flex flex-col lg:flex-row mx-auto">
            <div className="form-wrapper w-full lg:w-2/3 bg-white order-2 lg:order-1">
              <form className="w-full px-6 sm:px-12 py-16 text-dark-gray flex flex-col xl:px-14">
                <div className="relative">
                  <input
                    id="name"
                    name="Name"
                    type="text"
                    className="h-10 pl-3 w-full border border-dim-gray placeholder-dim-gray rounded-md focus:outline-none"
                    placeholder="Name"
                  />
                  <label htmlFor="email" className="hidden">
                    Name
                  </label>
                </div>
                <div className="relative mt-10">
                  <input
                    id="email"
                    name="email"
                    type="text"
                    className="h-10 pl-3 w-full border border-dim-gray placeholder-dim-gray rounded-md focus:outline-none"
                    placeholder="Email"
                  />
                  <label htmlFor="email" className="hidden">
                    Email
                  </label>
                </div>
                <div className="relative mt-10 mb-12">
                  <textarea
                    id="message"
                    name="message"
                    type="text"
                    className="h-44 pl-3 w-full border border-dim-gray placeholder-dim-gray rounded-md focus:outline-none pt-2"
                    placeholder="Your message here"
                  ></textarea>
                  <label htmlFor="message" className="hidden">
                    Message
                  </label>
                </div>
                <button className="ml-auto text-white bg-bondi-blue rounded-lg px-8 py-3 w-full sm:max-w-[16rem] text-base">
                  Submit
                </button>
              </form>
            </div>
            <div className="flex-1 lg:mr-6 text-base order-1 lg:order-2 pt-5 lg:pt-0 px-6 lg:px-0 mb-8 lg:mb-0">
              <ul className="contact-details mt-8 lg:mt-16 text-sm lg:text-base">
                <li className="w-full flex items-center mb-4">
                  <ion-icon name="location" class="text-xl"></ion-icon>
                  <p className=" ml-2 sm:ml-4">
                    388 Market Street, Suite 1300, San Francisco CA 94111ct
                  </p>
                </li>
                <li className="w-full flex items-center mb-4">
                  <ion-icon name="call"></ion-icon>
                  <p className="ml-2 sm:ml-4">+1-650-449-9546</p>
                </li>
                <li className="w-full flex items-center mb-4">
                  <ion-icon name="mail"></ion-icon>
                  <p className="ml-2 sm:ml-4">contact@filly jobs.com</p>
                </li>
                <li className="w-full flex items-center mb-4">
                  <ion-icon name="globe"></ion-icon>
                  <p className="ml-2 sm:ml-4">fillyjobs.com</p>
                </li>
              </ul>
              <div className="social flex justify-between items-center my-5 w-48">
                <a href="#" aria-label="instagram">
                  <i className="text-3xl hover:text-bondi-blue fab fa-instagram"></i>
                </a>
                <a href="#" aria-label="linkedin">
                  <i className="text-3xl hover:text-bondi-blue fab fa-linkedin-in"></i>
                </a>
                <a href="#" aria-label="twitter">
                  <i className="text-3xl hover:text-bondi-blue fab fa-twitter"></i>
                </a>
                <a href="#" aria-label="facebook">
                  <i className="text-3xl hover:text-bondi-blue fab fa-facebook-f"></i>
                </a>
              </div>
              <div className="">
                <p className="text-sm">
                  Send your enquiries to{" "}
                  <a href="mailto:info@fillyjobs.com" className="text-bondi-blue">
                    info@fillyjobs.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
