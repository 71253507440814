import React, { useState } from "react";

const Credit = () => {
  return (
    <React.Fragment>
      <div className="md:flow-root md:rounded-lg md:m-auto md:w-3/4 md:border-solid md:border-t md:border-r md:border-b md:border-l border-0 md:mt-8  md:p-3">
        <div className="px-2 pt-4 font-bold text-2xl">
          <p>Buy Credits</p>
        </div>
        <div className="font-bold text-lg px-2 pt-12">
          <p>Your available credits</p>
          <p className="text-gray-500">11</p>
        </div>
        <div className="font-bold text-lg px-2 pt-12">
          <p>Select the amount to buy</p>
          <select className="text-gray-500 w-40 rounded-md p-2 border-solid border-2 outline-0 border-gray-400">
            <option value="10 for $2">10 for $2</option>
            <option value="20 for $4">20 for $4</option>
          </select>
        </div>
        <div className="font-bold text-lg px-2 pt-12">
          <p>Your account will be charged</p>
          <p className="text-gray-500">$2</p>
        </div>
        <div className="font-bold text-lg px-2 pt-12">
          <p>Your new Credits balance will be</p>
          <p className="text-gray-500">21</p>
        </div>
        <div className="text-lg px-2 pt-12">
          <p className="font-bold">These Credits will expire on</p>
          <p className="font-light text-gray-300">October 15, 2032</p>
        </div>
        <div className="px-4 pt-4 pb-2 flex float-right">
          <button className="mx-6 text-gray-400 rounded-md px-4 py-1.5 border-solid border-2 border-gray-300 font-bold">
            Cancel
          </button>
          <button className="text-white bg-blue-700 border-0 rounded-md px-4 py-1.5 border-solid border-gray-400 font-bold">
            Buy Credit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Credit;
