import React from "react";
import transparencyImg from "../../assets/careers/our-core-values/Loyalty_customer.svg";
import integrityImg from "../../assets/careers/our-core-values/bullseye.svg";
import honestyImg from "../../assets/careers/our-core-values/phone.svg";
import reliabilityImg from "../../assets/careers/our-core-values/workspace-trusted.svg";
import effiencyImg from "../../assets/careers/our-core-values/work_efficiency_gear_human_personal_profile_user.svg";

const OurCoreValues = () => {
  return (
    <section className="px-5 mx-auto lg:w-full max-w-xl lg:max-w-7xl mt-6">
      <h2 className="font-bold text-[2.5rem] mb-14 text-center">
        Our Core Values
      </h2>
      <div className="flex flex-col sm:flex-row items-center justify-center mb-16 mt-8 lg:mt-12">
        <div className="flex flex-col items-center mx-3 lg:mx-8 xl:mx-12 mb-6 sm:mb-0">
          <figure className="border border-bondi-blue w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center rounded-full">
            <img
              src={transparencyImg}
              alt="icon of loyal customer"
              className="w-6 h-6 lg:w-8 lg:h-8 text-xs"
            />
          </figure>
          <h3 className="mt-5 font-medium text-bondi-blue text-lg">
            Transparency
          </h3>
        </div>
        <div className="flex flex-col items-center mx-3 lg:mx-8 xl:mx-12 mb-6 sm:mb-0">
          <figure className="border border-bondi-blue w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center rounded-full">
            <img
              src={integrityImg}
              alt="icon of bull's eye"
              className="w-6 h-6 lg:w-8 lg:h-8 text-xs"
            />
          </figure>
          <h3 className="mt-5 font-medium text-bondi-blue text-lg">
            Integrity
          </h3>
        </div>
        <div className="flex flex-col items-center mx-3 lg:mx-8 xl:mx-12 mb-6 sm:mb-0">
          <figure className="border border-bondi-blue w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center rounded-full">
            <img
              src={honestyImg}
              alt="icon of phone"
              className="w-6 h-6 lg:w-8 lg:h-8 text-xs"
            />
          </figure>
          <h3 className="mt-5 font-medium text-bondi-blue text-lg">
            Honesty
          </h3>
        </div>
        <div className="flex flex-col items-center mx-3 lg:mx-8 xl:mx-12 mb-6 sm:mb-0">
          <figure className="border border-bondi-blue w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center rounded-full">
            <img
              src={reliabilityImg}
              alt="reliability image"
              className="w-6 h-6 lg:w-8 lg:h-8 text-xs"
            />
          </figure>
          <h3 className="mt-5 font-medium text-bondi-blue text-lg">
            Reliabilty
          </h3>
        </div>
        <div className="flex flex-col items-center mx-3 lg:mx-8 xl:mx-12 mb-6 sm:mb-0">
          <figure className="border border-bondi-blue w-16 h-16 lg:w-24 lg:h-24 flex items-center justify-center rounded-full">
            <img
              src={effiencyImg}
              alt="icon of gear and person"
              className="w-6 h-6 lg:w-8 lg:h-8 text-xs"
            />
          </figure>
          <h3 className="mt-5 font-medium text-bondi-blue text-lg">
            Effiency
          </h3>
        </div>
      </div>
    </section>
  );
};

export default OurCoreValues;
