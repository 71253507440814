import React from "react";
import aboutUsImg from "../../assets/about/about-us/about-us.jpg";
const AboutUs = () => {
  return (
    <div className="mb-24 px-5 mx-auto lg:w-full max-w-xl lg:max-w-7xl md:mb-60 xl:mb-24">
      <h2 className="font-bold text-[2.5rem] mb-9 text-center">About Us </h2>
      <div className="w-full relative flex flex-wrap justify-center lg:justify-between mx-auto">
        <img src={aboutUsImg} alt="" className="xl:w-[718px] xl:h-[450px]" />

        <div className="about-us-details md:shadow-md py-10 md:px-10 lg:px-12 md:absolute max-w-[700px] h-fit bg-white md:top-1/2 xl:inset-y-0 m-auto md:-right-10 lg:right-0 md:left-16 lg:left-auto">
          <h3 className="text-bondi-blue font-semibold text-lg mb-4">
            Who we Are
            <hr className="w-16" />
          </h3>
          <p className="mb-10">
            Filly is the future of remote work. Here you can find your next
            opportunity, receive feedback on your skills and get a remote job
            for free. We built Filly to help you find talent, get in touch with
            opportunities and land your dream job.
            <br />
            Our platform has developed a unique program for professionals and
            entrepreneurs that allows you to hire your ideal candidates, no
            matter where they are located.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
