import React from "react";
import { Link } from "react-router-dom";

const FaqsClient = () => {
  return (
    <section className="mx-auto">
      <div className="relative flex flex-col items-center justify-between text-dark-gray">
        <div className="h-[358px] bg-bondi-blue text-white w-full">
          <div className="max-w-xl lg:max-w-7xl mx-auto text-center px-5 mt-24">
            <h1 className="uppercase text-2xl sm:text-3xl lg:text-4xl my-2 font-semibold">
              frequently asked questions
            </h1>
            <h2 className="mt-3 text-sm sm:text-base lg:text-xl font-poppins">
              Please find answers to your frequently asked questions below. If
              you still have any questions, please contact us at{" "}
              <a href="mailto:contact@fillyjobs.com">contact@fillyjobs.com</a>{" "}
            </h2>
          </div>
        </div>
        <div className="relative rounded-t-[50px] -mt-20 bg-white overflow-hien w-full max-w-xl lg:max-w-7xl min-h-[30vh] pt-10 px-8 mb-20 pb-1">
          <div className="preference flex justify-center absolute inset-x-4 sm:inset-x-0 -top-5 sm:-top-6">
            <Link
              to="/faqs-client"
              className="font-semibold shadow-md text-sm lg:text-base lg:w-full flex justify-center text-center max-w-md text-white hover:text-bondi-blue bg-dark-cerulean-blue hover:bg-white px-10 lg:px-16 mb-5 py-3 lg:mb-0  lg:flex lg:items-center cursor-pointer transition-all"
            >
              I want to hire
            </Link>
            <Link
              to="/faqs-freelancer"
              className="font-semibold shadow-md text-sm lg:text-base lg:w-full flex justify-center text-center max-w-md hover:text-white bg-white hover:bg-dark-cerulean-blue px-10 lg:px-16 mb-5 py-3 lg:mb-0 lg:flex lg:items-center cursor-pointer transition-all"
            >
              I want to work
            </Link>
          </div>
          <div className="mb-8 mt-16">
            <p className="">
              Please find answers to your frequently asked questions below. If
              you still have any questions, please contact us at{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>{" "}
            </p>
          </div>
          <div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                How do I get Talents for my work?
              </h2>
              <p className="">
                Explain the work you want to be done and include the appropriate
                tags so it would be found easily by talents.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                How long does it takes to get Talent(s) for my work?
              </h2>
              <p className="">
                Once you've set up your client (I want to hire) account you have
                access to post jobs for hire to talents, who can then apply for
                the job and share their own pricing with you. Once you're happy
                with their proposal, confirm the job and let them get started on
                it.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                Can I seek out top-rated Talents for my work?
              </h2>
              <p className="">
                Use our search features in your job listing to get top talents.
                Reviews and ratings will help you get an honest assessment of
                the pros and cons of each candidate so that you have all the
                data needed to make a favorable decision.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                What happens if I’m not satisfied with a Filly Talent?
              </h2>
              <p className="">
                You can terminate a contract with Talent at any time. It is
                important to communicate this intention clearly to your Talent
                and make sure they accept the job or deliver the work before
                canceling their contract. Canceling a job early can lead to
                financial penalties, but it gives you time to find another
                Talent who can complete your project.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                What happens if a Filly Job expert is unavailable for the days
                or times they are scheduled to work with me?
              </h2>
              <p className="">
                If a certain Talent is unavailable, you can search for a
                replacement.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                How do I pay Talent(s)
              </h2>
              <p className="">
                There are a variety of online payment options, including hourly,
                daily, and milestone payments, you can customize the invoice for
                your specific business needs.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                What is the minimum I can pay Talent(s)
              </h2>
              <p className="">
                Filly Jobs does not support contract rates under $3.00 USD per
                hour for hourly contracts. For fixedprice contracts, the minimum
                payment for work is $5.00 USD. This means a Talent must be paid
                at least $3.00 an hour for hourly contracts. Fixed-price
                contracts must pay a minimum of $5.00 for a project.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                Are there any upfront recruiting or contractual costs?
              </h2>
              <p className="">
                Clients pay Filly Jobs a Marketplace Fee for accessing the
                Services, and for administration and facilitation of payments
                related to the Talents Fees they pay to Talents they engage
                through the Site, as described in the{" "}
                <a href="#" className="border-b border-bondi-blue text-bondi-blue">
                  Fee and ACH Authorization Agreement
                </a>
                .
              </p>
              <p className="mt-3">
                Clients may also choose to pay for access to additional features
                and Services, as described in the{" "}
                <a href="#" className="border-b border-bondi-blue text-bondi-blue">
                  Fee and ACH Authorization Agreement
                </a>
                .
              </p>
            </div>
            <div className="mb-8">
              <h2 className="font-medium text-bondi-blue text-xl my-1 mb-3">
                Where do Filly Job experts work?
              </h2>
              <p className="">
                Our head office is located in San Francisco, California, but we
                work remotely all over the world. We are able to get excellent
                results for our clients through our remote strategy, which
                allows us to be flexible and responsive with schedules that work
                for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqsClient;
