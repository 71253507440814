import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false, // true || false
  auth: null,
  showLogin: false,
  showSignup: false,
  profile: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.auth = action.payload;
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.auth = null;
      state.isLoggedIn = false;
    },
    showLoginScreen: (state) => {
      state.showLogin = true && !state.isLoggedIn;
    },
    showSignupScreen: (state) => {
      state.showSignup = true && !state.isLoggedIn;
    },
    hideLoginScreen: (state) => {
      state.showLogin = false;
    },
    hideSignupScreen: (state) => {
      state.showSignup = false;
    },
    saveProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
});

export const {
  login,
  logout,
  showLoginScreen,
  showSignupScreen,
  hideLoginScreen,
  hideSignupScreen,
  saveProfile,
} = userSlice.actions;

export default userSlice.reducer;
