import React from "react";

const TermsAndConditions = () => {
  return (
    <section className="mx-auto">
      <div className="relative flex flex-col items-center justify-between font-roboto text-dark-gray">
        <div className="h-[358px] bg-bondi-blue text-white w-full">
          <div className="max-w-xl lg:max-w-7xl mx-auto text-center px-5 mt-24">
            <h1 className="font-poppins uppercase text-2xl sm:text-3xl lg:text-4xl my-2 font-semibold text-black">
              terms and conditions
            </h1>
          </div>
        </div>
        <div className="rounded-t-[50px] -mt-20 bg-white overflow-hidden w-full max-w-xl lg:max-w-7xl min-h-[30vh] pt-10 px-8 mb-20 pb-1">
          <div className="mb-8">
            <h2 className="font-poppins font-semibold text-black text-2xl my-1 mb-3">
              Filly Jobs terms and conditions
            </h2>
            <p className="text-sm lg:text-base">Valid since 25 August 2022.</p>
          </div>
          <div className="mb-8">
            <h2 className="font-poppins font-semibold text-black text-2xl my-1 mb-3">
              Copyright
            </h2>
            <p className="text-sm lg:text-base">
              The website HTML, text, images audio, video, software or other
              content that is made available on this website or otherwise hosted
              by Filly Jobs are the property of someone - the author in the case
              of content produced elsewhere and reproduced here with permission,
              or Filly Jobs or its content suppliers. Before you use this
              content in some way, please take care to ensure that you have the
              relevant rights and permissions from the copyright holder.
            </p>
            <p className="text-sm lg:text-base mt-4">
              You are welcome to display on your computer, download and print
              pages from this website for personal, educational and
              non-commercial use only. You must retain copyright, trademark and
              other notices unaltered on any copies or printouts you make.
              Certain materials available on this site are “open source”
              materials subject to the GNU General Public Licence (“GPL”) or
              other open-source licence and are so marked. Use of those
              materials is governed by the individual applicable licence.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-poppins font-semibold text-black text-2xl my-1 mb-3">
              Trademarks
            </h2>
            <p className="text-sm lg:text-base">
              Any trademarks, logos and service marks displayed on this website
              are the property of their owners, whether Filly Jobs or third
              parties. For example, Linux is a registered trademark of Linus
              Torvalds. Debian is a trademark of Software in the Public
              Interest, Inc. Windows is a trademark of Microsoft Corporation..
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-poppins font-semibold text-black text-2xl my-1 mb-3">
              Privacy Policy
            </h2>
            <p className="text-sm lg:text-base">
              Our collection and use of your personal information are governed
              by the{" "}
              <a
                href="/"
                className="text-bondi-blue border-b border-b-bondi-blue pb-1"
              >
                Filly Jobs website
              </a>{" "}
              privacy policy.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-poppins font-semibold text-black text-2xl my-1 mb-3">
              Links and third party content
            </h2>
            <p className="text-sm lg:text-base">
              This and any other websites hosted by Filly Jobs may contain links
              to other websites and resources and third-party content, for
              example, message board posts or blog comments. Filly Jobs is not
              responsible for such content, or that of any linked websites. You
              will not remove or alter any third party’s copyright or trademark
              notices or other identifiers, except as allowed by the third
              party’s licence of that content. Should you reasonably believe
              that any third-party content you access through our websites is in
              breach of any law, regulation or third party’s rights, you should
              notify Filly Jobs in writing at the address below. In doing so,
              please:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                Identify the material which you believe to be infringing;
              </li>
              <li className="mb-3">
                Identify what you believe this material infringes and why;
              </li>
              <li className="mb-3">
                Provide your name, email address, address and telephone number;
              </li>
              <li className="mb-3">
                Confirm that you believe in good faith that this material is
                infringing a law or third party’s rights and that, to the best
                of your knowledge, the information you are providing is correct;
              </li>
              <li className="mb-3">
                Identify if you are acting on behalf of the third party whose
                rights may have been infringed;
              </li>
              <li className="mb-3">
                Provide your physical or electronic signature.
              </li>
            </ul>
          </div>
        <div className="mb-8">
          <h2 className="font-poppins font-semibold text-black text-2xl my-1 mb-3">
            Disclaimer
          </h2>
          <p className="text-sm lg:text-base">
            This and any other websites hosted by Filly Jobs and all
            information, products and services on them are provided on an “as
            is” basis, without warranty of any kind, either express or implied.
            Your use of this and any other websites hosted by Filly Jobs is at
            your own risk. Filly Jobs disclaims all warranties, express or
            implied, including without limitation, warranties of merchantability
            and fitness for a particular purpose. Filly Jobs disclaims liability
            for any direct, indirect, incidental, special, consequential,
            exemplary, punitive or other damages, or lost profits, that may
            result directly or indirectly from the use of this and any other
            websites hosted by Filly Jobs and any material that is downloaded or
            obtained through them. This includes, without limitation, any damage
            to computer systems, hardware or software, loss of data, or any
            other performance failures, any errors, bugs, viruses or other
            defects that result from or are associated with the use of this and
            any other websites hosted by Filly Jobs.
            <br />
            Yes, it gives most of us a headache to read all of this, but it’s
            important so thank you for your patience and now, enjoy the site!
          </p>
        </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
