import React from "react";
import { Link } from "react-router-dom";
import iphones from "../../assets/how-it-works/work-steps/iphones.svg";

const HireSteps = () => {
  return (
    <>
      <section className="preference flex justify-center absolute inset-x-0">
        <Link
          to="/howitworks-hire"
          className="font-semibold shadow-md text-sm lg:text-base lg:w-full flex justify-center text-center max-w-md text-white hover:text-bondi-blue bg-dark-cerulean-blue hover:bg-white px-10 lg:px-16 mb-5 py-3 lg:mb-0  lg:flex lg:items-center cursor-pointer transition-all"
        >
          I want to hire
        </Link>
        <Link
          to="/howitworks-findwork"
          className="font-semibold shadow-md text-sm lg:text-base lg:w-full flex justify-center text-center max-w-md hover:text-white bg-white hover:bg-dark-cerulean-blue px-10 lg:px-16 mb-5 py-3 lg:mb-0 lg:flex lg:items-center cursor-pointer transition-all"
        >
          I want to work
        </Link>
      </section>
    <section className="max-w-xl lg:max-w-7xl mx-auto px-5 mb-16 mt-32 lg:mt-40">
      <h2 className="text-[2.5rem] font-semibold text-center">
        How does it work?
      </h2>
      <div className="flex mt-16 pt-2 flex-col lg:flex-row">
        <div className="flex-1">
          <img src={iphones} alt="iphone 11 max" className="lg:ml-auto" />
        </div>
        <div className="flex-1">
          <ol className="mt-8 lg:mt-0 ml-6 lg:ml-20 list-[auto]" type="1">
            <li className="mb-12">
              <h3 className="font-medium text-xl">Sign Up for filly Jobs</h3>
              <p className="text-base mt-5 text-dark-gray">
                Simply sign up for the platform by submitting your name and
                choosing a password, and submitting your email address.
              </p>
            </li>
            <li className="mb-12">
              <h3 className="font-medium text-xl">Review Bids from Talents</h3>
              <p className="text-base mt-5 text-dark-gray">
                Our platform not only allows you to review bids from numerous
                talents, but also lets you make contact with them easily.
              </p>
            </li>
            <li className="mb-12">
              <h3 className="font-medium text-xl">
                Select your preferred Talent
              </h3>
              <p className="text-base mt-5 text-dark-gray">
                We sign up employees who are right for your role, and make sure
                they have the experience, skills and personality that make them
                a good fit.
              </p>
            </li>
            <li className="mb-12">
              <h3 className="font-medium text-xl">Pay for your service</h3>
              <p className="text-base mt-5 text-dark-gray">
                Our talents will get the job done and make your life easier, so
                you can focus on doing what matters most
              </p>
            </li>
          </ol>
        </div>
      </div>
    </section></>
  );
};

export default HireSteps;
