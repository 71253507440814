import {Link, useRouteError, useNavigate} from 'react-router-dom'

function _404(props) {
  
  const error = useRouteError();
  const navigate = useNavigate()
  return (
    <div className="h-screen w-full bg-gray-900 text-white flex justify-center content-center">
      <div className="m-auto">
        <h1 className="text-6xl font-bold">404 Page:  {error.statusText || error.message}</h1>
        <span></span>
        <hr className="my-4" />
        <h1 className="my-4 text-3xl text-center">
          <button onClick={() => navigate(-1)}
          >
            &larr; &nbsp; Click here to go back
          </button>
        </h1>
      </div>
    </div>
  );
}

export default _404;
