import React from "react";
import team1 from "../../assets/about/team/team-1.png";
import team2 from "../../assets/about/team/team-2.png";
import team3 from "../../assets/about/team/team-3.png";
const Team = () => {
  return (
    <div className="my-24 px-5 mx-auto lg:w-full max-w-xl lg:max-w-7xl">
      <h2 className="font-bold text-[2.5rem] mb-9 text-center">
        Meet Our Team
      </h2>
      <div className="w-full flex flex-wrap justify-center lg:justify-between mx-auto">
        <div className="relative overflow-hidden rounded-sm w-full sm:w-72 lg:w-[280px] xl:w-[350px] shadow-md mx-4 my-6">
          <div className="img-wrapper relative w-full sm:w-72 lg:w-[280px] xl:w-[350px] sm:h-72 lg:h-[280px] xl:h-[352px]">
            <img
              src={team1}
              alt="head shot of a person"
              className="w-full h-full object-fit"
            />
          </div>
          <div className="info px-6 pb-6 flex flex-col justify-end my-4">
            <h3 className="text-bondi-blue mb-2 font-semibold text-lg">
              Bernard Baah
            </h3>
            <h4 className="text-base mb-2">Chief Exective Officer</h4>
            <p className="text-xs">
              MSc Computer Engineering (Standford University), MBA Statistics
              (University of Khent), B.Sc (University of Ghana) Computer Science
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden rounded-sm w-full sm:w-72 lg:w-[280px] xl:w-[350px] shadow-md mx-4 my-6">
          <div className="img-wrapper relative w-full sm:w-72 lg:w-[280px] xl:w-[350px] sm:h-72 lg:h-[280px] xl:h-[352px]">
            <img
              src={team2}
              alt="head shot of a person"
              className="w-full h-full object-fit"
            />
          </div>
          <div className="info px-6 pb-6 flex flex-col justify-end my-4">
            <h3 className="text-bondi-blue mb-2 font-semibold text-lg">
              Gloria Gladys
            </h3>
            <h4 className="text-base mb-2">Talent Recruitment Analyst</h4>
            <p className="text-xs">
              MSc Computer Engineering (Standford University), MBA Statistics
              (University of Khent), B.Sc (University of Ghana) Computer Science
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden rounded-sm w-full sm:w-72 lg:w-[280px] xl:w-[350px] shadow-md mx-4 my-6">
          <div className="img-wrapper relative w-full sm:w-72 lg:w-[280px] xl:w-[350px] sm:h-72 lg:h-[280px] xl:h-[352px]">
            <img
              src={team3}
              alt="head shot of a person"
              className="w-full h-full object-fit"
            />
          </div>
          <div className="info px-6 pb-6 flex flex-col justify-end my-4">
            <h3 className="text-bondi-blue mb-2 font-semibold text-lg">
            Esther Richards
            </h3>
            <h4 className="text-base mb-2">Client Technology Officer</h4>
            <p className="text-xs">
              MSc Computer Engineering (Standford University), MBA Statistics
              (University of Khent), B.Sc (University of Ghana) Computer Science
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
