import React from "react";
import { Link } from "react-router-dom";

const HourlyDirectContract = () => {
  return (
    <div className="font-thin text-dark-gray">
      <h2 className="font-semibold text-black text-xl my-1 mb-2">
        Hourly Direct Contract Escrow Instructions
      </h2>
      <p className="font-normal text-black">Valid since 25th August 2022</p>
      <div className="mt-8">
        <p className="">
          If Client and Talent enter into an Hourly Direct Contract, these
          Hourly Direct Contract Escrow Instructions apply. These Hourly Direct
          Contract Escrow Instructions govern Direct Contract Escrow Accounts
          for Hourly Direct Contracts. All other contracts, including
          Fixed-Price Direct Contracts and Service Contracts (as defined in the{" "}
          <a href="#" className="border-b border-bondi-blue text-bondi-blue">
            User Agreement
          </a>
          ), are governed by the applicable{" "}
          <Link
            to="/escrow-manual"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Escrow instructions
          </Link>
          .
        </p>
        <p className="mt-3">
          To the extent permitted by applicable law, we may modify these Hourly
          Direct Contract Escrow Instructions without prior notice to you, and
          any revisions to these Escrow Instructions will take effect when
          posted on the Site unless otherwise stated. Please check the Site
          often for updates.
        </p>
        <p className="">
          These Escrow Instructions hereby incorporate by reference the Terms of
          Service (“Terms of Service”). Capitalized terms not defined in these
          Escrow Instructions are defined in the{" "}
          <a href="#" className="border-b border-bondi-blue text-bondi-blue">
            User Agreement
          </a>
          , elsewhere in the{" "}
          <a
            href="/terms-and-conditions"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Terms of Service
          </a>{" "}
          , or have the meanings given such terms on the Site. These Hourly
          Direct Contract Escrow Instructions do not apply to Fixed-Price Direct
          Contracts or Service Contracts.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">1.</span> DIGITAL SIGNATURE
        </h2>
        <p className="">
          By clicking to send an offer for or to accept an Hourly Direct
          Contract, Client and Talent are deemed to have executed this Agreement
          electronically, effective on the date Client clicks to accept an
          Hourly Direct Contract, pursuant to California Civil Code section
          1633.8 and the federal Electronic Signatures in Global and National
          Commerce Act, 15 U.S.C. Sec. 7001, et seq., as amended from time to
          time (the “E-Sign Act”). Doing so constitutes an acknowledgement that
          Client and Talent agree to conduct the transaction electronically, and
          are able to electronically receive, download, and print these Hourly
          Direct Contract Escrow Instructions.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">2.</span> FUNDING ESCROW
        </h2>
        <p className="">
          By accepting an Hourly Direct Contract, Client agrees to deposit funds
          totaling the Talent’s hourly rate as agreed to by the parties
          multiplied by the weekly hourly limit agreed to by the parties (
          <span className="font-semibold">“Direct Contract Deposit”</span>).
          Client authorizes Filly Jobs Escrow to hold the Direct Contract
          Deposit in escrow through the term of the Hourly Direct Contract and
          further authorizes Filly Jobs Escrow to release funds from the Direct
          Contract Deposit to the Talent in any instance(s) where the Talent
          invoices hours of work and the invoice is neither disputed or funded
          for payment by Client in accordance with these Hourly Escrow
          Instructions. In any such instance(s), Client agrees that Filly Jobs
          Escrow may charge to Client’s Payment Method or Client will otherwise
          deposit additional funds into the Client Escrow Account to replenish
          the Direct Contract Deposit. Talent and Client agree and authorize
          Filly Jobs Escrow to hold an amount equal to the Direct Contract
          Deposit in escrow through the termination of the Direct Contract, at
          which point the parties agree the Direct Contract Deposit will be
          released to Client if all payments due under the Hourly Direct
          Contract have been paid to Talent.
        </p>
        <p className="mt-3">
          In addition to the Direct Contract Deposit, Client agrees to fund
          through Client Escrow Account payments to Talent on a weekly basis for
          the amount of Talent’s applicable weekly invoices (
          <span className="font-semibold">“Direct Contract Weekly Funds”</span>
          ). If Client approves the submitted invoices in accordance with the
          below provisions, Filly Jobs Escrow will release the Direct Contract
          Weekly Funds to Talent.
        </p>

        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg mb-2">
            <span className="mr-1">2.1</span> WEEKLY HOURLY INVOICES
          </h3>
          <p className="">
            For Hourly Direct Contracts, the weekly billing cycle starts Monday
            at 00:00 midnight UTC and ends Sunday at 23:59 UTC. Invoices for
            hours recorded by the Talent on Filly Jobs within the weekly billing
            cycle (the "Hourly Invoice Deadline") will be generated each Monday
            following the week in which the hours were billed (such invoice, the
            "Hourly Invoice"). Talent irrevocably authorizes and instructs Filly
            Jobs, as its agent, to (i) create an invoice on behalf of Talent for
            payment due based upon the hours that the Talent recorded before the
            Hourly Invoice Deadline; and (ii) submit the Hourly Invoice on
            behalf of Talent to Talent's Client for payment.
          </p>
          <p className="">
            By recording time on Filly Jobs and allowing an Hourly Invoice to be
            created based on the time recorded, Talent represents and warrants
            that (y) Talent has completed the applicable Talent Services fully
            and satisfactorily; and (z) the hours Talent reports are true,
            accurate, and complete.
          </p>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg mb-2">
            <span className="mr-1">2.1</span> WEEKLY HOURLY INVOICES
          </h3>
          <p className="">
            Client must review and approve or dispute the Hourly Invoice by
            11:59 PM UTC of the Thursday following the Sunday deadline for
            submission of the Hourly Invoice. Payments will be held in escrow
            during the Dispute Period (defined below), providing four days for
            Client to review and dispute the Hourly Invoice before funds are
            released. During the Dispute Period, Client may initiate a Dispute
            as to some or all of the time invoiced on the Hourly Invoice.
          </p>
          <p className="mt-3">
            On the Friday following the Sunday deadline for submission of the
            Hourly Invoice, Client will be deemed to have approved all
            undisputed amounts on the Hourly Invoice, and irrevocably instructed
            Filly Jobs’s Affiliate, Filly Jobs Escrow, to release escrow funds
            as described in these Hourly Direct Contract Escrow Instructions.
          </p>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">3.</span> INSTRUCTIONS TO RELEASE FUNDS
          IRREVOCABLE
        </h2>
        <p className="">
          Client’s instruction to Filly Jobs Escrow and its wholly owned
          subsidiaries to release funds to a Talent is irrevocable. Such
          instruction is Client’s authorization to transfer funds to Talent from
          the Client Escrow Account or authorization to charge Client’s Payment
          Method. Such instruction is also Client’s representation that Client
          has received, inspected, and accepted the subject work or expense.
          Client acknowledges and agrees that upon receipt of Client’s
          instruction to pay Talent, Filly Jobs Escrow will release funds to the
          Talent and that Filly Jobs, Filly Jobs Escrow, and other Affiliates
          have no responsibility to and may not be able to recover such funds.
          Therefore, and in consideration of services described herein, Client
          agrees that once Filly Jobs Escrow has charged Client’s Payment
          Method, the charge is non-refundable and any funds will only be
          returned pursuant to a Release Condition (see below).
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">4.</span> RELEASE AND DELIVERY OF AMOUNTS IN
          ESCROW
        </h2>
        <p className="">
          Filly Jobs Escrow is authorized to and will release applicable
          portions of the Client Escrow Account (each portion, a{" "}
          <span className="font-semibold">“Release”</span>) to the Talent Escrow
          Account, upon the occurrence of and in accordance with one or more
          Release Conditions provided below or as otherwise required by
          applicable law or the Terms of Service. The amount of the Release will
          be delivered to the Talent Escrow Account, in accordance with Talent’s
          and Client’s instructions (as applicable), these Hourly Direct
          Contract Escrow Instructions, and the other Terms of Service.
        </p>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">4.1</span> RELEASE CONDITIONS
          </h3>
          <p className="mb-6">
            As used in these Escrow Instructions,{" "}
            <span className="font-semibold">“Release Condition”</span> means any
            of the following:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              Client and Talent have submitted joint written instructions for a
              Release.
            </li>
            <li className="mb-4">
              Client has approved all or a portion of the Talent's Hourly
              Invoice. This Release Condition will only apply to amounts
              invoiced by the Talent that Client has approved. Client’s failure
              to dispute an Hourly Invoice, or a portion of an Hourly Invoice,
              within the Dispute Period pursuant to these Hourly Direct Contract
              Escrow Instructions, constitutes approval by the Client for
              purposes of this Release Condition.
            </li>
            <li className="mb-4">
              Filly Jobs makes a determination with respect to the funds and
              determines that the time is related to the Hourly Direct Contract
              requirements or Client’s instruction, if Client initiates a
              Dispute with respect to Talent's Hourly Invoice pursuant to these
              Hourly Direct Contract Escrow Instructions.
            </li>
            <li className="mb-4">
              Pursuant to the agreement of Client and Talent, if Client
              initiates a Dispute with respect to Talent's Hourly Invoice
              pursuant to these Hourly Direct Contract Escrow Instructions and
              Client and Talent resolve the dispute without the assistance of
              Filly Jobs. In any such instance, Client and Talent authorize and
              agree that Filly Jobs Escrow will release funds in accordance with
              Client and Talent’s agreed resolution.
            </li>
            <li className="mb-4">
              Issuance of a final order of a court or arbitrator of competent
              jurisdiction from which appeal is not taken, in which case the
              funds will be released in accordance with such order.
            </li>
            <li className="mb-4">
              We believe, in our sole discretion, that fraud, an illegal act, or
              a violation of Filly Jobs's Terms of Service has been committed or
              is being committed or attempted, in which case Client and Talent
              hereby authorize and instruct Filly Jobs Escrow to take such
              actions as we deem appropriate in our sole discretion and in
              accordance with applicable law, in order to prevent or remedy such
              acts, including without limitation to return the funds associated
              with such acts to their source of payment.
            </li>
          </ol>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">5.</span> MAKING OR RECEIVING A BONUS OR
          EXPENSE PAYMENT
        </h2>
        <p className="">
          Client may also make a bonus, tip, expense, or other miscellaneous
          payment to Talent using the Site. To make such a payment to a Talent,
          Client must follow the instructions and links on the Site and provide
          the information requested. If Client clicks to pay such a payment to
          Talent, Client irrevocably instructs Filly Jobs Escrow to and Filly
          Jobs Escrow will release escrow funds to Talent.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">6.</span> DISPUTES BETWEEN CLIENT AND TALENT
        </h2>
        <p className="">
          For Hourly Direct Contracts, Client may dispute Talent’s hours
          invoiced on the Hourly Invoice for the prior week (Monday 12:00 a.m.
          midnight UTC to Sunday 11:59 p.m. UTC) during the four days following
          the close of the weekly invoice period (Monday 12:00 a.m. midnight UTC
          to Thursday 11:59 p.m. UTC) (the{" "}
          <span className="font-semibold">“Dispute Period“</span>). It is
          Client’s responsibility to review the Hourly Invoice of every Hourly
          Direct Contract on a weekly basis and to file any disputes during the
          Dispute Period. Once the Dispute Period expires, Client will be deemed
          to have accepted the Talent Services and Talent Fees and can no longer
          dispute them. Disputes handled by Filly Jobs can only address the
          hours billed, not the quality of the Talent Services or the Work
          Product provided under Hourly Direct Contracts.
        </p>
        <p className="mt-3">
          If Client disputes Talent’s hours invoiced in the Hourly Invoice under
          an Hourly Direct Contract during the Dispute Period, Client and Talent
          are encouraged to resolve the dispute between themselves. If Client
          and Talent fail to come to a resolution, Filly Jobs will promptly
          review the Hourly Invoice and determine, in our sole discretion,
          whether an adjustment is appropriate consistent with Client’s and
          Talent’s instructions in these Escrow Instructions. Filly Jobs's
          determination of such dispute shall be final unless Client and Talent
          pursue legal proceedings, in which case funds will be released
          according to the final order from which appeal is not taken in
          accordance with Section 5.1 of these Hourly Direct Contract Escrow
          Instructions.
        </p>
        <p className="mt-3">
          You acknowledge and agree that Filly Jobs Escrow or its Affiliates
          may, at its sole discretion, withhold or delay payment in the event of
          dispute between a Client and a Talent. You further acknowledge and
          agree that Filly Jobs, Filly Jobs Escrow, and other Affiliates are not
          and will not be a party to any dispute between a Client and Talent
          over an Hourly Invoice or Hourly Direct Contract.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">7.</span> REFUNDS AND CANCELLATIONS
        </h2>
        <p className="">
          Client and Talent are encouraged to come to a mutual agreement if
          refunds or cancellations are necessary. If there are no funds in
          escrow, Talent may issue a refund via the Filly Jobs platform up to
          the full amount paid on the Hourly Direct Contract.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">8.</span> SERVICES FEES FOR ESCROW FUNDS
        </h2>
        <p className="">
          All Escrow funds released under Section 6 of these Escrow Instructions
          are subject to the normal Service Fees associated with Escrow
          Accounts, as detailed in the User Agreement and these Escrow
          Instructions.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">9.</span> NOTICES
        </h2>
        <p className="">
          All notices to a User required by these Escrow Instructions will be
          made via email sent by Filly Jobs to the User’s registered email
          address. Users are solely responsible for maintaining a current,
          active email address registered with Filly Jobs, for checking their
          email and for responding to notices sent by Filly Jobs to the User’s
          registered email address.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">10.</span> ABUSE
        </h2>
        <p className="">
          Filly Jobs, in its sole discretion, reserves the right to suspend or
          terminate your Account immediately upon giving notice to you if Filly
          Jobs believes you may be abusing the Dispute Assistance Program or as
          otherwise permitted by the{" "}
          <a
            href="/terms-and-conditions"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Terms of Service
          </a>
          . However, any Disputes for any Fixed-Price Contracts that existed
          prior to termination will be subject to the Terms of Service.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">11.</span> NO RESPONSIBILITY FOR SERVICES OR
          PAYMENTS
        </h2>
        <p className="">
          Filly Jobs and Affiliates merely provide a platform for Internet
          payment services. Filly Jobs and Affiliates do not have any
          responsibility or control over the Talent Services that Client
          purchases. Nothing in this Agreement deems or will be interpreted to
          deem Filly Jobs or any Affiliate as Client’s or Talent’s agent with
          respect to any Talent Services, or expand or modify any warranty,
          liability or indemnity stated in the Terms of Service. For example,
          Filly Jobs does not guarantee the performance, functionality, quality,
          or timeliness of Talent Services or that a Client can or will make
          payments.
        </p>
      </div>
    </div>
  );
};

export default HourlyDirectContract;
