import React, { useEffect, useState } from "react";

const SubmitReceipt = ({ active, setActive, saveRequest, location, setLocation }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (active === 5) {
      setShow(true);
    } else {
      setShow(false);
    }

    return () => {
      setShow(false);
    };
  }, [active]);

  return (
    <div
      className={!show ? 'hidden' : `bg-opacity-70 bg-slate-900 fixed left-0 h-screen inset-0 overflow-hidden right-0 top-0 w-full z-50
      x-transition:enter=transition ease-out duration-100 x-transition:enter-start=opacity-0 scale-90
      x-transition:enter-end=opacity-100 scale-100 x-transition:leave=transition ease-in duration-100
      x-transition:leave-start=opacity-100 scale-100 x-transition:leave-end=opacity-0 scale-90`}
    >
      <div className="grid h-full items-center max-w-3xl mx-auto px-4 py-8 relative">
        <div className="bg-white overflow-y-auto max-h-full relative rounded-lg">
          <div className=" flex text-riight items-right justify-end p-4 rounded-t">
            <button
              type="button"
              onClick={() => setActive(0)}
              className="bg-transparent hover:bg-slate-200 hover:text-slate-900 inline-flex items-center p-1.5 rounded-lg text-slate-400 text-sm"
            >
              <span className="material-icons text-2xl">X</span>
            </button>
          </div>
          <div className="font-bold text-2xl justify-center text-center">
            <h2>Your request has been submitted</h2>
          </div>

          <div className="p-6 pb-8">
            <div className="grid gap-y-4">
              <div className="grid gap-4">
                <div className="text-center items-center justify-center">
                  <h2 className="pb-8 text-lg text-blue-400">
                    The pro will contact you soon
                  </h2>
                  <button
                    type="button"
                    onClick={saveRequest}
                    className="text-white bg-blue-700 hover:bg-blue-500 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-10 py-2 tracking-widest"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubmitReceipt;
