import React from "react";

const PrivacyNoticeOnlinePurchase = () => {
  return (
    <section className="mx-auto font-light">
      <div className="relative flex flex-col items-center justify-between text-dark-gray text-sm lg:text-base mb-14">
        <div className="h-[358px] bg-bondi-blue text-white w-full">
          <div className="max-w-xl lg:max-w-7xl mx-auto text-center px-5 mt-24">
            <h1 className="uppercase text-2xl sm:text-3xl lg:text-4xl my-2 font-semibold">
              privacy notice -
              <br />
              online purchase
            </h1>
          </div>
        </div>
        <div className="rounded-t-[50px] -mt-20 bg-white overflow-hidden w-full max-w-xl lg:max-w-7xl min-h-[30vh] pt-16 px-8 mb-20 pb-1">
          <div className="mb-6">
            <p className="">
              This privacy notice tells you about the information we collect
              from you when you sign up to receive our regular newsletter via
              our website. In collecting this information, we are acting as a
              data controller and, by law, we are required to provide you with
              information about us, about why and how we use your data, and
              about the rights you have over your data. For more information,
              please see our{" "}
              <a href="/privacy-policy" className="text-bondi-blue">
                privacy policy
              </a>
              .
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Who we are
            </h2>
            <p className="">
              We are Filly Jobs Limited. Our address is 388 Market Street, Suite
              1300, San Francisco CA 94111. You can contact us by post at the
              above address or by email to{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>{" "}
              for the attention of “Legal”.
            </p>
            <p className="mt-4">
              We are not required to have a data protection officer, so any
              enquiries about our use of your personal data should be addressed
              to the contact details above.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Why do we collect this information?
            </h2>
            <p className="">
              We will use your information to send you our newsletter, which
              contains information about our products. This may involve us
              calling you, where we have your phone number in order to do so
            </p>
            <p className="mt-4">
              We ask for your consent to do this, and we will only send you our
              newsletter for as long as you continue to consent. Where you have
              agreed, we will also use your information to send you other news
              and product updates from Filly Jobs. This may involve us calling
              you, where we have your phone number in order to do so.
            </p>
            <p className="mt-4">
              We ask for your consent to do this, and we will only send you or
              tell you about news and product updates for as long as you
              continue to consent.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              What do we do with your information?
            </h2>
            <p className="">
              Your information is stored in our database and is not shared with
              any third parties, except as reasonably required for Filly Jobs to
              process and store your data.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-2xl mb-3">
              Our Legal basis to use your Personal Information
            </h2>
            <h3 className="text-black font-semibold text-xl mb-3">
              Our legitimate business interests
            </h3>
            <p className="">
              Our legal basis for using your personal information is that it is
              necessary for our legitimate interest in providing the purchased
              product in accordance with the terms and conditions between us and
              the business you are associated with.
            </p>
            <p className="mt-4">
              When purchasing a product from us online, our legal basis for
              processing your personal information is that it is necessary for
              our legitimate interests in conducting our business and meeting
              the requirements of you, our current and prospective customers.
            </p>
            <p className="mt-4">
              We may contact you from time to time with information about
              products and services that we offer. Our legal basis for using
              your personal information to contact you is that it is necessary
              for our legitimate interest in keeping you informed of the
              products and services that we offer. You can opt out of marketing
              communications as set out below.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Your consent
            </h2>
            <p className="">
              If you purchase a product from us online, you will have the option
              to provide your consent to us sending you information about the
              products and services that we offer. You can withdraw your consent
              at any time.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              How long do we keep your information for?
            </h2>
            <p className="">
              Your information is kept for as long as you continue to consent to
              receive our newsletter and for so long as reasonably required
              thereafter in accordance with our record retention policy.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Your rights over your information
            </h2>
            <p className="">
              You have a number of important rights under data protection laws.
              In summary, those include rights to:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                Fair processing of information and transparency over how we use
                your personal information;
              </li>
              <li className="mb-3">
                Access to your personal information and to certain other
                supplementary information that this Privacy Notice is already
                designed to address;
              </li>
              <li className="mb-3">
                Require us to correct any mistakes in your information which we
                hold;
              </li>
              <li className="mb-3">
                Require the erasure of personal information concerning you in
                certain situations;
              </li>
              <li className="mb-3">
                Receive the personal information concerning you which you have
                provided to us, in a structured, commonly used and
                machine-readable format and have the right to transmit that data
                to a third party in certain situations;
              </li>
              <li className="mb-3">
                Object at any time to processing of personal information
                concerning you for direct marketing;
              </li>
              <li className="mb-3">
                Object to decisions being taken by automated means which produce
                legal effects concerning you or similarly significantly affect
                you;
              </li>
              <li className="mb-3">
                Object in certain other situations to our continued processing
                of your personal information;
              </li>
              <li className="mb-3">
                Otherwise restrict our processing of your personal information
                in certain circumstances;
              </li>
              <li className="mb-3">
                Claim compensation for damages caused by out breach of any data
                protection laws.
              </li>
            </ul>
            <p className="mt-6">
              If you would like to exercise any of those rights, please:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                Email, call or write to us, please see “Your right to complain”
                section below;
              </li>
              <li className="mb-3">
                Let us have enough information to identify you;
              </li>
              <li className="mb-3">
                Let us have proof of your identity and address (a copy of your
                driving licence or passport and a recent utility or credit card
                bill); and
              </li>
              <li className="mb-3">
                Let us know the information to which your request relates.
              </li>
            </ul>
            <p className="mt-3">
              If you would like to unsubscribe from any email please see below.
            </p>
            <p className="mt-4">
              By law, you can ask us what information we hold about you, and you
              can ask us to correct it if it is inaccurate. You can also ask us
              to give you a copy of the information and to stop using your
              information for a period of time if you believe we are not doing
              so lawfully.
            </p>
            <p className="mt-3">
              To submit a request by email, post or telephone, please use the
              contact information provided above.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Email marketing communications
            </h2>
            <p className="">
              Filly Jobs offers a free email newsletter subscription service to
              keep you informed of our products and services. You may receive
              these marketing communications from us. We incorporate a tracking
              system in our email marketing communications which allows us to
              monitor whether the email has been opened and which links are the
              most popular. This allows us to tailor and refine our service to
              ensure that the emails you receive are relevant to your interests.
            </p>
            <p className="mt-3">
              Where you have consented to receiving email marketing material
              from us or are currently receiving material relating to similar
              products or services, you may at any time ask us to cease sending
              you such material by (i) clicking the unsubscribe button at the
              end of the email, (ii) reply to the email with “STOP”, (iii)
              sending an email to contact@fillyjobs.com, or (iv) navigating to
              your subscription centre page (you will need to login if you have
              not already done so) and click the ‘unsubscribe’ link at the foot
              of the page as appropriate.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Links to other websites
            </h2>
            <p className="">
              The purchased products may contain links to other third party
              websites with whom we have a relationship. However, we are not
              responsible for these websites and their privacy practices. We
              therefore suggest that you read the privacy policy statements on
              any website that you visit from a link on this site, as their
              policies and procedures may differ from our own.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Keeping your personal information secure
            </h2>
            <p className="">
              We have appropriate security measures in place to prevent personal
              information from being accidentally lost, or used or accessed in
              an unauthorised way. We limit access to your personal information
              to those who have a genuine business need to know it. Those
              processing your information will do so only in an authorised
              manner and are subject to a duty of confidentiality.
            </p>
            <p className="">
              We also have procedures in place to deal with any suspected data
              security breach. We will notify you and any applicable regulator
              of a suspected data breach where we are legally required to do so.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Changes to this Privacy Notice
            </h2>
            <p className="">
              By law, you can ask us what information we hold about you, and you
              can ask us to correct it if it is inaccurate. You can also ask for
              it to be erased and you can ask for us to give you a copy of the
              information.
            </p>
            <p className="mt-3">
              You can also ask us to stop using your information for
              newsletters, news or product updates – the simplest way to do this
              is to withdraw your consent, which you can do at any time, either
              by clicking the unsubscribe link at the end of any newsletter or
              email, or by emailing, writing or telephoning us using the contact
              details above
            </p>
          </div>
          <div className="mt-8">
            <h2 className="text-black font-semibold text-xl mb-3">
              Your rights to complain
            </h2>
            <p className="">
              If you have a complaint about our use of your information, you can
              contact the Information Commissioner’s Office via their website at{" "}
              <a
                href="https://portal.ct.gov/FOI/CommonElements/Top-Menu/Contact-Us"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                https://portal.ct.gov/FOI/CommonElements/Top-Menu/Contact-Us
              </a>{" "}
              or write to them at:
            </p>
            <address className="not-italic mt-6">
              <p className="mt-3">
                Connecticut Freedom of Information Commission
              </p>
              <p className="mt-3">165 Capitol Ave., Suite 1100</p>
              <p className="mt-3">Hartford, CT 06106y.</p>
            </address>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyNoticeOnlinePurchase;
