import React from "react";
import { Link } from "react-router-dom";

const FixedPriceService = () => {
  return (
    <div className="font-thin text-dark-gray">
      <h2 className="font-semibold text-black text-xl my-1 mb-2">
        Fixed Price Service Contract Escrow Instructions
      </h2>
      <p className="font-normal text-black">Valid since 25th August 2022</p>
      <p className="font-medium mt-8">
        *Filly Jobs is committed to helping users experiencing hardships as a
        result of COVID-19. To that end, Filly Jobs may extend or accommodate
        requests for extension of deadlines imposed by Section 6 of these
        Fixed-Price Escrow Instructions and related to disputes subject to the
        Dispute Assistance Program.*
      </p>
      <div className="mt-8">
        <p className="">
          If a Client and a Talent (defined below) enter into a Fixed-Price
          Contract on the Filly Jobs site, these Fixed Price Service Contract
          Escrow Instructions (
          <span className="font-semibold">“Escrow Instructions”</span>) apply.
          These{" "}
          <Link
            to="/escrow-manual"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Escrow instructions
          </Link>{" "}
          govern Fixed-Price Escrow Accounts. If you have a Fixed-Price Contract
          and wish to make or receive a miscellaneous or bonus payment, those
          activities are governed by the{" "}
          <a
            href="/escrow-manual/hourly-bonus"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Hourly, Bonus, and Expense Payment Agreement
          </a>{" "}
          with Escrow Instructions. Fixed Price Direct Contracts are not
          governed by these Escrow Instructions and are instead governed by
          these{" "}
          <Link
            to="/escrow-manual/fixed-price-direct"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Fixed Price Direct Contract Escrow Instructions
          </Link>
          .
        </p>
        <p className="mt-4">
          To the extent permitted by applicable law, we may modify these Escrow
          Instructions without prior notice to you, and any revisions to these
          Escrow Instructions will take effect when posted on the Site unless
          otherwise stated. The version of these Escrow Instructions in effect
          on the date any Fixed-Price Contract is entered into apply to that
          contract. Please check the Site often for updates.
        </p>
        <p className="">
          These Escrow Instructions hereby incorporate by reference the{" "}
          <a
            href="/terms-and-conditions"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Terms of Service
          </a>{" "}
          (<span className="font-semibold">“Terms of Service”</span>).
          Capitalized terms not defined in these Escrow Instructions are defined
          in the{" "}
          <a href="#" className="border-b border-bondi-blue text-bondi-blue">
            User Agreement
          </a>{" "}
          or elsewhere in the Terms of Service, or have the meanings given such
          terms on the Site. For purposes of these Escrow Instructions, the term{" "}
          <span className="font-semibold">“Talent” </span>
          means a User with a Talent account.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">1.</span> DIGITAL SIGNATURE AND COMMUNICATIONS
        </h2>
        <p className="">
          By clicking to fund Escrow (a{" "}
          <span className="font-semibold">“Funding Approval”</span>) or to
          accept a Fixed-Price Contract, Client and Talent are deemed to have
          executed these Escrow Instructions electronically, effective on the
          date Talent clicks to accept the engagement, pursuant to California
          Civil Code Section 1633.8 and the federal Electronic Signatures in
          Global and National Commerce Act, 15 U.S.C. Sec. 7001, et seq., as may
          be amended from time to time (the{" "}
          <span className="font-semibold">"E-Sign Act"</span>). Doing so
          constitutes an acknowledgement that Client and Talent agree to conduct
          the transaction electronically, and are able to electronically
          receive, download, and print these Escrow Instructions. All references
          to the Escrow in these Escrow Instructions will include the initial
          Funding Approval and any additional Funding Approval for a Fixed-Price
          Contract.
        </p>
        <p className="mt-4">
          Client and Talent agree that any written communications required
          herein may be provided electronically, including by email or support
          ticket, if applicable. Any communication sent to Filly Jobs via U.S.
          mail or similar service will be deemed timely if received by Filly
          Jobs by the applicable deadline.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">2.</span> ACCEPTANCE OF ESCROW INSTRUCTION
        </h2>
        <p className="">
          Once a Client and Talent have agreed to a Service Contract, and the
          Client and Talent have selected “Agree” on the send offer, accept
          offer, or pages relating to the Service Contract to accept the Terms
          of Service (including these Escrow Instructions), these Escrow
          Instructions constitute a binding agreement between Client, Talent,
          and Filly Jobs Escrow (collectively, the{" "}
          <span className="font-semibold">“Parties”</span>). Client and Talent
          must execute (by digital signature or by a method mutually agreed upon
          by both parties), and Filly Jobs Escrow must agree to, any
          supplemental instruction or addition, deletion or alteration thereto
          in writing (collectively the{" "}
          <span className="font-semibold">
            “Supplemental Escrow Instruction”
          </span>
          ). Filly Jobs Escrow reserves the right to reject any Supplemental
          Escrow Instruction.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">3.</span> DEPOSIT OF FUNDS INTO ESCROW
        </h2>
        <p className="">
          By sending a contract offer to Talent or accepting a contract offer
          from Talent, Client agrees to deposit funds for the amount of the
          first Milestone (as defined on the Site), or, if there are no
          Milestones, the full amount of the Service Contract. Additional
          Milestones may be funded on the same contract by Client by selecting
          the button to add an additional Milestone or to activate the next
          Milestone on the Site. Funds deposited by Client remain in the Client
          Escrow Account until they are released to the Talent Escrow Account or
          released to Client Escrow Account. Filly Jobs Escrow will release
          funds held in escrow pursuant to Section 4 of these Escrow
          Instructions (Release and Delivery of Amounts in Escrow).
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">4.</span> RELEASE AND DELIVERY OF AMOUNTS IN
          ESCROW
        </h2>
        <p className="">
          Client and Talent irrevocably authorize and instruct Filly Jobs Escrow
          to release applicable portions of the Fixed-Price Escrow Account (each
          portion, a <span className="font-semibold">“Release”</span>) to the
          Talent Escrow Account or Client Escrow Account, as applicable, based
          on the instructions described in this Section 4 (each such
          instruction, a “Release Condition”), or as otherwise required by
          applicable law or expressly permitted by the Terms of Service. The
          amount of each Release will be delivered to the applicable Escrow
          Account in accordance with Talent’s or Client’s instructions, these
          Escrow Instructions, and the other Terms of Service. All funds
          released pursuant to these Escrow Instructions are subject to
          applicable fees as described in the{" "}
          <a href="#" className="border-b border-bondi-blue text-bondi-blue">
            Fee and ACH Authorization Agreement
          </a>
          .
        </p>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="">4.1</span> RELEASE OF FUNDS TO TALENT
          </h3>
          <p className="mb-6">
            Talent and Client authorize and instruct Filly Jobs Escrow to
            immediately release funds to the Talent Escrow Account on the
            occurrence of any of the following Release Conditions, such
            authorization and instruction to be irrevocable except as expressly
            provided in these Escrow Instructions:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              Client affirmatively selects the option to release funds for a
              Milestone or Service Contract to Talent.
            </li>
            <li className="mb-4">
              Client does not take any action for 14 calendar days from the date
              of Talent’s Release request, in which case Talent and Client agree
              that Filly Jobs Escrow is authorized and instructed to immediately
              release to Talent the amount associated with the applicable
              Milestone in connection with such Release request.
            </li>
            <li className="mb-4">
              Client and Talent have jointly submitted Supplemental Escrow
              Instructions in writing and signed by both Client and Talent to
              Filly Jobs Escrow at legalnotices@Filly Jobs.com requesting a
              Release to Talent Escrow Account, and Filly Jobs Escrow has agreed
              to the Supplemental Escrow Instructions.
            </li>
            <li className="mb-4">
              Client has declined Arbitration or failed to make its Arbitration
              Payment (defined below) pursuant to the Dispute Assistance
              Program, and Talent has agreed to Arbitration and submitted its
              Arbitration Payment.
            </li>
            <li className="mb-4">
              Client has failed timely to respond to a Notice of Dispute
              (defined below) or otherwise failed to comply with the Dispute
              Assistance Program, as required by the Escrow Disputes Team.
            </li>
            <li className="mb-4">
              Submittal of a final award of an arbitrator appointed pursuant to
              the Dispute Assistance Program in favor of Talent or to the extent
              the award is in favor of Talent.
            </li>
            <li className="mb-4">
              Issuance of an order by a court, arbitrator, or other judicial
              body of apparent competent jurisdiction in favor of Talent, in
              whole or in part, to the extent required by the order.
            </li>
            <li className="mb-4">
              A condition to release funds to Talent described elsewhere in
              these Escrow Instructions applies
            </li>
          </ol>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">4.2</span> RELEASE OF FUNDS TO CLIENT
          </h3>
          <p className="mb-6">
            Talent and Client authorize and instruct Filly Jobs Escrow to
            immediately release funds to the Client Escrow Account on the
            occurrence of any of the following Release Conditions, such
            authorization and instruction to be irrevocable except as expressly
            provided in these Escrow Instructions:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              Talent cancels the Service Contract or accepts Client’s request to
              cancel the Service Contract (as described in Section 4.4), and
              funds for a Milestone or the Service Contract are held in Escrow.
            </li>
            <li className="mb-4">
              Client and Talent have jointly submitted Supplemental Escrow
              Instructions in writing and signed by both Client and Talent to
              Filly Jobs Escrow at{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>{" "}
              requesting a Release to the Client Escrow Account, and Filly Jobs
              Escrow has agreed to the Supplemental Escrow Instructions.
            </li>
            <li className="mb-4">
              Client and Talent agree to close the Service Contract without
              release of funds to the Talent Escrow Account.
            </li>
            <li className="mb-4">
              Talent has declined Arbitration or failed to make its Arbitration
              Payment pursuant to the Dispute Assistance Program.
            </li>
            <li className="mb-4">
              Talent has failed to timely respond to a request for a refund
              submitted through the platform, Notice of Dispute, or otherwise
              failed to comply with the Dispute Assistance Program, as required
              by the Escrow Disputes Team.
            </li>
            <li className="mb-4">
              Submittal of a final award of an arbitrator appointed pursuant to
              the Dispute Assistance Program in favor of Client or to the extent
              the award is in favor of Client.
            </li>
            <li className="mb-4">
              Issuance of an order of a court, arbitrator, or other judicial
              body of apparent competent jurisdiction in favor of Client, in
              whole or in part, to the extent required by the order.
            </li>
            <li className="mb-4">
              A condition to release funds to Client described elsewhere in
              these Escrow Instructions applies.
            </li>
            <li className="mb-4">
              For Service Contracts entered through Project Catalog, Client has
              failed to respond to a request for project requirements within 48
              hours, at which point any funds in escrow will be released to
              Client and the contract will be closed.
            </li>
          </ol>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">4.3</span> DORMANT ENGAGEMENTS
          </h3>
          <p className="">
            To be fair to Clients and Talents, Filly Jobs has a procedure for
            Fixed-Price Contracts that appear to be Dormant Engagements (as
            defined below). For purposes of determining dormant status,
            “activity” means a change to the Service Contract, including
            Milestone updates or requests, Fixed-Price Escrow Funding,
            Fixed-Price Escrow Release, Fixed-Price Escrow Refunds, Funding
            requests, Release requests, requests to close the Fixed-Price
            Contract, or actions under the Dispute Assistance Program.
          </p>
          <p className="mb-6">
            A <span className="font-semibold">“Dormant Engagement”</span> is a
            Fixed-Price Contract that has a Fixed-Price Escrow Account with a
            balance but has had no activity for 90 consecutive calendar days
            after the last Milestone date contained in the Service Contract
            terms (“Dormant Date”). Dormant Engagements are subject to the
            following rules:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              Filly Jobs will notify Client when the Fixed-Price Contract
              becomes Dormant.
            </li>
            <li className="mb-4">
              If the Client does not take any action within 7 calendar days
              after the Dormant Date and notification, Filly Jobs will notify
              Talent that the Fixed-Price Contract is Dormant (
              <span className="font-semibold">
                “Talent Notice of Dormant Engagement”
              </span>
              ).
            </li>
            <li className="mb-4">
              If Talent submits a Release request within 7 calendar days after
              the Talent Notice of Dormant Engagement and Client does not take
              any action for 14 calendar days from the date of the Release
              request, Talent and Client authorize and irrevocably instruct
              Filly Jobs Escrow to immediately release to Talent the amount
              related to the Milestone with the Release request.
            </li>
            <li className="mb-4">
              If neither Talent nor Client take any action for 7 calendar days
              after the Talent Notice of Dormant Engagement, Talent and Client
              authorize and irrevocably instruct Filly Jobs Escrow to
              immediately release escrow funds to the Client Escrow Account.
            </li>
          </ol>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">4.4</span> REFUNDS AND CANCELLATION
          </h3>
          <p className="">
            Client and Talent are encouraged to come to a mutual agreement if
            refunds or cancellations are necessary. If there are no funds in
            Escrow, Client and Talent have the ability to cancel the Service
            Contract by clicking to close the Service Contract. If funds are
            held in Escrow, refunds and cancellations must be initiated by
            Client or Talent by following the steps in this Section.
          </p>
          <div className="mt-8">
            <h2 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
              <span className="mr-1">4.4.1</span> REFUNDS AND CANCELLATION BY
              TALENTS
            </h2>
            <p className="">
              If Talent wants to cancel a Service Contract with funds held in
              Escrow, Talent must select “End Contract” on the Filly Jobs
              platform. When Talent ends the Service Contract on the Filly Jobs
              platform, Talent and Client agree that Filly Jobs Escrow is
              authorized and irrevocably instructed to immediately release to
              the Client Escrow Account all funds held in Escrow on the Service
              Contract at that time.
            </p>
            <p className="mt-3">
              Talent may issue a refund to Client up to the amount paid on a
              Service Contract in the past 180 days by selecting “Give a Refund”
              on the Filly Jobs platform. Talent may not issue a refund in an
              amount greater than the combined amount of funds held in Talent
              Escrow Account, funds for transactions pending to be placed in the
              Talent Escrow Account, and funds for submitted Milestones. By
              selecting the option to give a refund, Talent agrees that Filly
              Jobs Escrow is authorized and irrevocably instructed to
              immediately release to the Client Escrow Account all Escrow funds
              currently held in the Talent Escrow Account and such funds as may
              be placed into the Talent Escrow Account once the funds are
              available, until the entire refund is provided to Client.
            </p>
          </div>
          <div className="mt-8">
            <h2 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
              <span className="mr-1">4.4.2</span> REFUNDS AND CANCELLATION BY
              CLIENTS
            </h2>
            <p className="">
              If Client wants to cancel a Service Contract with funds held in
              Escrow, Client must select “End Contract” on the Filly Jobs
              platform. Talent must select the option to either approve or
              dispute Client’s cancellation within 7 calendar days. If Talent
              approves the cancellation, Talent and Client agree that Filly Jobs
              Escrow is authorized and irrevocably instructed to immediately
              release to the Client Escrow Account all funds held in Escrow on
              the Service Contract at that time. If Talent is using the Site on
              a mobile device and does not have the ability to select the option
              to approve or dispute the cancellation on the mobile website or
              application, Talent must dispute Client’s cancellation via support
              ticket within 7 calendar days. If Talent takes no action within 7
              calendar days from the date notification of the cancellation is
              sent to Talent by Filly Jobs, Talent and Client agree that Filly
              Jobs Escrow is authorized and irrevocably instructed to
              immediately release to the Client Escrow Account all funds held in
              Escrow on the Service Contract at that time. If Talent disputes
              the cancellation, Talent and Client will be offered Filly Jobs
              Dispute Assistance (as described in Section 6).
            </p>
          </div>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">4.5</span> VIOLATION OF FILLY JOBS TERMS OF
            SERVICE OR LAW
          </h3>
          <p className="">
            Talent and Client acknowledge and agree that if, in Filly Jobs
            Escrow’s sole discretion, Filly Jobs Escrow believes that fraud,
            illegal activity, or a violation of the Terms of Service has been
            committed or is being committed or attempted, then Client and Talent
            irrevocably authorize and instruct Filly Jobs Escrow to take such
            actions as deemed appropriate, in Filly Jobs Escrow’s sole
            discretion and in accordance with applicable law, in order to
            prevent or remedy such acts, including without limitation, to return
            the funds associated with such acts to their source of payment, as
            further described in Section 6.3 of the User Agreement. This Section
            4.5 allows Filly Jobs Escrow—as permitted by law but otherwise in
            its sole discretion—to return funds to Client’s Payment Method,
            continue to hold funds in Escrow, release funds to the Talent Escrow
            Account, or to turn funds over to third parties such as law
            enforcement.
          </p>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">5.</span> INSTRUCTIONS IRREVOCABLE
        </h2>
        <p className="">
          Client and Talent are deemed to and hereby agree that the instruction
          to Filly Jobs Escrow and its wholly owned subsidiaries to release
          funds is irrevocable, except as explicitly provided in these Escrow
          Instructions. Without limiting the foregoing, Client’s instruction to
          Filly Jobs Escrow and its wholly owned subsidiaries to release payment
          to Talent is irrevocable. Such instruction is Client’s authorization
          to transfer funds to the Talent Escrow Account from the Client Escrow
          Account or authorization to charge Client’s Payment Method. Such
          instruction is also Client’s representation that Client has received,
          inspected, and accepted the subject work or expense. Client
          acknowledges and agrees that upon receipt of Client’s instruction to
          release payment to the Talent Escrow Account, Filly Jobs Escrow will
          transfer funds to the Talent Escrow Account and that Filly Jobs, Filly
          Jobs Escrow, and other Affiliates have no responsibility to and may
          not be able to recover such funds. Therefore, and in consideration of
          the services described in these Escrow Instructions and the Terms of
          Service, Client agrees that once Filly Jobs Escrow or its subsidiaries
          or Affiliates have charged Client’s Payment Method, the charge is
          non-refundable. Client and Talent further agree that the only manner
          in which an instruction to Filly Jobs Escrow is revocable is if Client
          and Talent have jointly submitted Supplemental Escrow Instructions in
          writing and signed by both Client and Talent to Filly Jobs Escrow at{" "}
          <a
            href="mailto:contact@fillyjobs.com"
            className="border-b border-b-bondi-blue text-bondi-blue"
          >
            contact@fillyjobs.com
          </a>{" "}
          requesting that Filly Jobs Escrow take specific action with respect to
          the funds in its possession, and Filly Jobs Escrow has agreed to the
          Supplemental Escrow Instructions.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">6.</span> DISPUTE ASSISTANCE PROGRAM
        </h2>
        <p className="">
          If Client and Talent fail to come to a mutual resolution by way of the
          Refund and Cancellation process as stated in Section 4.4, Filly Jobs
          provides the dispute assistance program administered by Filly Jobs and
          described in this Section 6 (the{" "}
          <span className="font-semibold">“Dispute Assistance Program”</span>)
          as a mechanism to resolve the dispute. If the funds in dispute are
          held in Escrow, the Dispute Assistance Program will proceed as
          described in Section 6.1. If the funds in dispute have been released,
          the Dispute Assistance Program will proceed as described in Section
          6.2. The Dispute Assistance Program is not available for disputes
          filed or initiated past the Dispute Assistance deadlines, as set forth
          in Sections 6.1 and 6.2, as applicable. The Dispute Assistance Program
          is offered as a form of non-binding assistance to facilitate
          communication and potential resolution of disputes between clients and
          Talents. The Dispute Assistance Program as administered by Filly Jobs
          in this Section 6 does not evaluate the quality or functionality of
          work and cannot render binding judgment or determination as to the
          parties’ respective rights to the disputed funds. Fixed-Price
          Contracts with Enterprise Clients or Filly Jobs Business Clients
          (defined in the{" "}
          <a href="#" className="border-b border-bondi-blue text-bondi-blue">
            Fee and ACH Authorization Agreement
          </a>
          ) are not subject to Sections 6 and 7, regarding Filly Jobs’s Dispute
          Assistance Program and Arbitration, respectively.
        </p>

        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">6.1</span> DISPUTE OF FUNDS IN ESCROW
          </h3>
          <p className="mb-6">
            This Section applies to disputes filed by Clients or Talents over
            funds that are held in Escrow as of the date the dispute is filed
            (“Escrow Dispute”). The scope of the Escrow Dispute may cover the
            entirety of the Fixed-Price Contract and all Milestones previously
            funded, approved, and released. In the event of an Escrow Dispute,
            funds in Escrow will remain in Escrow while the Dispute Assistance
            Program or Arbitration, as applicable, is being administered. The
            Dispute Assistance Program for an Escrow Dispute will be
            administered as set forth below:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              <span className="font-semibold">
                Escrow Dispute Assistance Deadline:
              </span>{" "}
              Escrow Disputes must be initiated before the funds in Escrow have
              been released in order to be eligible for the Dispute Assistance
              Program under this Section. Disputes over funds that have been
              released from Escrow may be eligible for the Dispute Assistance
              Program, as described more fully below in Section 6.2.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Filling a Dispute</span>
              <ol className="list-[lower-alpha] ml-6 lg:ml-8 mt-4">
                <li className="mb-4">
                  <span className="font-semibold">Talents:</span> Talents and
                  Agencies may initiate an Escrow Dispute when a Client ends the
                  project with an escrow balance or a Client fails to release a
                  Milestone payment, despite any purported delivery of work.
                </li>
                <li className="mb-4">
                  <span className="font-semibold">Clients:</span> Clients may
                  initiate an Escrow Dispute by requesting an Escrow refund on
                  the platform or by releasing a partial milestone payment. If
                  the Talent disputes the Escrow refund or offers a partial
                  Escrow refund that is subsequently rejected by the Client, the
                  case will be referred to the Dispute Assistance Program.
                </li>
              </ol>
            </li>
            <li className="mb-4">
              <span className="font-semibold">Talents:</span> Talents may
              initiate an Escrow Dispute when a Client ends the project with an
              escrow balance or a Client fails to release a Milestone payment,
              despite any purported delivery of work.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Clients:</span> Clients may
              initiate an Escrow Dispute by requesting an Escrow refund on the
              platform or by releasing a partial milestone payment. If the
              Talent disputes the Escrow refund or offers a partial Escrow
              refund that is subsequently rejected by the Client, the case will
              be referred to the Dispute Assistance Program.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Notice of Escrow Dispute:</span>{" "}
              Once a dispute has been filed, the Escrow dispute team that
              administers the Disputes Assistance Program (
              <span className="font-semibold">“Escrow Disputes Team”</span>)
              will notify Client and Talent in writing of the Escrow dispute via
              ticket and request information and supporting documentation from
              the parties (
              <span className="font-semibold">“Notice of Escrow Dispute”</span>
              ).
            </li>
            <li className="mb-4">
              <span className="font-semibold">Lack of Participation:</span>{" "}
              Client and Talent must respond to the Notice of Escrow Dispute
              within 5 calendar days. If one party does not timely respond to
              the Notice of Escrow Dispute, Client and Talent agree that the
              lack of timely response acts as an irrevocable authorization and
              instruction to Filly Jobs Escrow to release the funds in Escrow
              from the non-responding party to the responding party.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Non-Binding Assistance:</span>{" "}
              After both Client and Talent respond to the Notice of Escrow
              Dispute, the Escrow Disputes Team will review the documentation
              submitted and any information available on the Site that pertains
              to the dispute. The Escrow Disputes Team will facilitate
              communication between the parties and help assess whether mutual
              resolution is possible. The Escrow Disputes Team does not evaluate
              the quality or functionality of work and cannot render binding
              judgment or determination as to the parties’ respective rights to
              the disputed funds.
            </li>
            <li className="mb-4">
              <span className="font-semibold">
                Resolution of Escrow Dispute:
              </span>{" "}
              If the Escrow Disputes Team is able to facilitate a resolution
              between Client and Talent, and if Client and Talent agree in
              writing to the resolution, Client and Talent agree that Filly Jobs
              Escrow is authorized and irrevocably instructed to immediately
              release Escrow funds in accordance with the agreed-upon
              resolution. This will result in a closure of the Escrow dispute
              ticket.
            </li>
            <li className="mb-4">
              <span className="font-semibold">No Resolution:</span> If no
              resolution of the Escrow dispute has been reached within 21
              calendar days of the Notice of Escrow Dispute, or if the Escrow
              Disputes Team determines in its sole discretion that no resolution
              between the parties is possible through the Dispute Assistance
              Program, the Escrow Disputes Team will issue a “Notice of
              Non-Resolution” and the Escrow dispute will be referred to
              Arbitration, as set forth in Section 7 below.
            </li>
          </ol>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">6.2</span> DISPUTE OF FUNDS RELEASED
          </h3>
          <p className="mb-6">
            This Section applies to disputes filed by Clients or Talents over
            funds that have been released from Escrow as of the date the dispute
            is filed (<span className="font-semibold">“Dispute Mediation”</span>
            ). The scope of the Dispute Mediation may cover any portion of or
            the entirety of the Fixed Price Contract and any Milestone
            previously funded, approved, and released. The Dispute Assistance
            Program for Dispute Mediation will be administered as set forth
            below:
          </p>
          <ol className="list-[auto] ml-6 lg:ml-8" type="1">
            <li className="mb-4">
              <span className="font-semibold">Dispute Mediation Deadline:</span>{" "}
              Dispute Mediation must be initiated within 30 calendar days of the
              date that funds in Escrow have been released to the Talent in
              order to be eligible for the Dispute Assistance Program under this
              Section 6.2. Any dispute over funds that have been released from
              Escrow more than 30 days prior to the date the dispute is filed is
              no longer eligible for submission to the Dispute Assistance
              Program.
            </li>
            <li className="mb-4">
              <span className="font-semibold">
                Initiating Dispute Resolution
              </span>
              <ol className="list-[lower-alpha] ml-6 lg:ml-8 mt-4">
                <li className="mb-4">
                  <span className="font-semibold">Requesting a Refund:</span>{" "}
                  Clients may initiate Dispute Mediation for disputes over funds
                  that have been released from Escrow by requesting a refund on
                  the platform. If the Talent rejects the request for a refund,
                  grants a partial refund that is subsequently rejected by the
                  Client, or takes no action, the dispute will be referred to
                  the Dispute Assistance Program
                </li>
                <li className="mb-4">
                  <span className="font-semibold">Contacting Support:</span> In
                  addition to the above method, any User may contact Filly Jobs
                  Support for assistance initiating Dispute Mediation.
                </li>
              </ol>
            </li>
            <li className="mb-4">
              <span className="font-semibold">
                Notice of Dispute Mediation:
              </span>{" "}
              Once a dispute has been initiated, the Filly Jobs Dispute
              Mediation team (
              <span className="font-semibold">“Dispute Mediation Team”</span>)
              will notify Client and Talent of the Dispute Mediation in writing
              and request information and supporting documentation from the
              parties (
              <span className="font-semibold">
                “Notice of Dispute Mediation”
              </span>
              ). Client and Talent must respond to the Notice of Dispute
              Mediation within 5 calendar days. Failure to respond to the Notice
              of Dispute Mediation within 5 calendar days will result in the
              suspension of the non-participating party’s User account, as
              defined in Section 1.4 of the User Agreement, which may impact the
              non-participating party’s ability to withdraw funds.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Non Binding Assistance:</span>{" "}
              Once both Client and Talent respond to the Notice of Dispute
              Mediation, the Dispute Mediation Team will review the
              documentation submitted and any information available on the Site
              that pertains to the dispute. The Dispute Mediation Team will
              facilitate communication between the parties and help assess
              whether mutual resolution is possible. The Dispute Mediation Team
              does not evaluate the quality or functionality of work and cannot
              render binding judgment or determination as to the parties’
              respective rights to the disputed funds.
            </li>
            <li className="mb-4">
              <span className="font-semibold">
                Resolution of Dispute Mediation:
              </span>{" "}
              If the Dispute Mediation Team is able to facilitate a resolution
              between Client and Talent, and if Client and Talent agree in
              writing to the resolution, the Dispute Mediation Team will send
              the applicable party instructions on transferring payment, if any,
              to an Escrow Account. By agreeing in writing to the resolution,
              Client and Talent agree that Filly Jobs Escrow is authorized and
              irrevocably instructed to immediately release Escrow funds in
              accordance with the agreed-upon resolution. This will result in
              the closure of the Dispute Mediation ticket.
            </li>
            <li className="mb-4">
              <span className="font-semibold">No Resolution:</span> If no
              resolution of the dispute has been reached within 21 calendar days
              of the Notice of Dispute Mediation, or if the Dispute Mediation
              Team determines in its sole discretion that no resolution between
              the parties is possible through the Dispute Assistance Program,
              the Dispute Mediation Team will issue a “Notice of
              Non-Resolution,” and the dispute will be referred to Arbitration,
              as set forth in Section 7 below.
            </li>
          </ol>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">7.</span> ARBITRATION
        </h2>
        <p className="">
          After a{" "}
          <span className="font-semibold">“Notice of Non-Resolution”</span> is
          issued from the Filly Jobs Dispute Assistance Program, Talent and
          Client each has the right to demand Arbitration of a Fixed-Price
          Contract if the Fixed-Price Escrow associated with the Fixed-Price
          Contract has been funded at least once. The demand for Arbitration
          must be communicated to the Escrow Disputes Team or Dispute Mediation
          Team, as applicable, within 7 calendar days of the date of the Notice
          of Non-Resolution. In any Arbitration, each of you and the other User
          is a <span className="font-semibold">“Dispute Party,”</span> and
          collectively you are the{" "}
          <span className="font-semibold">“Dispute Parties.”</span> Any
          Arbitration under this Section 7 will be conducted by a neutral
          third-party Arbitration service, the{" "}
          <a href="#" className="border-b border-bondi-blue text-bondi-blue">
            American Arbitration Association
          </a>{" "}
          (the “AAA”), unless Filly Jobs chooses another Arbitration service.
          The Arbitration rules and fees are set out in Appendix A to these
          Escrow Instructions.
        </p>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">7.1</span> ARBITRATION INITIATION PROCEDURE
          </h3>
          <ol className="list-[auto] ml-6 lg:ml-8 mt-2" type="1">
            <li className="mb-4">
              <span className="font-semibold">
                Notice of Arbitration Demand:
              </span>{" "}
              Client and Talent have 7 calendar days after receiving the Notice
              of Non-Resolution to notify Filly Jobs via a dispute ticket of
              their intent to initiate Arbitration. The Filly Jobs Dispute Team
              will then provide a{" "}
              <span className="font-semibold">
                “Notice of Arbitration Demand”
              </span>{" "}
              to both Client and Talent and provide the Dispute Parties with
              information on how to submit Client’s or Talent’s applicable
              portion of the costs of Arbitration (the{" "}
              <span className="font-semibold">“Arbitration Payment”</span>). To
              proceed with Arbitration, Client and Talent must each make the
              Arbitration Payment within 5 calendar days of the Notice of
              Arbitration Demand.
            </li>
            <li className="mb-4">
              <span className="font-semibold">Filly Jobs Escrow Duties</span>
              <ol className="list-[lower-alpha] ml-6 lg:ml-8 mt-4">
                <li className="mb-4">
                  <span className="font-semibold">
                    Rejection of Arbitration or non-participation by both
                    parties:
                  </span>{" "}
                  If both Dispute Parties decline to take the matter to
                  Arbitration or do not make the Arbitration Payment within 5
                  calendar days of the Notice of Arbitration Demand, Client and
                  Talent agree that Filly Jobs Escrow is authorized and
                  irrevocably instructed to immediately release the funds in
                  Escrow, if any, to the Client’s primary payment method on
                  file. This will close the Escrow Dispute ticket. To the extent
                  the parties wish to take legal action to enforce their
                  contractual rights, Filly Jobs may provide contact information
                  on file for Client or Talent, consistent with Filly Jobs’s{" "}
                  <a
                    href="/privacy-policy"
                    className="border-b border-bondi-blue text-bondi-blue"
                  >
                    Privacy Policy.
                  </a>
                </li>
                <li className="mb-4">
                  <span className="font-semibold">
                    Rejection of Arbitration or non-participation by one party:
                  </span>{" "}
                  If one party elects to take the matter to Arbitration and has
                  submitted the Arbitration Payment (
                  <span className="font-semibold">“Participating Party”</span>),
                  and the other party rejects Arbitration, fails to submit the
                  Arbitration Payment or does not respond within 5 calendar days
                  of the Notice of Arbitration Demand (
                  <span className="font-semibold">
                    “Non-Participating Party”
                  </span>
                  ), Talent and Client agree that Filly Jobs Escrow is
                  authorized and irrevocably instructed to immediately release
                  the funds in Escrow, if any, to the Participating Party. This
                  will close the Escrow Dispute ticket. To the extent the
                  parties wish to take legal action to enforce their contractual
                  rights, Filly Jobs may provide contact information on file for
                  Client or Talent, consistent with Filly Jobs’s Privacy Policy.
                </li>
              </ol>
            </li>
            <li className="mb-4">
              <span className="font-semibold">
                Filly Jobs Dispute Mediation
              </span>
              <ol className="list-[lower-alpha] ml-6 lg:ml-8 mt-4">
                <li className="mb-4">
                  <span className="font-semibold">
                    Rejection of Arbitration or non-participation by both
                    parties:
                  </span>{" "}
                  If both Dispute Parties decline to take the matter to
                  Arbitration or do not respond to Filly Jobs’s Notice of
                  Arbitration Demand within 5 calendar days, the Dispute
                  Mediation ticket will be closed. To the extent the parties
                  wish to take legal action to enforce their contractual rights,
                  Filly Jobs may provide contact information on file of Client
                  or Talent, consistent with Filly Jobs’s{" "}
                  <a
                    href="/privacy-policy"
                    className="border-b border-bondi-blue text-bondi-blue"
                  >
                    Privacy Policy.
                  </a>
                </li>
                <li className="mb-4">
                  <span className="font-semibold">
                    Rejection of Arbitration or non-participation by one party:
                  </span>{" "}
                  If one party elects to take the matter to Arbitration and has
                  submitted the Arbitration Payment, and the other party rejects
                  Arbitration, fails to submit the Arbitration Payment or does
                  not respond within 5 calendar days of the Notice of
                  Arbitration Demand, Filly Jobs will suspend the
                  Non-Participating Party’s User account, as defined in Section
                  1.4 of the User Agreement, which may impact the
                  Non-Participating Party’s ability to withdraw funds.
                </li>
              </ol>
            </li>
            <li className="mb-4">
              <span className="font-semibold">Arbitration Instructions:</span>{" "}
              If both parties timely submit the Arbitration Payment, Filly Jobs
              will instruct the parties on the process for initiating the
              Arbitration with the Arbitration service provider. After the
              parties have initiated the Arbitration, Filly Jobs will provide
              the arbitrator with relevant documentation, including information
              collected in the Escrow Dispute or Dispute Mediation process and
              contents of the Contract Room. The scope of Arbitration may cover
              the entirety of the Fixed-Price Contract and all Milestones
              previously funded, approved, and released.
            </li>
          </ol>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">7.2</span> AUTHORIZATION TO COLLECT
            ARBITRATION FEES
          </h3>
          <p className="">
            When you electronically authorize payment of the invoice for the
            Arbitration Payment as emailed to you by Filly Jobs, you irrevocably
            authorize and instruct (i) Filly Jobs Escrow or its Affiliates, as
            your agent, to charge your Payment Method for the amount of the
            payment for credit to your Escrow Account; and (ii) Filly Jobs
            Escrow, as escrow agent, to immediately release that amount from
            your Escrow Account and pay it to Filly Jobs. If Filly Jobs Escrow
            or its Affiliates cannot collect sufficient funds to fulfill the
            Escrow release instructions for any reason, Filly Jobs Escrow has no
            obligation with respect to making the payment to Filly Jobs on your
            behalf, and you will be considered as not having paid the
            Arbitration Payment as required by this Section 7.2.
          </p>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">7.3</span> LIMITATIONS PERIOD FOR
            ARBITRATIONS
          </h3>
          <p className="">
            If both Client and Talent fail to demand Arbitration within 7
            calendar days of the Notice of Non-Resolution, fail to timely submit
            the Arbitration Payments, or fail to respond to the Notice of
            Arbitration Demand within 5 calendar days, then the dispute is no
            longer eligible for Arbitration under this process, and Client and
            Talent will be deemed to have irrevocably authorized and instructed
            Filly Jobs Escrow to, and Filly Jobs Escrow will, release all funds
            in the Fixed-Price Escrow Account to the Client’s Escrow Account.
          </p>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            <span className="mr-1">7.4</span>
            ARBITRATION AWARD
          </h3>
          <p className="">
            Client and Talent agree that the arbitrator is authorized to decide
            the Escrow Dispute or Dispute Mediation within its discretion. You
            agree that the arbitrator’s award is final, that it may be entered
            in and enforced by any court of competent jurisdiction, and that if
            the arbitrator delivers notice of any award to Filly Jobs, then
            Filly Jobs and Filly Jobs Escrow have the right to treat such notice
            as conclusive and act in reliance thereon.
          </p>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">8.</span> SERVICE FEES FOR ESCROW FUNDS
          RELEASED FOLLOWING DISPUTE ASSISTANCE
        </h2>
        <p className="">
          All Escrow funds released under the Dispute Assistance Program are
          subject to the normal Service Fees associated with Escrow Accounts, as
          detailed in the User Agreement and these Escrow Instructions.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">9.</span> NOTICES
        </h2>
        <p className="">
          All notices to a User required by these Escrow Instructions will be
          made via email sent by Filly Jobs to the User’s registered email
          address. Users are solely responsible for maintaining a current,
          active email address registered with Filly Jobs, for checking their
          registered email address and for responding to notices sent by Filly
          Jobs to the User’s registered email address.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">10.</span> COOPERATION WITH THE DISPUTE
          ASSISTANCE PROGRAM
        </h2>
        <p className="">
          All claims, disputes or other disagreements between you and another
          User that are covered by the Dispute Assistance Program must be
          resolved in accordance with the terms in the Dispute Assistance
          Program. All claims filed or brought contrary to the Dispute
          Assistance Program will be considered improperly filed, unless
          otherwise required by law, and Filly Jobs will have the right to take
          any other action, including suspension or termination of your User
          Account, as defined in Section 1.4 of the User Agreement, which may
          impact your ability to withdraw funds, and any other legal action as
          Filly Jobs deems appropriate in its sole discretion.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          <span className="mr-1">11.</span> NO RESPONSIBILITY FOR SERVICES OR
          PAYMENTS
        </h2>
        <p className="">
          Filly Jobs, in its sole discretion, reserves the right to suspend or
          terminate your User Account, as defined in Section 1.4 of the User
          Agreement, immediately upon giving notice to you if Filly Jobs
          believes you may be abusing the Dispute Assistance Program or as
          otherwise permitted by the Terms of Service, which may impact your
          ability to withdraw funds. However, any disputes for any Fixed-Price
          Contracts that existed prior to termination will be subject to the{" "}
          <a
            href="/terms-and-conditions"
            className="border-b border-bondi-blue text-bondi-blue"
          >
            Terms of Service
          </a>
          .
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          APPENDIX A
        </h2>
        <h3 className="font-medium text-black text-lg my-1 mb-2 uppercase">
          RULES AND FEES FOR ARBITRATIONS CONDUCTED BY THE AMERICAN ARBITRATION
          ASSOCIATION
        </h3>
        <p className="">
          This Appendix A summarizes certain pertinent contractual terms that
          the American Arbitration Association (the "AAA") has agreed to in
          providing arbitration services to Clients and Talents who choose the
          AAA as their arbitrator under the Fixed-Price Escrow Instructions. The
          AAA is solely a third-party arbitration service provider. Filly Jobs
          and its Affiliates have no direct or indirect affiliate, partnership,
          joint venture, ownership or control relationship with or interest in
          the AAA. Filly Jobs and its Affiliates assume no responsibility or
          liability for the services of the AAA.
        </p>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            APPOINTMENT OF THE ARBITRATOR
          </h3>
          <ol className="list-[auto] ml-6 lg:ml-8 mt-4" type="1">
            <li className="mb-4">
              Each party will be provided with an identical list of five
              potential arbitrators and may have up to 3 calendar days to strike
              two potential arbitrators and rank the remaining in order of
              preference. If any party does not return the list within 5
              calendar days, all persons named therein will be deemed
              acceptable.
            </li>
            <li className="mb-4">
              From among the arbitrators approved on both lists and in
              accordance with the designated order of mutual preference, the AAA
              will invite the arbitrator to serve. If for any reason the
              appointment cannot be made according to this procedure, the AAA
              will have the power to make the appointment without submission of
              additional lists.
            </li>
            <li className="mb-4">
              Any arbitrator will be impartial and independent, will perform his
              or her duties with diligence and in good faith, and will be
              subject to disqualification for any grounds provided by applicable
              law.
            </li>
            <li className="mb-4">
              Neither Filly Jobs nor any party or party representative will
              communicate ex parte with an arbitrator or candidate for
              arbitrator concerning the Arbitration. Notwithstanding the above,
              Filly Jobs and an arbitrator may communicate ex parte solely for
              the purpose of enabling the arbitrator to access and use the Site,
              and the Contract Room, and the information contained therein,
              associated with the Fixed-Price Contract in dispute.
            </li>
          </ol>
        </div>
        <div className="mt-8">
          <h3 className="font-semibold text-black text-lg my-1 mb-2 uppercase">
            RULES OF THE PROCEEDINGS
          </h3>
          <ol className="list-[auto] ml-6 lg:ml-8 mt-4" type="1">
            <li className="mb-4">
              The amount at issue in any claims or counterclaims relating to a
              Fixed-Price Contract will be limited to the sum still held in
              Escrow and the sum previously released from Escrow for the
              Fixed-Price Contract.
            </li>
            <li className="mb-4">
              The Arbitration Plaintiff may submit a statement of the case (
              <span className="font-semibold">“Initial Statement”</span>), not
              to exceed 1,500 words, within 3 business days of being notified
              that the case has been filed with the AAA. The Arbitration
              Plaintiff will post the Initial Statement to the Contract Room.
            </li>
            <li className="mb-4">
              The Arbitration Respondent may submit a response (
              <span className="font-semibold">“Response Statement”</span>), not
              to exceed 1,500 words, within 3 business days of receiving the
              Initial Statement. The Arbitration Respondent will post the
              Response Statement to the Contract Room
            </li>
            <li className="mb-4">
              Either party may elect to use its dispute notice form or dispute
              response (as applicable) in lieu of creating a separate Initial
              Statement or Response Statement for the Arbitration. No party may
              submit new or different claims after the case has been initiated.
            </li>
            <li className="mb-4">
              All proceedings will be “on documents” and the evidence admissible
              will consist solely and exclusively of Contract Room contents at
              the time the Dispute was submitted to the Filly Jobs dispute
              resolution process. Any communication taking place outside the
              Contract Room must have been posted to the Contract Room within 24
              hours of the original transmission of that communication in order
              to be admissible. Once Arbitration is initiated, the arbitrator
              can make an award based solely on the Contract Room contents and
              material submitted to initiate the Arbitration. The failure of one
              party or the other to respond to an arbitrator request or
              otherwise continue to participate in the Arbitration will not
              prevent the arbitrator from making an award.
            </li>
            <li className="mb-4">
              The language of the Arbitration will be English.
            </li>
            <li className="mb-4">
              At the arbitrator’s sole discretion, the arbitrator may permit or
              require the submission of additional written statements from the
              parties (
              <span className="font-semibold">“Additional Arguments”</span>).
              The arbitrator is not required to permit Additional Arguments. If
              the arbitrator permits Additional Arguments, the arbitrator will
              establish the schedule and rules for the submission of such
              Additional Arguments, including any limits on the length of such
              Additional Arguments.
            </li>
            <li className="mb-4">
              The Arbitration will conclude within 30 calendar days from the
              date of submission to the AAA, and the arbitrator may grant an
              extension for good cause not to exceed 14 calendar days.
            </li>
            <li className="mb-4">
              Communications by either party to the arbitrator will be shared
              with all parties.
            </li>
            <li className="mb-4">
              All awards will be final, non-appealable and enforceable by any
              court of competent jurisdiction.
            </li>
            <li className="mb-4">
              Neither the AAA nor any arbitrator in a proceeding under these
              rules and procedures is a necessary or proper party in judicial
              proceedings relating to the Arbitration. The parties to an
              Arbitration under these rules and procedures will be deemed to
              have consented that neither the AAA nor any arbitrator will be
              liable to any party in any action for damages or injunctive relief
              for any act or omission in connection with any Arbitration under
              these rules and procedures.
            </li>
          </ol>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          THE AWARD
        </h2>
        <p className="">
          The AAA will provide Filly Jobs and the parties with a written award
          promptly upon the conclusion of the Arbitration, and in no event later
          than 14 calendar days after conclusion of the Arbitration. At the
          arbitrator’s sole discretion, the arbitrator may provide, but is not
          required to provide, written reasons for the Award to the parties.
        </p>
      </div>
      <div className="mt-8">
        <h2 className="font-semibold text-black text-xl my-1 mb-2 uppercase">
          THE FEES
        </h2>
        <p className="">
          The AAA will provide Filly Jobs and the parties with a written award
          promptly upon the conclusion of the Arbitration, and in no event later
          than 14 calendar days after conclusion of the Arbitration. At the
          arbitrator’s sole discretion, the arbitrator may provide, but is not
          required to provide, written reasons for the Award to the parties.
        </p>
      </div>
    </div>
  );
};

export default FixedPriceService;
