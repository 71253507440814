import React from "react";

const InfoDetails = () => {
  return (
    <div className="px-5 mt-10 sm:mt-16 flex flex-col sm:flex-row sm:flex-wrap items-center justify-between lg:w-full max-w-xl lg:max-w-7xl">
      <div className="mb-6 sm:mb-4 border-l-[3px] border-r-[3px] sm:border-r-0 border-bondi-blue px-5 sm:pr-0 text-center sm:text-left w-52 sm:w-1/2 lg:w-auto">
        <h2 className="text-[45px] font-bold">200</h2>
        <p className="">Happy Clients</p>
      </div>
      <div className="mb-6 sm:mb-4 border-l-[3px] border-r-[3px] sm:border-r-0 border-bondi-blue px-5 sm:pr-0 text-center sm:text-left w-52 sm:w-1/2 lg:w-auto">
        <h2 className="text-[45px] font-bold">300</h2>
        <p className="">Employees</p>
      </div>
      <div className="mb-6 sm:mb-4 border-l-[3px] border-r-[3px] sm:border-r-0 border-bondi-blue px-5 sm:pr-0 text-center sm:text-left w-52 sm:w-1/2 lg:w-auto">
        <h2 className="text-[45px] font-bold">500</h2>
        <p className=" w-max">Completed Projects</p>
      </div>
      <div className="mb-6 sm:mb-4 border-l-[3px] border-r-[3px] sm:border-r-0 border-bondi-blue px-5 sm:pr-0 text-center sm:text-left w-52 sm:w-1/2 lg:w-auto">
        <h2 className="text-[45px] font-bold">100</h2>
        <p className="">Ongoing Projects</p>
      </div>
    </div>
  );
};

export default InfoDetails;
