import React from "react";

const BlueBox = ({ children }) => {
  return (
    <div className="mb-24 lg:mt-20 px-5 mx-5 sm:mx-auto lg:w-[95%] xl:w-full max-w-xl lg:max-w-7xl bg-bondi-blue text-white relative lg:h-[234px] rounded-lg">
      <div className="absolute inset-0 m-auto w-full overflow-hidden">
        <div className="absolute inset-0">
          <svg
            className="m-auto inset-0 h-full"
            width="748"
            height="234"
            viewBox="0 0 748 234"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              opacity="0.15"
              cx="374"
              cy="79.5"
              rx="374"
              ry="216.5"
              fill="#D9D9D9"
            />
          </svg>
        </div>
        <div className="absolute inset-0">
          <svg
            className="m-auto inset-0 h-full"
            width="672"
            height="234"
            viewBox="0 0 672 234"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              opacity="0.15"
              cx="336"
              cy="119.5"
              rx="336"
              ry="244.5"
              fill="#D9D9D9"
            />
          </svg>
        </div>
      </div>
      <div className="py-5 lg:p-10 m-auto">{children}</div>
    </div>
  );
};

export default BlueBox;
