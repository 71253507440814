import React from "react";
import careersImg from "../../assets/careers/banner/careers-hero-img.jpg";

const Hero = () => {
  return (
    <section className="mb-4">
      <div className="flex items-center justify-between flex-col lg:flex-row lg:max-w-7xl lg:px-5 mx-auto lg:mt-8">
        <div className="img-wrapper lg:order-2 w-full lg:rounded-lg overflow-hidden">
          <img
            className="w-full h-full"
            src={careersImg}
            alt="two people smiling at computer"
          />
        </div>

        <div className="lg:order-1 max-w-xl text-center lg:text-left flex flex-col items-start justify-start mb-8 lg:my-0 mt-8 lg:mt-0 px-5 lg:px-0 lg:mr-4">
          <h1 className="font-semibold text-bondi-blue text-[2.75rem] lg:text-[3rem] xl:text-[3.125rem] xl:leading-[140%]">
            Work from anywhere with reliable team members
          </h1>
          <p className="text-lg mt-3 font-light text-dark-gray lg:pr-2 xl:pr-8">
            Our team members are determined to work from anywhere, with diverse
            backgrounds and the desire to learn. They’re people who believe in
            our mission, are obsessed with learning and growing as individuals.{" "}
          </p>
          <div className="btn-wrapper flex mt-8 justify-center lg:justify-start items-center lg:items-start w-full">
            <a
              href="#careers"
              className="font-semibold shadow-sm text-sm lg:text-base mx-2 md:w-auto text-center max-w-lg text-white hover:text-bondi-blue bg-bondi-blue hover:bg-white rounded-3xl px-10 lg:px-14 mb-5 py-3 lg:mb-0 lg:ml-0 lg:mr-3 lg:flex lg:items-center cursor-pointer border border-bondi-blue transition-all"
            >
              Browse our Careers
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
