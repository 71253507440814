import React from "react";

const FillyJobsPortfolio = () => {
  return (
    <section className="mt-28 max-w-xl lg:max-w-7xl mx-auto px-5 mb-16">
      <h2 className="text-[2.5rem] font-semibold text-center">
        Filly jobs Portfolio
      </h2>
      <p className="text-dark-gray text-center">
        Would you like to view the possible projects you could get done on filly
        jobs? then check below.
      </p>
      <div className="flex mt-16 pt-2 flex-col md:flex-row md:flex-wrap justify-between items-center">
        <div className="shadow-md w-full md:w-[45%] lg:w-[300px] xl:w-full max-w-[350px] max-h-[360px] overflow-hidden mx-3 mb-12">

          <div className="img-wrapper max-h-[273px]">
            <img
              src="https://s3-alpha-sig.figma.com/img/4f34/e858/2d26c7ed47f9c78d2411ed819f132cd7?Expires=1665360000&Signature=SIqAezdg9SP4IKrFvqoCmVQLtxpBqOtpL2CvLxFvHqZkdkG0ukIYMxQH1e3kF~etUvHdduG3dshEgzkB-IaD-ENfQHioLVtVAIt~WGD2NbdszttxqFU5sSoa3W1nbM7QXJvSqsoFOZn2vHmnysqBSjgpTFEtu8wVtv-1XZaKTzkd5EZD60BdaIcxCXnkEMYxCXpeJhZLY8cue7pwTepV04RU9kPnPxoxEkrp9FxImfbY2iAjzHDxgKUyF8e4Hyuk3TUFbN5zYPslmHsu4r45n4~fDyOAekHmUs3LdxXnHz2-nNntBYTgXPCJeP5iBRtPjdamy486Y-65~LBJEumfaQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              alt="portfolio screenshot"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="info px-6 py-5 flex-1">
            <h3 className="font-semibold">MOBILE APP DESIGN</h3>
          </div>
        </div>
        <div className="shadow-md w-full md:w-[45%] lg:w-[300px] xl:w-full max-w-[350px] max-h-[360px] overflow-hidden mx-3 mb-12">

          <div className="img-wrapper max-h-[273px]">
            <img
              src="https://s3-alpha-sig.figma.com/img/ebff/4951/e9f6165182a7bb2dc51d638749a9e302?Expires=1665360000&Signature=fu0LbYYXU57RkBbfcoGYEl-lGy~xkMoigzTebnTk8xhjDJi~bOYwDfjly7TSOsmtvemD9NCw-QB6kpUeuD9tEHs~BcUdl1acJMPiQEnexCtJ8gEPw6T1tdPoHsrGBf-gNrlD4610XDBXVw5spwu~JupAerZeiJ8TE~rM~HAag0o8-i3nx5zUpFYozNwSzoQZokubXd0nW97I3KfD6d7kkQwpRbeJVG75tJUqBFe24gdh3iEcWZUQ5p00sl2yN6xMd3C9iXC-AUy-7vrfD7zbNZnPJ9VkKPh7MKTIob5EHdfj-EX-aDWxO8KRN-vq0iyvU8XGNYn0izo03L5zXh~dBA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              alt="portfolio screenshot"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="info px-6 py-5 flex-1">
            <h3 className="font-semibold">SOCIAL MEDIA MANAGER</h3>
          </div>
        </div>
        <div className="shadow-md w-full md:w-[45%] lg:w-[300px] xl:w-full max-w-[350px] max-h-[360px] overflow-hidden mx-3 mb-12">

          <div className="img-wrapper max-h-[273px]">
            <img
              src="https://s3-alpha-sig.figma.com/img/f8f1/8420/6c36d553b4dc0cbc24c9b8fa0ff1408f?Expires=1665360000&Signature=arNTEsmXAVAGmMzoSIvwfx2el1fxc1jjShu7ZwxVTYTpYf5efimdqixsK2gtnzqDQ~qAw2EWxoS-A62-coKxDDF--wBjAg7w2ZlV4WMrFs8dHmAGyvi1b94UVGKyOzBJGIIsc-yUYYtRhdaCJwkbFjGZZXu8TiVL0GXW-55kJLkA-BhYV41YYU-pMYNuCoX6IlHG7vEKOFhpDYZAycUSzv4ksGQ0TqZPn-nVHcFAsiRtlQtIxS5~uiIbIi-WI0rjH4qOXBaYqbrwMRYF2R~~qyb3Ryd192HT9qWPMP0DoXC2RGbQkyEIZ2gwqOUSgKtyGyXfmnBWnf-q25DS7JVhxA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              alt="portfolio screenshot"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="info px-6 py-5 flex-1">
            <h3 className="font-semibold">LANDING PAGE DESIGN</h3>
          </div>
        </div>
        <div className="shadow-md w-full md:w-[45%] lg:w-[300px] xl:w-full max-w-[350px] max-h-[360px] overflow-hidden mx-3 mb-12">

          <div className="img-wrapper max-h-[273px]">
            <img
              src="https://s3-alpha-sig.figma.com/img/f8f1/8420/6c36d553b4dc0cbc24c9b8fa0ff1408f?Expires=1665360000&Signature=arNTEsmXAVAGmMzoSIvwfx2el1fxc1jjShu7ZwxVTYTpYf5efimdqixsK2gtnzqDQ~qAw2EWxoS-A62-coKxDDF--wBjAg7w2ZlV4WMrFs8dHmAGyvi1b94UVGKyOzBJGIIsc-yUYYtRhdaCJwkbFjGZZXu8TiVL0GXW-55kJLkA-BhYV41YYU-pMYNuCoX6IlHG7vEKOFhpDYZAycUSzv4ksGQ0TqZPn-nVHcFAsiRtlQtIxS5~uiIbIi-WI0rjH4qOXBaYqbrwMRYF2R~~qyb3Ryd192HT9qWPMP0DoXC2RGbQkyEIZ2gwqOUSgKtyGyXfmnBWnf-q25DS7JVhxA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              alt="portfolio screenshot"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="info px-6 py-5 flex-1">
            <h3 className="font-semibold">CONTENT DEVELOPMENT</h3>
          </div>
        </div>
        <div className="shadow-md w-full md:w-[45%] lg:w-[300px] xl:w-full max-w-[350px] max-h-[360px] overflow-hidden mx-3 mb-12">

          <div className="img-wrapper max-h-[273px]">
            <img
              src="https://s3-alpha-sig.figma.com/img/4f34/e858/2d26c7ed47f9c78d2411ed819f132cd7?Expires=1665360000&Signature=SIqAezdg9SP4IKrFvqoCmVQLtxpBqOtpL2CvLxFvHqZkdkG0ukIYMxQH1e3kF~etUvHdduG3dshEgzkB-IaD-ENfQHioLVtVAIt~WGD2NbdszttxqFU5sSoa3W1nbM7QXJvSqsoFOZn2vHmnysqBSjgpTFEtu8wVtv-1XZaKTzkd5EZD60BdaIcxCXnkEMYxCXpeJhZLY8cue7pwTepV04RU9kPnPxoxEkrp9FxImfbY2iAjzHDxgKUyF8e4Hyuk3TUFbN5zYPslmHsu4r45n4~fDyOAekHmUs3LdxXnHz2-nNntBYTgXPCJeP5iBRtPjdamy486Y-65~LBJEumfaQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              alt="portfolio screenshot"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="info px-6 py-5 flex-1">
            <h3 className="font-semibold">DATA ANALYST</h3>
          </div>
        </div>
        <div className="shadow-md w-full md:w-[45%] lg:w-[300px] xl:w-full max-w-[350px] max-h-[360px] overflow-hidden mx-3 mb-12">

          <div className="img-wrapper max-h-[273px]">
            <img
              src="https://s3-alpha-sig.figma.com/img/ebff/4951/e9f6165182a7bb2dc51d638749a9e302?Expires=1665360000&Signature=fu0LbYYXU57RkBbfcoGYEl-lGy~xkMoigzTebnTk8xhjDJi~bOYwDfjly7TSOsmtvemD9NCw-QB6kpUeuD9tEHs~BcUdl1acJMPiQEnexCtJ8gEPw6T1tdPoHsrGBf-gNrlD4610XDBXVw5spwu~JupAerZeiJ8TE~rM~HAag0o8-i3nx5zUpFYozNwSzoQZokubXd0nW97I3KfD6d7kkQwpRbeJVG75tJUqBFe24gdh3iEcWZUQ5p00sl2yN6xMd3C9iXC-AUy-7vrfD7zbNZnPJ9VkKPh7MKTIob5EHdfj-EX-aDWxO8KRN-vq0iyvU8XGNYn0izo03L5zXh~dBA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
              alt="portfolio screenshot"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="info px-6 py-5 flex-1">
            <h3 className="font-semibold">VIRTUAL ASSISTANT</h3>
          </div>
        </div>
      </div>
      <div className="mt-6 mx-auto w-full flex items-center justify-center lg:justify-start text-center">
        <a href="" className="bg-[#FF5C6D] font-semibold text-white rounded-3xl text-base py-4 w-full max-w-xs lg:w-auto px-14 mx-auto lg:ml-0">Browse more Jobs</a>
      </div>
    </section>
  );
};

export default FillyJobsPortfolio;
