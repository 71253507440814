import React from "react";
import testimonial1 from "../../assets/about/testimonial/testimonial-1.png";
import testimonial2 from "../../assets/about/testimonial/testimonial-2.png";
import testimonial3 from "../../assets/about/testimonial/testimonial-3.png";

const Testimonial = () => {
  return (
    <div className="my-24 px-5 mx-auto lg:w-full max-w-xl lg:max-w-7xl">
      <h2 className="font-bold text-[2.5rem] mb-9 text-center">Testimonials</h2>
      <div className="w-full flex flex-wrap justify-center lg:justify-between mx-auto">
        <div className="mb-4 lg:mb-0">
          <div className="relative bg-white lg:w-[280px] xl:w-[377px] shadow-lg rounded">
            <div className="bg-white z-10 relative inset-0 p-6">
              Since I started using Filly Jobs, I have been able to find workers
              for my company. I've benefited from the team of professionals who
              offer excellent search-engine optimization services thereby giving
              me sufficient time for other tasks.
            </div>
            <div className="bg-white absolute w-12 h-12 -bottom-5 inset-x-0 mx-auto rotate-45 shadow-md skew-x-[10deg] skew-y-[10deg]"></div>
          </div>
          <div className="mt-14 flex flex-col text-center items-center">
            <img
              src={testimonial1}
              alt="head shot of a woman"
              className="w-20 rounded-full overflow-hidden"
            />
            <h3 className="font-semibold text-base mt-2">
              Chief Executive Officer
            </h3>
            <p className="mt-1 text-sm">Lucid Digitals</p>
          </div>
        </div>
        <div className="mb-4 lg:mb-0">
          <div className="relative bg-white lg:w-[280px] xl:w-[377px] shadow-lg rounded">
            <div className="bg-white z-10 relative inset-0 p-6 lg:h-[288px] xl:h-[216px]">
              I am so thankful for this platform. The process was smooth, fast
              and well organized. I got my desired job very soon after
              submitting my application through Filly Jobs.
            </div>
            <div className="bg-white absolute w-12 h-12 -bottom-5 inset-x-0 mx-auto rotate-45 shadow-md skew-x-[10deg] skew-y-[10deg]"></div>
          </div>
          <div className="mt-14 flex flex-col text-center items-center">
            <img
              src={testimonial2}
              alt="head shot of a man"
              className="w-20 rounded-full overflow-hidden"
            />
            <h3 className="font-semibold text-base mt-2">Data Scientist</h3>
            <p className="mt-1 text-sm">Adans Gain</p>
          </div>
        </div>
        <div className="mb-4 lg:mb-0">
          <div className="relative bg-white lg:w-[280px] xl:w-[377px] shadow-lg rounded">
            <div className="bg-white z-10 relative inset-0 p-6 lg:h-[288px] xl:h-[216px]">
              I have been able to find talented people who are eager to work on
              their current skill set. They have a high interest in what they
              are doing, and so I am able to bring out the best in them.
            </div>
            <div className="bg-white absolute w-12 h-12 -bottom-5 inset-x-0 mx-auto rotate-45 shadow-md skew-x-[10deg] skew-y-[10deg]"></div>
          </div>
          <div className="mt-14 flex flex-col text-center items-center">
            <img
              src={testimonial3}
              alt="head shot of a woman"
              className="w-20 rounded-full overflow-hidden"
            />
            <h3 className="font-semibold text-base mt-2">HR Manager</h3>
            <p className="mt-1 text-sm">Esther Williams</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
