import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Login from "../Components/Login";
import Signup from "../Components/Signup";

export default function GuestRoot() {
  const showLogin = useSelector((state) => state.user.showLogin);
  const showSignup = useSelector((state) => state.user.showSignup);
  return (
    <>
      {showLogin && <Login />}
      {showSignup && <Signup />}
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}
