import React, { useEffect, useState } from "react";

const SubmitDuration = ({ active, setActive, duration, setDuration }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (active === 2) {
      setShow(true);
    } else {
      setShow(false);
    }

    return () => {
      setShow(false);
    };
  }, [active]);

  return (
    <div
      className={
        !show
          ? "hidden"
          : `bg-opacity-70 bg-slate-900 fixed left-0 h-screen inset-0 overflow-hidden right-0 top-0 w-full z-50
        x-transition:enter=transition ease-out duration-100 x-transition:enter-start=opacity-0 scale-90
        x-transition:enter-end=opacity-100 scale-100 x-transition:leave=transition ease-in duration-100
        x-transition:leave-start=opacity-100 scale-100 x-transition:leave-end=opacity-0 scale-90`
      }
    >
      <div className="grid h-full items-center max-w-3xl mx-auto px-4 py-8 relative">
        <div className="bg-white overflow-y-auto max-h-full relative rounded-lg">
          <div className=" flex text-riight items-right justify-end p-4 rounded-t">
            <button
              type="button"
              onClick={() => setActive(0)}
              className="bg-transparent hover:bg-slate-200 hover:text-slate-900 inline-flex items-center p-1.5 rounded-lg text-slate-400 text-sm"
            >
              <span className="material-icons text-2xl">X</span>
            </button>
          </div>
          <div className="font-bold text-2xl justify-center text-center">
            <h3>Where will be the duration of this project?</h3>
          </div>

          <div className="p-6 pb-8">
            <div className="grid gap-y-4">
              <div className="grid gap-4">
                <div className="items-center justify-center text-center m-8">
                  <div className="w-1/2 m-auto border px-6 rounded-lg">
                    <select value={duration} onChange={e => setDuration(e.target.value)} className="m-auto py-4 px-12 outline-none">
                      <option>
                        Select a duration
                      </option>
                      <option value="1-5-months">1-5 Months</option>
                      <option value="5-10-months">5-10 Months</option>
                      <option value="10-15-months">10-15 Months</option>
                      <option value="15-20-months">15-20 Months</option>
                      <option value="20-25-months">20-25 Months</option>
                      <option value="25-30-months">25-30 Months</option>
                      <option value="30-35-months">30-35 Months</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    onClick={() => setActive(active - 1)}
                    className="mr-2 border text-gray-500 bg-white hover:bg-blue-900 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm uppercase px-5 py-2 tracking-widest"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={() => setActive(active + 1)}
                    className="text-white bg-blue-700 ml-2 hover:bg-blue-900 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm uppercase px-5 py-2 tracking-widest"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubmitDuration;
