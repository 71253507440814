import React from "react";
import howItWorksImg from "../../assets/how-it-works/banner/how-it-works-hero-img.png";

const Hero = () => {
  return (
    <section className="h-auto w-full relative -mb-4">
      <div className="bg-bondi-blue opacity-[5%] absolute inset-0"></div>
      <div className="relative flex items-center justify-between flex-col lg:flex-row lg:pb-4">
        <div className="img-wrapper lg:mt-8 lg:order-2 w-full lg:w-1/2">
          <img
            className="w-full h-full"
            src={howItWorksImg}
            alt="two people smiling at computer"
          />
        </div>

        <div className="lg:order-1 max-w-lg lg:ml-8 xl:ml-auto text-center lg:text-left mt-12 mb-8 lg:my-0">
          <h1 className="font-semibold text-bondi-blue text-3xl lg:text-[3.125rem] lg:leading-[140%]">
            The most reliable workplace
          </h1>
          <p className="text-lg mt-3">
            Work security and talents hiring made easy
          </p>
          <div className="btn-wrapper flex mt-8 justify-center lg:justify-start">
            <a
              href="#"
              className="font-semibold shadow-sm text-sm lg:text-base mx-2 md:w-auto text-center max-w-lg text-white hover:text-bondi-blue bg-bondi-blue hover:bg-white rounded-3xl px-10 lg:px-14 mb-5 py-3 lg:mb-0 lg:ml-0 lg:mr-3 lg:flex lg:items-center cursor-pointer border border-bondi-blue transition-all"
            >
              Hire
            </a>
            <a
              href="#"
              className="font-semibold shadow-sm text-sm lg:text-base mx-2 md:w-auto text-center max-w-lg text-bondi-blue hover:text-white bg-white hover:bg-bondi-blue rounded-3xl px-10 lg:px-14 mb-5 py-3 lg:mb-0 lg:ml-3 lg:flex lg:items-center cursor-pointer border border-white transition-all"
            >
              Find Work
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
