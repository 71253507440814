import React from "react";

const JobApplication = () => {
  return (
    <div
      className="screen modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="applicationModal"
      tabindex="-1"
      aria-labelledby="applicationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog max-2xl lg:max-w-4xl xl:max-w-6xl relative w-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="flex flex-col lg:flex-row bg-white rounded-md">
            <div className="absolute lg:hidden right-8 top-8">
              <i
                className="text-2xl"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <ion-icon name="close"></ion-icon>
              </i>
            </div>
            <div className="w-full lg:w-7/12 xl:w-8/12 px-7 lg:pl-12 py-12 lg:pr-5">
              <h2 className="text-bondi-blue text-xl font-bold">
                Frontend Web Developer
              </h2>
              <p className="">California, USA</p>
              <div className="mt-6">
                <h3 className="uppercase font-semibold font-base">
                  JOB DESCRIPTION
                </h3>
                <div className="mt-1">
                  <p className="mt-3">
                    Filly Coder is a growing and remote-first software
                    development agency! We are nationally recognized on Inc.’s
                    Best Workplaces list and Inc. 5000 list, highlighting the
                    5000 fastest-growing private companies. Interested in
                    joining the Filly Coder team?
                  </p>
                  <p className="mt-3">
                    We currently have a spot open for a talented Front End
                    Developer to help us continue to deliver great content for
                    our world-class clients. Perks for United States residents
                    include 100% health, vision and dental benefits, 50% 401k
                    match up to 6% of your salary (immediate vesting), donation
                    matching, career development training, unlimited PTO,
                    work-from-home equipment, and free pens. Everyone loves free
                    pens.Learn more about working at Filly Coder by watching our
                    careers video, experiencing our culture on Instagram, and
                    checking out our work on Dribbble!
                  </p>
                  <p className="mt-3">
                    Filly Coder Media values diversity. We encourage people of
                    color, LGBTQIA+ folks, veterans, parents, and those with
                    disabilities to apply. Through our “health, then work”
                    philosophy we are committed to helping all people succeed at
                    Filly Coder. If you require reasonable accommodation to
                    complete our application or interview process, please let us
                    know.
                  </p>
                </div>
                <div className="mt-6">
                  <h3 className="font-semibold font-base">What you’ll do</h3>
                  <ul className="mt-4 list-disc ml-8">
                    <li className="mb-2">
                      Develop best-in-class content that solves user search
                      queries (such as creating a “wedding budget calculator”
                      thousands search for each month), and ranks #1 online
                    </li>
                    <li className="mb-2">
                      Ability to translate wireframes and Designs into
                      functional websites using HTML5, CSS, and JavaScript
                    </li>
                    <li className="mb-2">
                      Build reusable code and libraries for future use
                    </li>
                    <li className="mb-2">
                      Test and debug to ensure flawless functionality and
                      performance of your design
                    </li>
                    <li className="mb-2">
                      Collaborate with project managers, writers, designers, and
                      clients to create the best possible work
                    </li>
                    <li className="mb-2">
                      Help improve and iterate on the Siege Media website
                    </li>
                  </ul>
                </div>
                <div className="mt-6">
                  <h3 className="font-semibold font-base">Requirements</h3>
                  <ul className="mt-4 list-disc ml-8">
                    <li className="mb-2">
                      3-4 years experience showing proficiency with front-end
                      technologies, including HTML5, CSS3, JavaScript, and
                      jQuery
                    </li>
                    <li className="mb-2">Familiar with Bootstrap framework</li>
                    <li className="mb-2">
                      Experience building mobile-first, responsive web pages
                    </li>
                    <li className="mb-2">
                      Expertise with Advanced Custom Fields and Custom Post
                      Types in WordPress
                    </li>
                    <li className="mb-2">
                      Expert understanding of WordPress themes and plugins; able
                      to build a theme from the ground up, able to build a
                      simple plugin, able to use a Text Editor to build complex
                      WordPress posts and pages; able to create a custom page
                      template
                    </li>
                    <li className="mb-2">
                      Experience working with headless CMS platforms, such as
                      Contentful, Prismic, and Bloomreach
                    </li>
                    <li className="mb-2">
                      Site speed best practices and optimization (PageSpeed
                      Insights, Pingdom, caching/minification, image
                      optimization, etc.)
                    </li>
                    <li className="mb-2">
                      Good understanding of SEO principles and ensuring your
                      content will adhere to them
                    </li>
                    <li className="mb-2">
                      Good understanding of ADA/WCAG compliance for an
                      accessible web
                    </li>
                    <li className="mb-2">
                      Strong interpersonal skills, with ability to form
                      productive working relationships
                    </li>
                    <li className="mb-2">
                      Strong project management skills, with ability to juggle
                      multiple projects while still working effectively
                    </li>
                    <li className="mb-2">
                      Ability to set priorities, meet deadlines, and work
                      independently
                    </li>
                    <li className="mb-2">
                      Familiar with source control technologies like Git (we use
                      Gitlab)
                    </li>
                  </ul>
                </div>
                <div className="mt-6">
                  <h3 className="font-semibold font-base">Requirements</h3>
                  <ul className="mt-4">
                    <li className="mb-2">401(k)</li>
                    <li className="mb-2">Dental Insurance</li>
                    <li className="mb-2">Health Insurance</li>
                    <li className="mb-2">Unlimited paid Time Off</li>
                    <li className="mb-2">Vision Insurance</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-5/12 xl:w-4/12 bg-dim-gray bg-opacity-[3%] px-7 lg:pr-12 py-12">
              <h2 className="font-semibold text-lg mb-8">Submit Application</h2>
              <form className="">
                <div className="relative mb-5">
                  <label htmlFor="fileUpload" className="mb-2 mb-2">
                    Resume/CV
                  </label>
                  <div className="m-auto relative h-20 p-3 w-full border border-dim-gray rounded-md bg-white flex flex-col items-center justify-center">
                    <input
                      type="file"
                      name="file"
                      id="fileUpload"
                      className="w-full opacity-0 absolute inset-0 cursor-pointer"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M15.2097 13.1128C15.1847 13.0777 15.1529 13.0493 15.1165 13.0297C15.0801 13.0102 15.0402 13 14.9997 13C14.9592 13 14.9193 13.0102 14.8829 13.0297C14.8465 13.0493 14.8146 13.0777 14.7897 13.1128L11.0566 18.3215C11.0258 18.3648 11.0067 18.4169 11.0015 18.4716C10.9962 18.5264 11.0051 18.5816 11.027 18.6311C11.0489 18.6805 11.083 18.7222 11.1253 18.7513C11.1677 18.7804 11.2166 18.7958 11.2666 18.7957H13.7298V27.7059C13.7298 27.8677 13.8497 28 13.9964 28H15.9963C16.143 28 16.2629 27.8677 16.2629 27.7059V18.7994H18.7328C18.9561 18.7994 19.0795 18.5163 18.9428 18.3252L15.2097 13.1128Z"
                        fill="#0384C6"
                      />
                      <path
                        d="M23.7715 10.7432C22.4297 7.2041 19.0107 4.6875 15.0059 4.6875C11.001 4.6875 7.58203 7.20117 6.24023 10.7402C3.72949 11.3994 1.875 13.6875 1.875 16.4062C1.875 19.6436 4.49707 22.2656 7.73145 22.2656H8.90625C9.03516 22.2656 9.14062 22.1602 9.14062 22.0312V20.2734C9.14062 20.1445 9.03516 20.0391 8.90625 20.0391H7.73145C6.74414 20.0391 5.81543 19.6465 5.12402 18.9346C4.43555 18.2256 4.06934 17.2705 4.10156 16.2803C4.12793 15.5068 4.3916 14.7803 4.86914 14.168C5.3584 13.5439 6.04395 13.0898 6.80566 12.8877L7.91602 12.5977L8.32324 11.5254C8.5752 10.8574 8.92676 10.2334 9.36914 9.66797C9.80587 9.10754 10.3232 8.61489 10.9043 8.20605C12.1084 7.35938 13.5264 6.91113 15.0059 6.91113C16.4854 6.91113 17.9033 7.35938 19.1074 8.20605C19.6904 8.61621 20.2061 9.1084 20.6426 9.66797C21.085 10.2334 21.4365 10.8604 21.6885 11.5254L22.0928 12.5947L23.2002 12.8877C24.7881 13.3154 25.8984 14.7598 25.8984 16.4062C25.8984 17.376 25.5205 18.29 24.835 18.9756C24.4988 19.3138 24.0988 19.5819 23.6583 19.7644C23.2178 19.947 22.7454 20.0403 22.2686 20.0391H21.0938C20.9648 20.0391 20.8594 20.1445 20.8594 20.2734V22.0312C20.8594 22.1602 20.9648 22.2656 21.0938 22.2656H22.2686C25.5029 22.2656 28.125 19.6436 28.125 16.4062C28.125 13.6904 26.2764 11.4053 23.7715 10.7432Z"
                        fill="#0384C6"
                      />
                    </svg>
                    <p className="">
                      Drag files or{" "}
                      <span className="text-bondi-blue">click here</span> to
                      upload
                    </p>
                  </div>
                </div>
                <div className="relative mb-5">
                  <label htmlFor="email" className=" mb-2">
                    Email Address
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="h-10 pl-3 w-full border border-dim-gray placeholder-dim-gray rounded-md focus:outline-none"
                    placeholder="Email here..."
                  />
                </div>
                <div className="relative mb-5">
                  <label htmlFor="name" className=" mb-2">
                    Full Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="h-10 pl-3 w-full border border-dim-gray placeholder-dim-gray rounded-md focus:outline-none"
                    placeholder="Name here..."
                  />
                </div>
                <div className="relative mb-5">
                  <label htmlFor="website" className=" mb-2">
                    Website url
                  </label>
                  <input
                    id="website"
                    name="website"
                    type="text"
                    className="h-10 pl-3 w-full border border-dim-gray placeholder-dim-gray rounded-md focus:outline-none"
                    placeholder="Url here..."
                  />
                </div>

                <div className="relative mb-5">
                  <label htmlFor="letter" className=" mb-2">
                    Cover Letter
                  </label>
                  <textarea
                    id="letter"
                    name="letter"
                    type="text"
                    className="h-24 pl-3 w-full border border-dim-gray placeholder-dim-gray rounded-md focus:outline-none"
                    placeholder="Your text here..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="font-semibold shadow-sm text-sm lg:text-base lg:mx-2 lg:w-auto text-center w-full lg:w-fit max-w-lg text-white hover:text-bondi-blue bg-bondi-blue hover:bg-white rounded-3xl px-10 lg:px-14 mb-5 py-3 lg:mb-0 lg:ml-0 lg:mr-3 lg:flex lg:items-center cursor-pointer border border-bondi-blue transition-all"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobApplication;
