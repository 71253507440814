import React from "react";
import wallet from "../../assets/how-it-works/why-filly-jobs/wallet.svg";
import person from "../../assets/how-it-works/why-filly-jobs/person.svg";
import headset from "../../assets/how-it-works/why-filly-jobs/headset.svg";

const WhyFillyJobs = () => {
  return (
    <section className="max-w-xl lg:max-w-7xl px-5 mx-auto">
        <h3 className="text-[2.5rem] font-semibold text-center">
          Why filly jobs?
        </h3>
      <div className="flex flex-col lg:flex-row items- justify-center mb-16 mt-8 lg:mt-12">
        <div className="details mb-8 lg:mb-0 lg:px-2 xl:px-5 lg:flex-1">
          <figure className="mb-6">
            <img src={wallet} alt="wallet" className="" />
          </figure>
          <h3 className="text-lg font-semibold mb-2">Flexible payment plans</h3>
          <p className="text-dark-gray text-sm">
            There are a variety of online payment options, including hourly,
            daily and milestone payments. From hourly rates to milestone
            payments, you can customize the invoice for your specific business
            needs.
          </p>
        </div>
        <div className="details mb-8 lg:mb-0 lg:px-2 xl:px-5 lg:flex-1">
          <figure className="mb-6">
            <img src={person} alt="icon of person with helmet" className="" />
          </figure>
          <h3 className="text-lg font-semibold mb-2">Myriads of talents</h3>
          <p className="text-dark-gray text-sm">
            Get in touch with thousands of highly qualified, eager, and talented
            Freelancers.
          </p>
        </div>
        <div className="details mb-8 lg:mb-0 lg:px-2 xl:px-5 lg:flex-1">
          <figure className="mb-6">
            <img src={headset} alt="headset" className="" />
          </figure>
          <h3 className="text-lg font-semibold mb-2">24/7 Customer Support</h3>
          <p className="text-dark-gray text-sm">
            We take great pride in our results and believe that your time is
            important. We listen to your needs, find ways to make things work
            around you, and don't leave any details behind.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyFillyJobs;
