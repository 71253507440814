import React from "react";

const PrivacyNoticeRecruitment = () => {
  return (
    <section className="mx-auto">
      <div className="relative flex flex-col items-center justify-between text-dark-gray text-sm lg:text-base mb-14">
        <div className="h-[358px] bg-bondi-blue text-white w-full">
          <div className="max-w-xl lg:max-w-7xl mx-auto text-center px-5 mt-24">
            <h1 className="uppercase text-2xl sm:text-3xl lg:text-4xl my-2 font-semibold">
              PRIVACY NOTICE -
              <br />
              RECRUITMENT
            </h1>
          </div>
        </div>
        <div className="rounded-t-[50px] -mt-20 bg-white overflow-hidden w-full max-w-xl lg:max-w-7xl min-h-[30vh] pt-10 px-8 mb-20 pb-1">
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              Introduction
            </h2>
            <p className="">
              This Recruitment Privacy Notice describes how Filly Jobs Limited
              (“Filly Jobs”) collects, uses and retains personal information
              about you, when you apply or take part in our recruitment process.
              As such, this policy applies to potential candidates for
              employment, or those who may take part in recruitment programmes
              at Filly Jobs.
            </p>
            <p className="mt-4">
              At Filly Jobs, we consider your privacy to be extremely important
              to us. Further information regarding our fundamental principles
              and practices can be found in our Privacy Policy.
            </p>
            <p className="mt-4">
              We are Filly Jobs Limited and are the “data controller” for the
              purposes of applicable data protection regulations relevant to
              your personal information. This means that we are responsible for
              determining how we hold and use your personal information. We are
              Filly Jobs Limited. Our address is 388 Market Street, Suite 1300,
              San Francisco CA 94111. You can contact us by post at the above
              address or by email to contact@fillyjobs.com for the attention of
              “Legal”.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              What information we collect
            </h2>
            <p className="">
              In connection with our recruitment activities and your application
              to work with us, we will collect, store, and use the following
              categories of personal information about you:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                Your name, date of birth, nationality, contact information such
                as e-mail and telephone number,
              </li>
              <li className="mb-3">
                Any information on your identification and right to work
                documentation,
              </li>
              <li className="mb-3">
                Any information provided to us in your CV, references, and cover
                letter,
              </li>
              <li className="mb-3">
                Any information and documentation you have provided in relation
                to your qualifications, educational and employment background,
              </li>
              <li className="mb-3">
                Any correspondence you may have had with us during the
                recruitment application process,
              </li>
              <li className="mb-3">
                Any information you may provide us with during your application
                interviews, and any interview feedback that your interviewer may
                have,
              </li>
              <li className="mb-3">
                Your psychometric test results, including your rank and
                percentile, if you undertake a recruitment test with our
                providers.
              </li>
            </ul>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              How we collect your information
            </h2>
            <p className="">
              We may collect personal information about you from the following
              sources:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                Directly from you, when you submit your application and
                information to us,
              </li>
              <li className="mb-3">Recruitment services platforms,</li>
              <li className="mb-3">
                Your named referees, if you have submitted them in your
                application,
              </li>
              <li className="mb-3">
                Your public profile on professional social media platforms such
                as LinkedIn.
              </li>
            </ul>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Why we use your information
            </h2>
            <p className="">
              We use your personal information as part of the recruitment
              process at Filly Jobs. Using your information allows us to:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">Carry out recruitment operations,</li>
              <li className="mb-3">
                Assess your skills, qualifications, and suitability for the
                role,
              </li>
              <li className="mb-3">
                Communicate with you as part of the recruitment process,
              </li>
              <li className="mb-3">
                Communicate with and verify your references where required,
              </li>
              <li className="mb-3">
                Keep records regarding our recruitment and hiring processes,
              </li>
              <li className="mb-3">
                Comply with our legal or regulatory requirements,
              </li>
              <li className="mb-3">
                Respond to and defend against legal claims.
              </li>
            </ul>
            <p className="mt-6">
              We use your information because we believe we have a legitimate
              business interest in managing our recruitment operations and
              enabling the hiring of new employees for our organisation. We also
              process your personal information to decide whether to enter a
              contract of employment with you
            </p>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              Who we share your information with
            </h2>
            <p className="">
              We only share your personal information for the purposes of
              facilitating the recruitment process. To achieve this, we may
              share your information amongst other international Filly Jobs
              group entities and third-party companies providing services to us.
              Your personal information may be:
            </p>
            <p className="mt-4">
              Transferred to other international Filly Jobs group entities . We
              may share your personal information with other Filly Jobs group
              entities where necessary for HR, recruitment, administrative and
              support services. Further information on international transfers
              has been included in Section 6. International Data Transfers
              below.
            </p>
            <p className="mt-4">
              Transferred to third-party organisations that provide services to
              us. We use third-party service providers that support us in
              operating our recruitment processes, including application
              management and psychometric testing platforms. We may also ask for
              feedback on the recruitment process. We also use third party
              service providers who help facilitate, run and manage our internal
              administrative systems. For example, we use service providers for
              scheduling and conducting interviews, data storage and back-up
              services.
            </p>
            <p className="mt-4">
              Transferred to government agencies and/or regulators. We may also
              transfer your personal information to government agencies or
              regulators as required to comply with our legal obligations. Where
              we share your data with third parties, we implement appropriate
              legal mechanisms and security measures to ensure the protection of
              your personal information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              International data transfers
            </h2>
            <p className="">
              As Filly Jobs is an international organisation, we may process,
              transfer and store your personal information outside the US. Where
              your personal information is transferred to international Filly
              Jobs entities and third parties outside the US, we will always
              undertake reasonable steps to ensure that adequate security
              mechanisms are implemented to protect your information to the same
              standard as within the US, allowing you to exercise the same
              rights over your data. To ensure that the data protection policies
              of the receiving organisations meet the US, we use approved
              standard contractual clauses to protect your personal information.
            </p>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              How we protect your information
            </h2>
            <p className="">
              We take the security of your personal information seriously, and
              have implemented appropriate security measures, internal policies
              and controls to prevent your information from being lost, misused,
              accessed without authorisation, altered or accidentally destroyed.
              Our security policy includes systems restrictions, encrypted and
              password-protected files, retention protocols and security
              training for management.
            </p>
            <p className="mt-4">
              In addition, we use strict access controls to ensure that your
              information is only accessible on a need-to-know basis, by
              employees who are directly involved in your recruitment
              application. Any third parties that process your information act
              on our instructions and are subject to a duty of confidentiality.
            </p>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              How long we keep your infomation
            </h2>
            <p className="">
              We keep your personal information for a period of 2 years, after
              your recruitment application is decided. We keep your information
              for that period in order to respond to or defend against any legal
              claims. After this period, we will securely delete or anonymise
              your personal information, in accordance with the applicable laws
              and regulations. When we anonymise your information, this makes it
              unidentifiable as to whom it originally belonged to.
            </p>
            <p className="">
              If you are unsuccessful with your application, we may also keep
              your information in case there are any similar opportunities that
              may be suitable for you in the future. In any event, we will not
              hold your data for more than 2 years.
            </p>
            <p className="">
              If you do not want us to keep your information after the
              recruitment process has ended, please contact us at
              contact@fillyjobs.com
            </p>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              Automated decision-making
            </h2>
            <p className="">
              We do not use automated decision-making for recruitment purposes.
              All applications are reviewed by Filly Jobs employees, who assess
              your suitability for the role you have applied for.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="font-semibold text-black text-2xl my-1 mb-3">
              Your Rights
            </h2>
            <p className="">
              You have a number of rights which you may be able to exercise with
              regards to your personal information. These rights have been
              listed below:
            </p>
            <ul className="mt-6 list-disc ml-8">
              <li className="mb-3">
                Rights to Access and Data Portability: You have the right to
                access your personal information and may make a request to do so
                either verbally or in writing. You also have the right to data
                portability and may request to receive your personal information
                in a standard use, machine readable format.
              </li>
              <li className="mb-3">
                Right to Rectification: You have the right to request us to
                rectify any inaccurate or incomplete personal information that
                we may hold on you. If you make this request, we will update
                your personal information with the correct information provided.
              </li>
              <li className="mb-3">
                Rights to Objection, Restriction and Erasure: You have the right
                to restrict how your personal information is being used, and the
                right to request us to delete your information.
              </li>
              <li className="mb-3">
                Right to Withdraw Consent: Although we do not generally process
                your personal information based on your consent, in
                circumstances which this is the case, you may withdraw your
                consent at any time.
              </li>
              <li className="mb-3">
                Right to be Informed: You have the right to be informed about
                how we collect, use and retain your personal information, which
                is why we have set these out within this policy. If you have
                further queries on how we use your personal information, you may
                contact us at any time.
              </li>
              <li className="mb-3">
                Rights Against Automated Decision-Making and Profiling: We do
                not use automated decision-making or profiling for recruitment
                purposes.
              </li>
            </ul>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              Exercising your rights
            </h2>
            <p className="">
              To exercise any of these rights above, please contact us at{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>
              , providing your name and contact details. Depending on your
              request, we may ask for further verification of your identity, or
              additional information in order to fulfil your request.
            </p>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              Right to complain
            </h2>
            <p className="">
              If you wish to make a complaint to us about how we use or retain
              your personal information, please contact us at
              contact@fillyjobs.com. If you have a complaint about our use of
              your information, you can contact the Information Commissioner’s
              Office via their website at
              <a
                href="https://portal.ct.gov/FOI/Common-Elements/Top-Menu/Contact-Us"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                {" "}
                https://portal.ct.gov/FOI/Common-Elements/Top-Menu/Contact-Us
              </a>
            </p>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              Contact Information
            </h2>
            <p className="">
              If you have any questions about this notice, or about how we use
              or retain your personal information, please contact us at{" "}
              <a
                href="mailto:contact@fillyjobs.com"
                className="border-b border-b-bondi-blue text-bondi-blue"
              >
                contact@fillyjobs.com
              </a>
              .
            </p>
          </div>
          <div className="mb-8">
            <h2 className=" font-semibold text-black text-2xl my-1 mb-3">
              Policy changes
            </h2>
            <p className="">
              This is the latest version of our Recruitment Privacy Notice and
              is in effect as of the “Last Updated” date which may be found at
              the top of the notice. As we may change this notice from time to
              time, you should check here regularly for the most up-to-date
              version.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyNoticeRecruitment;
